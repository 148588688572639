import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import styles from "./invoice.module.css";
import Layout from "../../../facility/component/layout";
import { useFacilityStore } from "../../../../app/z-store/store";
import { useFacilityServiceRequests } from "../hooks/use_facility_service_requests";

const VasInvoicePage = () => {
  const [invoice, setInvoice] = useState({
    invoiceNumber: "",
    issueDate: new Date(),
    dueDate: new Date(),
    status: "Pending",
    unit: "",
    fullName: "",
    subTotal: 0,
    tax: 0,
    amount: 0,
    invoiceNote: "Payment is due within 30 days",
  });

  const { invoiceId } = useParams();
  const navigate = useNavigate();
  const toast = useRef(null);
  const facilityId = useFacilityStore((state) => state.facilityId);

  const {
    states: { loading, error },
    invoiceHandlers: { fetchSingleInvoice, downloadInvoicePDF },
  } = useFacilityServiceRequests();

  useEffect(() => {
    if (invoiceId && facilityId) {
      fetchInvoiceData();
    }
  }, [invoiceId, facilityId]);

  const fetchInvoiceData = async () => {
    try {
      const invoiceData = await fetchSingleInvoice(facilityId, invoiceId);
      setInvoice({
        invoiceNumber: invoiceData.invoiceNumber || "",
        issueDate: invoiceData.createdAt,
        dueDate: invoiceData.dueDate,
        status: invoiceData.status || "Pending",
        unit: invoiceData.unit,
        fullName: invoiceData.customerInfo?.fullName || "N/A",
        subTotal: invoiceData.subTotal || 0,
        tax: invoiceData.tax || 0,
        amount: invoiceData.amount || 0,
        invoiceNote: invoiceData.invoiceNote || "Payment is due within 30 days",
      });
    } catch (error) {
      if (toast.current) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.message || "Failed to fetch invoice data",
        });
      }
    }
  };

  const handleDownloadPDF = async () => {
    try {
      toast.current.show({
        severity: "info",
        summary: "Processing",
        detail: "Generating PDF...",
      });

      await downloadInvoicePDF("printable-invoice", invoice);

      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "PDF downloaded successfully!",
      });
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to generate PDF. Please try again.",
      });
    }
  };

  if (loading) {
    return <div className={styles.loading}>Loading...</div>;
  }

  const companyDetails = {
    name: "Property PLC",
    address: "123 Business Avenue, Nairobi, Kenya",
    bankName: "KCB Bank",
    accountNumber: "1234567890"
  };

  return (
    <Layout>
      <div className="card">
        <div className="card-header">
          <Link to="#" onClick={() => navigate(-1)}>
            <span>
              <i className="ti ti-arrow-narrow-left"></i> Back
            </span>
          </Link>
        </div>
      </div>

      <div className={styles.pageContainer}>
        <Toast ref={toast} />
        <div className={styles.invoiceContainer}>
          <div className={styles.actionButtons}>
            <Button
              label="Download Invoice"
              icon="pi pi-download"
              onClick={handleDownloadPDF}
              className="p-button-primary"
            />
          </div>
          <div id="printable-invoice" className={styles.invoiceContent}>
            <div className={styles.invoiceHeader}>
              <div className={styles.invoiceHeaderContent}>
                <div className={styles.invoiceTitle}>Invoice</div>
                <div className={styles.invoiceInfo}>
                  <ul className={styles.infoLabels}>
                    <li>INVOICE #</li>
                    <li>INVOICE DATE</li>
                    <li>DUE DATE</li>
                    <li>STATUS</li>
                  </ul>
                  <ul className={styles.infoValues}>
                    <li>{invoice.invoiceNumber}</li>
                    <li>{new Date(invoice.issueDate).toLocaleDateString()}</li>
                    <li>{new Date(invoice.dueDate).toLocaleDateString()}</li>
                    <li>{invoice.status}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.detailsSection}>
              <div className={styles.detailsLeft}>
                <div className={styles.addressBlock}>
                  <h6 className={styles.label}>from</h6>
                  <h6 className={styles.value}>{companyDetails.name}</h6>
                  <h6 className={styles.value}>{companyDetails.address}</h6>
                </div>
                <div className={styles.addressBlock}>
                  <h6 className={styles.label}>bill to</h6>
                  {/* <h6 className={styles.value}>{invoice.fullName}</h6> */}
                  <h6 className={styles.value}>Unit: {invoice.unit}</h6>
                </div>
              </div>
              <div className={styles.detailsRight}>
                <div className={styles.totalSection}>
                  <div className={styles.totalColumn}>
                    <div className={styles.totalLabel}>Invoice Total</div>
                    <div className={styles.totalAmount}>
                      Ksh {invoice.amount?.toFixed(2)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.invoiceBody}>
              <div className={styles.calculations}>
                <div className={styles.calculationsGrid}>
                  <div className={styles.calcLabels}>
                    <h6 className={styles.columnHeader}>Subtotal</h6>
                    <h6 className={styles.columnHeader}>Tax (16%)</h6>
                  </div>
                  <div className={styles.calcValues}>
                    <h6 className={styles.value}>Ksh {invoice.subTotal?.toFixed(2)}</h6>
                    <h6 className={styles.value}>Ksh {invoice.tax?.toFixed(2)}</h6>
                  </div>
                </div>
              </div>
              <div className={styles.totalRow}>
                <h6 className={styles.columnHeader}>Total</h6>
                <h6 className={styles.value}>Ksh {invoice.amount?.toFixed(2)}</h6>
              </div>
            </div>
            <div className={styles.footer}>
              <h6 className={styles.termsLabel}>terms and conditions</h6>
              <h6 className={styles.value}>{invoice.invoiceNote}</h6>
              <div className={styles.bankDetails}>
                <h6 className={styles.value}>Payment Details:</h6>
                <h6 className={styles.value}>Bank: {companyDetails.bankName}</h6>
                <h6 className={styles.value}>Account: {companyDetails.accountNumber}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default VasInvoicePage;