import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import QrReader from 'react-qr-scanner';
import { Scanner } from '@yudiel/react-qr-scanner';
import Layout from '../../component/layout';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { makeRequest2 } from '../../../../utils/makeRequest';
import { toastify } from '../../../../utils/toast';
import { handleAllowVisitorURL, confirmQRDataURL, getEntriesAndExitsForFacility } from '../../../../utils/urls';
import { getItem } from '../../../../utils/localStorage';
import { getLocation } from '../../../../utils/getLocation';

function ScanQRCode() {
    const navigate = useNavigate();
    const [delay, setDelay] = useState(0); // Initially no scanning
    const [result, setResult] = useState('');
    const [scanning, setScanning] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false); // Controls whether scanning is active
    const [facingMode, setFacingMode] = useState('rear'); // Default to rear camera
    const [entries, setEntries] = useState([])
    const [entry, setEntry] = useState(null);
    const [visitorDetailsVisible, setVisitorDetailsVisible] = useState(false)
    const [type, setType] = useState('');
    const [member, setMember] = useState({});
    const [visitor, setVisitor] = useState('');
    const [customer, setCustomer] = useState('');
    const [unitName, setUnitName] = useState('');
    const [vehicle, setVehicle] = useState({});
    const [visitLog, setVisitorLog] = useState({});



    const handleScan = async (data) => {
        try {
            if (!entry) {
                throw new Error('Entry is required.');
            }

            if (data) {
                const { latitude: lat, longitude: long } = await getLocation();
                const value = await getItem('selectedFacilityId')
                const response = await makeRequest2(confirmQRDataURL + '/' + value, 'POST', {
                    data,
                    entry: entry.name,
                    lat,
                    long,
                    entryPointId: entry._id,
                });


                if (response.success) {
                    // Set the data to the appropriate state based on the type
                    setType(response.data.type);
                    setVisitorDetailsVisible(true);

                    if (response.data.type === "Family Member") {
                        setMember(response.data.member);
                        setCustomer(response.data.customer);
                        setUnitName(response.data.unitName);
                    } else if (response.data.type === "Staff") {
                        setMember(response.data.member);
                        setCustomer(response.data.customer);
                        setUnitName(response.data.unitName);
                    } else if (response.data.type === "Vehicle") {
                        setVehicle(response.data.vehicle);
                        setUnitName(response.data.unitName);
                        setCustomer(response.data.customer);
                    } else if (response.data.type === "Resident") {
                        setCustomer(response.data.customer);
                        setUnitName(response.data.unitName);
                    } else if (response.data.type === "Visitor") {
                        setVisitorLog(response.data.visitLog);
                    }
                }
            }
        } catch (err) {
            setScanning(false);
            // toastify("We have encountered an error. Please try again or use the code.", 'error');
            toastify(err.message, 'error');
        }
    };

    const handleAllowVisitor = async () => {
        try {
            const value = await getItem('selectedFacilityId')
            const response = await makeRequest2(handleAllowVisitorURL + '/' + value, 'POST', {
                type, vehicle, customer, visitLog, member, entry: entry.name
            });
            if (response.success) {
                setVisitorDetailsVisible(false)

                toastify(response.data, 'success');
                navigate('/facility/visitor_access_management/home');

            }
            else {
                throw new Error(response.error)
            }
        }
        catch (err) {
            toastify(err.message, 'error');
        }
    }


    const handleError = (err) => {
        toastify(err, 'error');
    };
    const handleStartScan = () => {
        if (entry === '') {
            toastify('Entry is required', 'error');
        }
        else {
            setDelay(300); // Set delay to start scanning
            setScanning(true); // Enable scanning mode
            setError(false);
            setSuccess(false);
        }

    };
    const handleCancelScan = () => {
        setScanning(false); // Stop scanning
        setDelay(0); // Reset delay to stop the scanner
        setError(false);
        setSuccess(false);

    };

    const toggleCamera = () => {
        setFacingMode((prevMode) => (prevMode === 'rear' ? 'front' : 'rear'));
    };
    // Updated previewStyle for larger camera and centered positioning
    const previewStyle = {
        height: 400, // Increased height
        width: 600,  // Increased width
        display: 'block',
        margin: '0 auto', // Center the scanner
    };
    const fetchEntriesAndExitsForFacility = async () => {
        try {

            const value = await getItem('selectedFacilityId')
            const response = await makeRequest2(`${getEntriesAndExitsForFacility}/${value}`, 'GET', {});
            if (response.success) {
                setEntries(response.data);
            } else {
                throw new Error('Failed to fetch entries and exits')
            }
        } catch (error) {
            console.log(error.message)
            toastify(error.message, 'error');
        }
    };

    const getCurrentAccessPoint = async () => {
        try {
            const value = await getItem('selectedEntryPoint');
            if (value) {
                let data;
                if (typeof value === 'string') {
                    try {
                        data = JSON.parse(value);
                    } catch (err) {
                        console.error("Error parsing selectedEntryPoint:", err.message);
                        return; // Exit if parsing fails
                    }
                } else {
                    data = value; // Already an object
                }
                setEntry(data);
            }
        } catch (err) {
            console.log(err.message);
        }
    };


    useEffect(() => {
        fetchEntriesAndExitsForFacility();
        getCurrentAccessPoint()

    }, []);

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Visitor & Access Management</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Scan QR Code</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Scan QR Code</h5>
                                </div>

                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12 col-xxl-12">
                                            <div className="card">
                                                <div className="card-body position-relative">

                                                    {/* <div className="row">
                                                        
                                                        <div className="col-sm-4">
                                                            <div className="mb-3">
                                                                <label>Entry Point<span style={{ color: 'red' }}>*</span></label>
                                                                <input className="form-control" value={entry} disabled></input>
                                                            </div>
                                                        </div>

                                                    </div> */}

                                                    {scanning ? (
                                                        <>
                                                            <Scanner
                                                                delay={delay}
                                                                style={previewStyle} // Larger and centered camera preview
                                                                onError={handleError}
                                                                onScan={handleScan}
                                                                facingMode={'rear'}
                                                            />
                                                            <Button
                                                                label="Cancel Scanning"
                                                                onClick={handleCancelScan}
                                                                className="p-button-danger mt-3"
                                                            />
                                                        </>
                                                    ) : (
                                                        <p>Click "Scan Now" to start scanning.</p>
                                                    )}
                                                    {
                                                        error &&
                                                        <div className="text-center">
                                                            <strong style={{ color: 'red', fontSize: 20 }}>Error Scanning!!</strong>
                                                        </div>

                                                    }
                                                    {
                                                        success &&
                                                        <div className="text-center">
                                                            <strong style={{ color: 'green', fontSize: 20 }}>Visitor is allowed!</strong>
                                                        </div>

                                                    }
                                                    {!scanning && (
                                                        <Button
                                                            label="Scan Now"
                                                            onClick={handleStartScan}
                                                            className="p-button-primary mt-3"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog header={type} visible={visitorDetailsVisible} style={{ width: '50vw' }} onHide={() => { if (!visitorDetailsVisible) return; setVisitorDetailsVisible(false); }}>

                <div className="row">
                    {
                        type === 'Family Member' && <div className="card">
                            <div className="card-body" style={{ paddingTop: 50 }}>
                                <p>House Number: <strong>{unitName || 'N/A'}</strong></p>
                            </div>
                        </div>
                    }
                    {
                        type === 'Staff' && <div className="card">
                            <div className="card-body" style={{ paddingTop: 50 }}>
                                <p>House Number: <strong>{unitName}</strong></p>
                            </div>
                        </div>
                    }
                    {
                        type === 'Vehicle' && <div className="card">
                            <div className="card-body" style={{ paddingTop: 50 }}>
                                <p>House Number: <strong>{unitName}</strong></p>
                            </div>
                        </div>
                    }
                    {
                        type === 'Resident' && <div className="card">
                            <div className="card-body" style={{ paddingTop: 50 }}>
                                <p>House Number: <strong>{unitName}</strong></p>
                            </div>
                        </div>
                    }
                    {
                        type === 'Visitor' && <div className="card">
                            <div className="card-body" style={{ paddingTop: 50 }}>
                                <p>House Number: <strong>{visitLog.houseNumber}</strong></p>
                            </div>
                        </div>
                    }

                    <button className="btn btn-primary" onClick={handleAllowVisitor}>Allow Visit</button>

                </div>

            </Dialog>

        </Layout>
    );
}

export default ScanQRCode;
