import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { makeRequest2 } from '../../../../../utils/makeRequest';
import { get_lease } from '../../../../../utils/urls';
import { toastify } from '../../../../../utils/toast';
import { getItem } from '../../../../../utils/localStorage';

const PopulatedLeaseTemplate = ({ leaseId, visible, onHide }) => {
  const [loading, setLoading] = useState(true);
  const [populatedContent, setPopulatedContent] = useState('');

  useEffect(() => {
    if (visible && leaseId) {
      fetchAndPopulateTemplate();
    }
  }, [visible, leaseId]);

  const fetchAndPopulateTemplate = async () => {
    try {
      setLoading(true);
      const facilityId = await getItem("selectedFacilityId");
      
      const response = await makeRequest2(
        `${get_lease}/${facilityId}/${leaseId}`,
        'GET'
      );

      if (!response.success) {
        throw new Error(response.error || 'Failed to fetch lease data');
      }

      // Accessing the nested data structure correctly
      const lease = response.data.data;

      if (!lease || !lease.leaseTemplate?.templateContent) {
        console.error("Missing template content:", lease?.leaseTemplate);
        throw new Error('No template content found');
      }

      // Create data object for template population
      const templateData = {
        tenant: {
          firstName: lease.tenant?.firstName || 'N/A',
          lastName: lease.tenant?.lastName || 'N/A',
          phoneNumber: lease.tenant?.phoneNumber || 'N/A',
          email: lease.tenant?.email || 'N/A',
          idNumber: lease.tenant?.idNumber || 'N/A'
        },
        landlord: {
          firstName: lease.landlord?.firstName || 'N/A',
          lastName: lease.landlord?.lastName || 'N/A',
          phoneNumber: lease.landlord?.phoneNumber || 'N/A',
          email: lease.landlord?.email || 'N/A'
        },
        unit: {
          name: lease.unitNumber?.name || 'N/A',
          number: lease.unitNumber?.floorUnitNo || 'N/A'
        },
        lease: {
          startDate: lease.leaseTerms?.startDate ? new Date(lease.leaseTerms.startDate).toLocaleDateString() : 'N/A',
          endDate: lease.leaseTerms?.endDate ? new Date(lease.leaseTerms.endDate).toLocaleDateString() : 'N/A',
          duration: lease.leaseTerms?.duration || 'N/A',
          monthlyRent: lease.financialTerms?.monthlyRent?.toLocaleString() || 'N/A',
          securityDeposit: lease.financialTerms?.securityDeposit?.toLocaleString() || 'N/A',
          paymentDueDate: lease.financialTerms?.paymentDueDate || 'N/A',
          paymentMethod: lease.financialTerms?.paymentMethod || 'N/A',
          billingFrequency: lease.billingCycle?.frequency || 'N/A'
        }
      };

      // Get template content and populate
      let content = lease.leaseTemplate.templateContent;
      
      // Replace all placeholders with actual values
      Object.entries(templateData).forEach(([category, values]) => {
        Object.entries(values).forEach(([field, value]) => {
          const placeholder = `{${category}.${field}}`;
          const regex = new RegExp(placeholder, 'g');
          content = content.replace(regex, value);
        });
      });

      setPopulatedContent(content);
      setLoading(false);
    } catch (error) {
      console.error('Error in fetchAndPopulateTemplate:', error);
      toastify(error.message || 'Error loading template', 'error');
      setLoading(false);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <Dialog
      header="Lease Agreement Document"
      visible={visible}
      style={{ width: '80vw', maxHeight: '90vh' }}
      onHide={onHide}
      className="populated-lease-template"
    >
      <div className="p-4">
        {loading ? (
          <div className="text-center py-4">
            <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>
            <div className="mt-2">Loading template...</div>
          </div>
        ) : (
          <div>
            <div className="mb-4 text-end">
              <Button
                icon="pi pi-print"
                label="Print"
                className="p-button-secondary mr-2"
                onClick={handlePrint}
              />
              <Button
                icon="pi pi-times"
                label="Close"
                className="p-button-danger"
                onClick={onHide}
              />
            </div>
            <div className="template-content bg-white p-4" style={{ minHeight: '50vh' }}>
              <ReactQuill
                value={populatedContent}
                readOnly
                theme="bubble"
                modules={{ toolbar: false }}
              />
            </div>
          </div>
        )}
      </div>
      
      <style>{`
        @media print {
          .p-dialog-header,
          .mb-4.text-end {
            display: none !important;
          }
          .p-dialog {
            box-shadow: none !important;
          }
          .template-content {
            padding: 0 !important;
          }
        }
      `}</style>
    </Dialog>
  );
};

export default PopulatedLeaseTemplate;