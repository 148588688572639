import React from 'react';
import { Button } from 'primereact/button';
import { statusTemplate } from './service_form';

export const RequestApprovalMatrix = ({
    selectedFacilityServiceRequest,
    handleReject,
    handleApprove
}) => {
    const showButtons = selectedFacilityServiceRequest?.status !== 'Cancelled';

    return (
        <div className="p-4">
            <div className="service-info-grid">
                <div className="info-section mb-4">
                    <div className="row mb-3">
                        <div className="col-6">
                            <label className="text-muted mb-2">Customer Name</label>
                            <h6 className="m-0">{selectedFacilityServiceRequest?.CustomerInfo?.fullName}</h6>
                        </div>
                        <div className="col-6">
                            <label className="text-muted mb-2">Status</label>
                            <div>
                                {statusTemplate(selectedFacilityServiceRequest)}
                            </div>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-6">
                            <label className="text-muted mb-2">Service</label>
                            <h6 className="m-0">{selectedFacilityServiceRequest?.serviceName}</h6>
                        </div>
                        <div className="col-6">
                            <label className="text-muted mb-2">Phone Number</label>
                            <h6 className="m-0">{selectedFacilityServiceRequest?.CustomerInfo?.phoneNumber}</h6>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-6">
                            <label className="text-muted mb-2">Created Date</label>
                            <h6 className="m-0">
                                {selectedFacilityServiceRequest?.createdAt ?
                                    new Date(selectedFacilityServiceRequest.createdAt).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    }) : 'N/A'}
                            </h6>
                        </div>
                    </div>
                </div>

                {showButtons && (
                    <div className="modal-footer d-flex justify-content-end">
                        <Button
                            label="Reject"
                            className="btn btn-outline-secondary me-2"
                            icon="pi pi-times"
                            onClick={() => handleReject(selectedFacilityServiceRequest._id)}
                        />
                        <Button
                            label="Proceed"
                            className="btn btn-primary"
                            icon="pi pi-check"
                            onClick={handleApprove}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};