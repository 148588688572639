import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Layout from '../../component/layout';
import { Button, InputText } from 'primereact';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { getItem } from '../../../../utils/localStorage';
import { toastify } from '../../../../utils/toast';
import { makeRequest2 } from '../../../../utils/makeRequest';
import { getLevyTypes, addLevy } from '../../../../utils/urls';

function AddContract() {
    const navigate = useNavigate();

    const [levyType, setLevyType] = useState('');
    const [levyTypes, setLevyTypes] = useState([]);
    const [levyName, setLevyName] = useState('');
    const [amount, setAmount] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [levyApplicant, setLevyApplicant] = useState('');
    const [collectionFrequency, setCollectionFrequency] = useState('');
    const [invoiceDate, setInvoiceDate] = useState('');
    const [invoiceDay, setInvoiceDay] = useState("");
    const [error, setError] = useState("");

    const handleInvoiceDayChange = (e) => {
        const value = e.target.value;
        setInvoiceDay(value);

        // Validation logic
        if (value && (value < 1 || value > 31)) {
            setError("Please enter a number between 1 and 31.");
        } else {
            setError(""); // Clear error if the input is valid
        }
    };



    const fetchLevyTypes = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const response = await makeRequest2(getLevyTypes + '/' + facilityId, 'GET', {});

            if (response.success) {
                setLevyTypes(response.data);
            } else {
                throw new Error(response.error);
            }
        } catch (err) {
            toastify(err.message, 'error')
        }
    };

    const handleLevySubmit = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');

            const levyData = {
                levyName,
                levyType,
                amount,
                levyApplicant,
                collectionFrequency,
                invoiceDay,
                dueDate
            };


            if (!levyData.levyName) throw new Error('Contract name is required.');
            if (!levyData.levyType) throw new Error('Phone number is required.');
            if (!levyData.amount) throw new Error('Amount is required.');
            if (!levyData.levyApplicant) throw new Error('Start date is required.');
            if (!levyData.collectionFrequency) throw new Error('End date is required.');
            if (!levyData.invoiceDay) throw new Error('Invoice day is required.');
            if (!levyData.dueDate) throw new Error('Due date is required.');


            const response = await makeRequest2(addLevy + '/' + facilityId, 'POST', levyData);

            if (response.success) {
                toastify('Levy added successfully', 'success');
                setLevyName('');
                setLevyType('');
                setAmount('')
                setLevyApplicant('');
                setCollectionFrequency('');
                setInvoiceDay('');
                setDueDate('');
            }
            else {
                throw new Error(response.error);
            }
        }
        catch (err) {
            toastify(err.message, 'error')
        }
    }


    useEffect(() => {
        fetchLevyTypes();
    }, [])


    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/app/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/levy_management/levy_management/levy_management"}>levy Management</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    Add New Levy
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <Link to={"#"} onClick={() => navigate(-1)}><span><i className="ti ti-arrow-narrow-left"></i> Back</span></Link>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h5>Add New Levy</h5>
                        </div>
                        <div className='card-body'>
                            <div className='container'>
                                <div className='row'>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Levy Name<span style={{ color: 'red' }}>*</span></label>
                                            <InputText
                                                className="form-control"
                                                value={levyName}
                                                onChange={(e) => setLevyName(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Levy Type<span style={{ color: 'red' }}>*</span></label>
                                            <select
                                                className="form-control mt-2"
                                                value={levyType}
                                                onChange={(e) => setLevyType(e.target.value)}
                                            >
                                                <option value="">-- Select --</option>
                                                {levyTypes.map((type) => (
                                                    <option key={type._id} value={type._id}>
                                                        {type.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Amount</label>
                                            <InputText
                                                className="form-control"
                                                value={amount}
                                                onChange={(e) => setAmount(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Collection Frequency</label>
                                            <select
                                                className="form-control mt-2"
                                                value={collectionFrequency}
                                                onChange={(e) => setCollectionFrequency(e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                <option value="Weekly">Weekly</option>
                                                <option value="Monthly">Monthly</option>
                                                <option value="Quarterly">Quarterly</option>
                                                <option value="Semi-anually">Semi-anually</option>
                                                <option value="Annually">Annually</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Levy Applicant</label>
                                            <select
                                                className="form-control mt-2"
                                                value={levyApplicant}
                                                onChange={(e) => setLevyApplicant(e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                <option value="home owner">Home Owner</option>
                                                <option value="tenant">Tenant</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Invoice Day</label>
                                            <InputText
                                                type="number"
                                                className={`form-control ${error ? "is-invalid" : ""}`}
                                                placeholder="1-31"
                                                value={invoiceDay}
                                                onChange={handleInvoiceDayChange}
                                            />
                                            {error && <div className="invalid-feedback">{error}</div>}
                                        </div>
                                    </div>


                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label">Days to Due Date</label>
                                            <select
                                                className="form-control"
                                                value={dueDate}
                                                onChange={(e) => setDueDate(e.target.value)}
                                            >
                                                <option value="">-- Select Day --</option>
                                                {[...Array(31).keys()].map((num) => (
                                                    <option key={num + 1} value={num + 1}>
                                                        {num + 1}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>



                                </div>
                            </div>

                            <div className="text-end mt-4">
                                <Button type="button" className="btn btn-outline-secondary" >clear</Button>
                                &nbsp;
                                <Button type="button" className="btn btn-primary" onClick={handleLevySubmit}>Submit</Button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </Layout>
    );

}

export default AddContract;
