import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../component/layout';
import { getItem } from '../../../utils/localStorage';
import { makeRequest2 } from '../../../utils/makeRequest';
import { toastify } from '../../../utils/toast';
import Select from "react-select";
import { countries } from "countries-list";
import { addTaxRate, getTaxRates, deleteTaxRate } from '../../../utils/urls';
import { Button } from 'primereact/button';
import ConfirmDialog from "../component/confirmDialog";

const FacilitySettings = () => {
    const [taxRate, setTaxRate] = useState("");
    const [currency, setCurrency] = useState("");
    const [country, setCountry] = useState(null);
    const [taxType, setTaxType] = useState("");
    const [facilityId, setFacilityId] = useState(null);
    const [fetchedTaxRates, setFetchedTaxRates] = useState([]);
    const [editingTax, setEditingTax] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [taxToDelete, setTaxToDelete] = useState(null);

    const TAX_TYPES = [
        { value: 'rental_income', label: 'Rental Income Tax' },
        { value: 'capital_gains', label: 'Capital Gains Tax (CGT)' },
        { value: 'vat', label: 'Value Added Tax (VAT)' },
        { value: 'land_rates', label: 'Land Rates' },
        { value: 'land_rent', label: 'Land Rent' },
        { value: 'withholding', label: 'Withholding Tax on Rent (WHT)' }
    ];

    const countryList = Object.keys(countries).map((code) => ({
        label: countries[code].name,
        value: code,
        currency: countries[code].currency,
    }));

    const handleCountryChange = (selectedOption) => {
        setCountry(selectedOption);
        setCurrency(selectedOption ? selectedOption.currency[0] : "");
    };

    const fetchTaxRates = async (facilityId) => {
        try {
            const response = await makeRequest2(`${getTaxRates}/${facilityId}`, "GET");
            if (response.success && response.data) {
                const taxData = response.data.data || response.data;
                const taxRatesArray = Array.isArray(taxData) ? taxData : [taxData];
                setFetchedTaxRates(taxRatesArray);
            }
        } catch (error) {
            console.error('Error fetching tax rates:', error);
            toastify("Error fetching tax rates", "error");
        }
    };

    const handleTaxRateSubmit = async () => {
        if (!taxRate || !country || !currency || !taxType) {
            toastify("Please enter all required fields", "error");
            return;
        }

        try {
            const data = {
                taxRate: parseFloat(taxRate),
                currency: currency,
                country: country.value,
                taxType: taxType
            };

            let response;
            if (editingTax) {
                response = await makeRequest2(
                    `${addTaxRate}/${facilityId}/${editingTax._id}`,
                    "PUT",
                    data
                );
            } else {
                response = await makeRequest2(
                    `${addTaxRate}/${facilityId}`,
                    "POST",
                    data
                );
            }

            // Special handling for 201 status in error message
            if (response?.error?.includes('201')) {
                toastify(editingTax ? "Tax rate updated successfully" : "Tax rate added successfully", "success");
                await fetchTaxRates(facilityId);
                resetForm();
            } else if (response && response.success === true) {
                toastify(editingTax ? "Tax rate updated successfully" : "Tax rate added successfully", "success");
                await fetchTaxRates(facilityId);
                resetForm();
            } else {
                const errorMessage = response?.message || response?.error || 'Operation failed';
                if (!errorMessage.includes('201')) {  // Only show error if it's not a 201 status
                    toastify(errorMessage, "error");
                }
                await fetchTaxRates(facilityId);
            }
        } catch (error) {
            console.error('Submit error:', error);
            toastify(error.message || "Error submitting tax rate", "error");
            await fetchTaxRates(facilityId);
        }
    };

    const handleEdit = (tax) => {
        setEditingTax({
            ...tax,
            facilityId: facilityId  // Add this line
        });
        setTaxRate(tax.taxRate.toString());
        setTaxType(tax.taxType);

        const countryData = {
            value: tax.country,
            label: countries[tax.country]?.name || tax.country,
            currency: countries[tax.country]?.currency || [tax.currency]
        };
        setCountry(countryData);
        setCurrency(tax.currency);
    };

    const handleDeleteClick = (tax) => {
        setTaxToDelete(tax);
        setShowDeleteDialog(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            const response = await makeRequest2(
                `${deleteTaxRate}/${facilityId}/${taxToDelete._id}`,
                "DELETE"
            );

            if (response.success) {
                toastify("Tax rate deleted successfully", "success");
                await fetchTaxRates(facilityId);
                setShowDeleteDialog(false);
                setTaxToDelete(null);
            } else {
                toastify(response.message || "Failed to delete tax rate", "error");
            }
        } catch (error) {
            console.error('Delete error:', error);
            toastify("Error deleting tax rate", "error");
        }
    };

    const resetForm = () => {
        setTaxRate("");
        setTaxType("");
        setCountry(null);
        setCurrency("");
        setEditingTax(null);
    };

    useEffect(() => {
        const loadFacilityData = async () => {
            const id = await getItem('selectedFacilityId');
            setFacilityId(id);
            if (id) {
                fetchTaxRates(id);
            }
        };
        loadFacilityData();
    }, []);

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/settings_management/settings"}>Settings</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-body py-0">
                            <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="profile-tab-3" data-bs-toggle="tab" href="#profile-3" role="tab" aria-selected="true">
                                        <i className="ti ti-currency me-2"></i>Tax Management
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="tab-content">
                                        <div className="tab-pane show active" id="profile-3" role="tabpanel">
                                            <div className="row p-3">
                                                <div className="col-lg-6">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h5>{editingTax ? "Update" : "Add"} Tax Rate</h5>

                                                            <div className="mt-2">
                                                                <label>Tax Type:</label>
                                                                <select
                                                                    className="form-control mt-2"
                                                                    value={taxType}
                                                                    onChange={(e) => setTaxType(e.target.value)}
                                                                    required
                                                                >
                                                                    <option value="">Select Tax Type</option>
                                                                    {TAX_TYPES.map(tax => (
                                                                        <option key={tax.value} value={tax.value}>
                                                                            {tax.label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>

                                                            <div className="mt-2">
                                                                <label>Tax Rate (%):</label>
                                                                <input
                                                                    required
                                                                    className="form-control mt-2"
                                                                    type="number"
                                                                    value={taxRate}
                                                                    onChange={(e) => setTaxRate(e.target.value)}
                                                                    placeholder="Enter tax rate"
                                                                />
                                                            </div>

                                                            <div className="mt-2">
                                                                <label>Country:</label>
                                                                <Select
                                                                    required
                                                                    className="mt-2"
                                                                    options={countryList}
                                                                    value={country}
                                                                    onChange={handleCountryChange}
                                                                    placeholder="Select a country"
                                                                    getOptionLabel={(e) => `${e.label}`}
                                                                    getOptionValue={(e) => e.value}
                                                                />
                                                            </div>

                                                            <div className="mt-2">
                                                                <label>Currency:</label>
                                                                <input
                                                                    required
                                                                    className="form-control mt-2"
                                                                    type="text"
                                                                    value={currency}
                                                                    onChange={(e) => setCurrency(e.target.value)}
                                                                    placeholder="Currency will be auto-filled"
                                                                    disabled
                                                                />
                                                            </div>

                                                            <div className="mt-4" style={{ textAlign: "right" }}>
                                                                {editingTax && (
                                                                    <button
                                                                        className="btn btn-secondary me-2"
                                                                        onClick={resetForm}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                )}
                                                                <button
                                                                    className="btn btn-primary"
                                                                    onClick={handleTaxRateSubmit}
                                                                >
                                                                    {editingTax ? "Update" : "Add"} Tax Rate
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h5 className="mb-4">Current Tax Rates</h5>
                                                            <div className="table-responsive">
                                                                <table className="table align-middle">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">TAX TYPE</th>
                                                                            <th scope="col">RATE</th>
                                                                            <th scope="col">CURRENCY</th>
                                                                            <th scope="col" className="text-end">ACTIONS</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {fetchedTaxRates.length > 0 ? (
                                                                            fetchedTaxRates.map((tax, index) => (
                                                                                <tr key={index}>
                                                                                    <td>
                                                                                        {TAX_TYPES.find(t => t.value === tax.taxType)?.label || tax.taxType}
                                                                                    </td>
                                                                                    <td>{tax.taxRate}%</td>
                                                                                    <td>{tax.currency}</td>
                                                                                    <td className="text-end">
                                                                                        <Button
                                                                                            icon="ti ti-pencil"
                                                                                            className="p-button-rounded p-button-warning me-2"
                                                                                            onClick={() => handleEdit(tax)}
                                                                                            tooltip="Edit"
                                                                                        />
                                                                                        <Button
                                                                                            icon="ti ti-trash"
                                                                                            className="p-button-rounded p-button-danger"
                                                                                            onClick={() => handleDeleteClick(tax)}
                                                                                            tooltip="Delete"
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            ))
                                                                        ) : (
                                                                            <tr>
                                                                                <td colSpan="4" className="text-center py-4">
                                                                                    <div className="text-muted">
                                                                                        <i className="ti ti-inbox mb-2" style={{ fontSize: '24px' }}></i>
                                                                                        <p className="mb-0">No tax rates available</p>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ConfirmDialog
                visible={showDeleteDialog}
                onHide={() => setShowDeleteDialog(false)}
                message="Are you sure you want to delete this tax rate?"
                onConfirm={handleDeleteConfirm}
            />
        </Layout>
    );
};

export default FacilitySettings;