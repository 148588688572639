import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../component/layout';
import WorkOrdersTab from './work_orders_tab';
import FacilityServiceRequestsTab from './facility_service_requests_tab';
import {
    getWorkOrders,
    deleteWorkOrder,
    getFacilityServiceRequests
} from '../../../utils/urls';
import { getItem } from '../../../utils/localStorage';
import { makeRequest2 } from '../../../utils/makeRequest';
import { toastify } from '../../../utils/toast';

function Services() {
    const [workOrders, setWorkOrders] = useState([]);
    const [facilityServiceRequests, setFacilityServiceRequests] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchWorkOrders = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const fId = typeof facilityId === 'object' ? facilityId.$oid : facilityId;
            const url = `${getWorkOrders}/${fId}`;
            const response = await makeRequest2(url, 'GET');

            if (response.success) {
                const orders = response.data.workOrders || response.data || [];
                setWorkOrders(orders);

                // Show appropriate message based on whether orders were found
                // if (orders.length === 0) {
                //     toastify('No work orders found for this facility', 'info');
                // }
            } else {
                toastify(response.message || 'Unable to fetch work orders', 'error');
            }
        } catch (err) {
            console.error('Error fetching work orders:', err);
            toastify(err.message || 'Error fetching work orders', 'error');
        }
    };

    const fetchFacilityServiceRequests = async () => {
        try {
            setLoading(true);
            const facilityId = await getItem('selectedFacilityId');
            const url = `${getFacilityServiceRequests}/${facilityId}`;
            const response = await makeRequest2(url, 'GET');

            if (response.success) {
                const requests = response.data?.serviceRequests || [];
                setFacilityServiceRequests(requests);

                // Show appropriate message when no requests are found
                // if (requests.length === 0) {
                //     toastify('No service requests found for this facility', 'info');
                // }
            } else {
                toastify(response.message || 'Unable to fetch facility service requests', 'error');
            }
        } catch (err) {
            console.error('Error fetching facility service requests:', err);
            toastify(err.message || 'Error fetching facility service requests', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteWorkOrder = async (workOrderId) => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const fId = typeof facilityId === 'object' ? facilityId.$oid : facilityId;
            const url = `${deleteWorkOrder}/${fId}/${workOrderId}`;

            const response = await makeRequest2(url, 'DELETE');

            if (response.success) {
                toastify('Work Order deleted successfully', 'success');
                await fetchWorkOrders();
            } else {
                toastify(response.message || 'Failed to delete work order', 'error');
            }
        } catch (err) {
            console.error('Error deleting work order:', err);
            toastify(err.message || 'Error deleting work order', 'error');
        }
    };

    useEffect(() => {
        fetchWorkOrders();
        fetchFacilityServiceRequests();
    }, []);

    return (
        <Layout>
            <div className="page-header">
                <div className="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/app/">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <Link to="">Vas</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <Link to="">Services</Link>
                        </li>
                    </ol>
                </div>
            </div>

            <div className="card">
                <div className="card-body py-0">
                    <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">
                        <li className="nav-item">
                            <a
                                className="nav-link active"
                                id="facility-service-requests-tab"
                                data-bs-toggle="tab"
                                href="#facility-service-requests"
                                role="tab"
                                aria-selected="true"
                            >
                                <i className="fa fa-bell mx-2"></i> Service Requests
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                id="workorders-tab"
                                data-bs-toggle="tab"
                                href="#workorders"
                                role="tab"
                                aria-selected="false"
                            >
                                <i className="ti ti-clipboard me-2"></i> Work Orders
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="tab-content">
                <div className="tab-pane fade show active" id="facility-service-requests" role="tabpanel">
                    <FacilityServiceRequestsTab
                        facilityServiceRequests={facilityServiceRequests}
                        refreshRequests={fetchFacilityServiceRequests}
                        refreshWorkOrders={fetchWorkOrders}
                    />
                </div>
                <div className="tab-pane fade" id="workorders" role="tabpanel">
                    <WorkOrdersTab
                        workOrders={workOrders}
                        onDeleteWorkOrder={handleDeleteWorkOrder}
                    />
                </div>
            </div>
        </Layout>
    );
}

export default Services;