import React from 'react';
import { useReminderForm } from '../hooks/useReminderForm';

const ReminderForm = ({ levies = [], module, onSubmitSuccess }) => {
  const {
    type,
    selectedLevyId,
    reminderData,
    handleReminderSubmit,
    handleLevyChange,
    handleTypeChange,
    handleInputChange
  } = useReminderForm(levies, module, onSubmitSuccess);

  if (!Array.isArray(levies)) {
    console.error('Expected levies to be an array, but got:', levies);
    return <div>Error: Invalid levies data</div>;
  }

  return (
    <form onSubmit={handleReminderSubmit}>
      <input type="hidden" name="module" value={module} />
      <div className="form-group mb-4">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          className="form-control"
          id="name"
          name="name"
          value={reminderData.name}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="form-group mb-4">
        <label htmlFor="levy">Select Levy</label>
        <select
          id="levy"
          className="form-select"
          value={selectedLevyId}
          onChange={(e) => {
            const selectedLevy = levies.find(levy => levy._id === e.target.value);
            handleLevyChange(selectedLevy);
          }}
          required
        >
          <option value="">Select a levy</option>
          {levies.map(levy => (
            <option key={levy._id} value={levy._id}>
              {levy.levyName}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group mb-4">
        <label htmlFor="type">Type</label>
        <select
          id="type"
          className="form-select"
          value={type}
          onChange={(e) => handleTypeChange(e.target.value)}
          required
        >
          <option value="">Select Reminder Type</option>
          <option value="onetime">One Time</option>
          <option value="recurring">Recurring</option>
        </select>
      </div>

      {type === 'onetime' && (
        <div className="form-group mb-4">
          <label htmlFor="scheduledDate">Scheduled Date</label>
          <input
            type="date"
            className="form-control"
            id="scheduledDate"
            name="scheduledDate"
            value={reminderData.scheduledDate}
            onChange={handleInputChange}
            required
          />
        </div>
      )}

      {type === 'recurring' && (
        <>
          <div className="form-group mb-4">
            <label htmlFor="frequency">Frequency</label>
            <select
              id="frequency"
              className="form-select"
              value={reminderData.frequency}
              onChange={(e) => handleInputChange({
                target: {
                  name: 'frequency',
                  value: e.target.value
                }
              })}
              required
            >
              <option value="">Select Frequency</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="bi-weekly">Bi-weekly</option>
              <option value="monthly">Monthly</option>
            </select>
          </div>

          <div className="form-group mb-4">
            <label htmlFor="day">Day</label>
            <select
              id="day"
              className="form-select"
              value={reminderData.day}
              onChange={(e) => handleInputChange({
                target: {
                  name: 'day',
                  value: e.target.value
                }
              })}
              required
            >
              <option value="">Select Day</option>
              {['Monday', 'Tuesday', 'Wednesday', 'Thursday',
                'Friday', 'Saturday', 'Sunday'].map(day => (
                  <option key={day} value={day}>{day}</option>
                ))}
            </select>
          </div>
        </>
      )}

      <div className="form-group mb-4">
        <label htmlFor="time">Time</label>
        <input
          type="time"
          className="form-control"
          id="time"
          name="time"
          value={reminderData.time}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="form-group mb-4">
        <label>Notification Types</label>
        <div>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="emailNotification"
              name="notificationTypes"
              value="EMAIL"
            />
            <label className="form-check-label" htmlFor="emailNotification">
              Email
            </label>
          </div>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="smsNotification"
              name="notificationTypes"
              value="SMS"
            />
            <label className="form-check-label" htmlFor="smsNotification">
              SMS
            </label>
          </div>
        </div>
      </div>

      <div className="text-end">
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </div>
    </form>
  );
};

export default ReminderForm;