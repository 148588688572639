import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { makeRequest2 } from "../../../../../utils/makeRequest";
import styles from "./invoice.module.css";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Layout from "../../../component/layout";
import { getInvoice } from "../../../../../utils/urls";
import { useFacilityStore } from "../../../../../app/z-store/store";

const InvoicePage = () => {
  const [invoice, setInvoice] = useState(null);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const { invoiceId } = useParams();
  const navigate = useNavigate();
  const toast = useRef(null);

  const facilityId = useFacilityStore((state) => state.facilityId);

  useEffect(() => {
    fetchInvoiceData();
    fetchCompanyDetails();
  }, [invoiceId]);

  // const fetchInvoiceData = async () => {
  //   try {
  //     console.log("Fetching invoice data for ID:", invoiceId);
  //     const response = await makeRequest2(`${getInvoice}/${facilityId}/${invoiceId}`,"GET",{});
  //     console.log("Response received:", response);

  //     if (response.success) {
  //       setInvoice(response.data.invoice);
  //       console.log("Invoice", invoice)
  //     } else {
  //       console.error("Failed response:", response);
  //       toast.current.show({
  //         severity: "error",
  //         summary: "Error",
  //         detail: response.message || "Failed to fetch invoice",
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error fetching invoice:", error);
  //     toast.current.show({
  //       severity: "error",
  //       summary: "Error",
  //       detail: error.message,
  //     });
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchInvoiceData = async () => {
    try {
      const response = await makeRequest2(`${getInvoice}/${facilityId}/${invoiceId}`, "GET", {});

      if (response.success) {
        const fetchedInvoice = response.data.invoice;

        const invoicesWithCustomerInfo = {
          ...fetchedInvoice,
          fullName: fetchedInvoice.customerInfo?.fullName,
        }

        setInvoice(invoicesWithCustomerInfo);
      } else {
        console.error("Failed response:", response);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.message || "Failed to fetch invoice",
        });
      }
    } catch (error) {
      console.error("Error fetching invoice:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchCompanyDetails = async () => {
    try {
      const response = await makeRequest2(
        `/api/facilities/${invoice?.facilityId}/details`,
        "GET"
      );
      if (response.success) {
        setCompanyDetails(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch company details:", error);
    }
  };

  const handleDownloadPDF = async () => {
    const element = document.getElementById("printable-invoice");
    const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
    const filename = `Invoice_${invoice?.invoiceNumber}_${timestamp}.pdf`;

    try {
      // Show loading state
      setLoading(true);
      toast.current.show({
        severity: "info",
        summary: "Processing",
        detail: "Generating PDF...",
      });

      // Configure html2canvas
      const canvas = await html2canvas(element, {
        scale: 2, // Higher scale for better quality
        useCORS: true,
        logging: false,
        backgroundColor: "#ffffff",
      });

      const imgData = canvas.toDataURL("image/jpeg", 1.0);

      // Configure PDF
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      // Calculate dimensions
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add image to PDF
      pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight, "", "FAST");

      // If content spans multiple pages
      if (imgHeight > pageHeight) {
        let remainingHeight = imgHeight;
        let position = -pageHeight;

        while (remainingHeight > 0) {
          pdf.addPage();
          position -= pageHeight;
          pdf.addImage(
            imgData,
            "JPEG",
            0,
            position,
            imgWidth,
            imgHeight,
            "",
            "FAST"
          );
          remainingHeight -= pageHeight;
        }
      }

      // Save the PDF
      pdf.save(filename);

      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "PDF downloaded successfully!",
      });
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to generate PDF. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };


  if (loading) {
    return <div className={styles.loading}>Loading...</div>;
  }

  return (
    <Layout>
      <div className="card">
        <div className="card-header">
          <Link to={"#"} onClick={() => navigate(-1)}><span><i className="ti ti-arrow-narrow-left"></i> Back</span></Link>
        </div>
      </div>

      <div className={styles.pageContainer}>
        <Toast ref={toast} />
        <div className={styles.invoiceContainer}>
          <div className={styles.actionButtons}>
            <Button
              label="Download Invoice"
              icon="pi pi-download"
              onClick={handleDownloadPDF}
              className="p-button-primary"
            />
          </div>
          <div id="printable-invoice" className={styles.invoiceContent}>
            <div className={styles.invoiceHeader}>
              <div className={styles.invoiceHeaderContent}>
                <div className={styles.invoiceTitle}>Invoice</div>
                <div className={styles.invoiceInfo}>
                  <ul className={styles.infoLabels}>
                    <li>INVOICE #</li>
                    <li>INVOICE DATE</li>
                    <li>DUE DATE</li>
                    <li>STATUS</li>
                  </ul>
                  <ul className={styles.infoValues}>
                    <li>{invoice?.invoiceNumber}</li>
                    <li>{new Date(invoice?.issueDate).toLocaleDateString()}</li>
                    <li>{new Date(invoice?.dueDate).toLocaleDateString()}</li>
                    <li>{invoice?.status}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.detailsSection}>
              <div className={styles.detailsLeft}>
                <div className={styles.addressBlock}>
                  <h6 className={styles.label}>from</h6>
                  <h6 className={styles.value}>
                    {invoice?.facility?.name || "Company Name"}
                  </h6>
                  <h6 className={styles.value}>
                    {companyDetails?.address || "Company Address"}
                  </h6>
                </div>
                <div className={styles.addressBlock}>
                  <h6 className={styles.label}>bill to</h6>
                  <h6 className={styles.value}>{invoice?.fullName}</h6>
                  <h6 className={styles.value}>Unit: {invoice?.unit?.name}</h6>
                </div>
              </div>
              <div className={styles.detailsRight}>
                <div className={styles.totalSection}>
                  <div className={styles.totalColumn}>
                    <div className={styles.totalLabel}>Invoice Total</div>
                    <div className={styles.totalAmount}>
                      Ksh {invoice?.totalAmount?.toFixed(2)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.invoiceBody}>
              <div className={styles.itemsGrid}>
                <div className={styles.descriptionColumn}>
                  <h6 className={styles.columnHeader}>Description</h6>
                  {invoice?.items?.map((item, index) => (
                    <h6 key={index} className={styles.value}>
                      {item.description}
                    </h6>
                  ))}
                </div>
                <div className={styles.unitPriceColumn}>
                  <h6 className={styles.columnHeader}>unit price</h6>
                  {invoice?.items?.map((item, index) => (
                    <h6
                      key={index}
                      className={`${styles.value} ${styles.textEnd}`}
                    >
                      {item.unitPrice.toFixed(2)}
                    </h6>
                  ))}
                </div>
                <div className={styles.qtyColumn}>
                  <h6 className={styles.columnHeader}>qty</h6>
                  {invoice?.items?.map((item, index) => (
                    <h6
                      key={index}
                      className={`${styles.value} ${styles.textEnd}`}
                    >
                      {item.quantity}
                    </h6>
                  ))}
                </div>
                <div className={styles.amountColumn}>
                  <h6 className={styles.columnHeader}>amount</h6>
                  {invoice?.items?.map((item, index) => (
                    <h6
                      key={index}
                      className={`${styles.value} ${styles.textEnd}`}
                    >
                      {(item.quantity * item.unitPrice).toFixed(2)}
                    </h6>
                  ))}
                </div>
              </div>
              <div className={styles.calculations}>
                <div className={styles.calculationsGrid}>
                  <div className={styles.calcLabels}>
                    <h6 className={styles.columnHeader}>Subtotal</h6>
                    <h6 className={styles.columnHeader}>
                      Tax (
                      {((invoice?.tax / invoice?.subTotal) * 100).toFixed(0) ||
                        0}
                      %)
                    </h6>
                    {invoice?.penalty > 0 && (
                      <h6 className={styles.columnHeader}>Penalty</h6>
                    )}
                  </div>
                  <div className={styles.calcValues}>
                    <h6 className={styles.value}>
                      Ksh {invoice?.subTotal?.toFixed(2)}
                    </h6>
                    <h6 className={styles.value}>
                      Ksh {invoice?.tax?.toFixed(2)}
                    </h6>
                    {invoice?.penalty > 0 && (
                      <h6 className={styles.value}>
                        Ksh {invoice?.penalty?.toFixed(2)}
                      </h6>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.totalRow}>
                <h6 className={styles.columnHeader}>Total</h6>
                <h6 className={styles.value}>
                  Ksh {invoice?.totalAmount?.toFixed(2)}
                </h6>
              </div>
            </div>
            <div className={styles.footer}>
              <h6 className={styles.termsLabel}>terms and conditions</h6>
              <h6 className={styles.value}>
                {invoice?.invoiceNote || "Payment is due within 15 days"}
              </h6>
              <div className={styles.bankDetails}>
                <h6 className={styles.value}>Payment Details:</h6>
                <h6 className={styles.value}>
                  Bank: {companyDetails?.bankName || "Bank Name"}
                </h6>
                <h6 className={styles.value}>
                  Account: {companyDetails?.accountNumber || "Account Number"}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default InvoicePage;
