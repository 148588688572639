import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../component/layout';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import ConfirmDialog from '../component/confirmDialog';
import { getItem } from '../../../utils/localStorage';
import { makeRequest2 } from '../../../utils/makeRequest';
import {
    getValueAddedServices,
    addVasVendor,
    getVasVendors,
    deleteVasVendor,
    updateVasVendor,
    addNewValueAddedService,
    deleteValueAddedService,
    getTaxRates
} from '../../../utils/urls';
import { toastify } from '../../../utils/toast';

function ServiceVendor() {
    // Tab Management
    const [activeIndex, setActiveIndex] = useState(0);
    const [loading, setLoading] = useState(false);

    // Vendor States
    const [serviceVendors, setServiceVendors] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);
    const [selectedVendorId, setSelectedVendorId] = useState(null);

    // Service States
    const [services, setServices] = useState([]);
    const [isServiceDialogVisible, setServiceDialogVisible] = useState(false);
    const [serviceConfirmDialogVisible, setServiceConfirmDialogVisible] = useState(false);
    const [selectedService, setSelectedService] = useState(null);
    const [serviceName, setServiceName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isFieldInvalid, setIsFieldInvalid] = useState(false);
    const [taxRates, setTaxRates] = useState([]);
    const [selectedTaxes, setSelectedTaxes] = useState([]);
    const [applicantTypes, setApplicantTypes] = useState({
        tenant: false,
        'home owner': false
    });

    // Form Data
    const [formData, setFormData] = useState({
        name: '',
        location: '',
        jobDescription: '',
        offers: [{ serviceId: null, amount: '' }],
        contactDetails: { name: '', phone: '', email: '' }
    });

    // Predefined services
    const predefinedServices = [
        { name: 'Commission' },
        { name: 'Housekeeping' },
        { name: 'Unit Management' }
    ];

    useEffect(() => {
        fetchInitialData();
    }, []);

    const fetchInitialData = async () => {
        await Promise.all([
            fetchServices(),
            fetchServiceVendors(),
            fetchTaxRates()
        ]);
    };

    const fetchServices = async () => {
        try {
            setLoading(true);
            const facilityId = await getItem('selectedFacilityId');
            const response = await makeRequest2(`${getValueAddedServices}/${facilityId}`, 'GET');

            if (response.success) {
                const services = response.data?.services || [];
                const formattedServices = services.map(service => ({
                    ...service,
                    label: service.serviceName,
                    value: service._id
                }));
                setServices(formattedServices);

                // Show appropriate message when no services are found
                if (services.length === 0) {
                    toastify('No value-added services found for this facility', 'info');
                }
            } else {
                setServices([]);
                toastify(response.message || 'Unable to fetch services', 'error');
            }
        } catch (error) {
            console.error('Error fetching services:', error);
            setServices([]);
            toastify(error.message || 'Error fetching services', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleAddService = async () => {
        try {
            setLoading(true);
            if (!serviceName.trim()) {
                throw new Error('Service name is required');
            }

            const selectedApplicantTypes = Object.entries(applicantTypes)
                .filter(([_, isSelected]) => isSelected)
                .map(([type]) => type.toLowerCase());

            if (selectedApplicantTypes.length === 0) {
                throw new Error('Please select at least one applicant type');
            }

            const facilityId = await getItem('selectedFacilityId');
            const serviceData = {
                serviceName: serviceName.trim(),
                serviceType: serviceName.trim(),
                appliesTo: selectedApplicantTypes,
                applicableTaxes: selectedTaxes
            };

            const response = await makeRequest2(`${addNewValueAddedService}/${facilityId}`, 'POST', serviceData);

            if (response.success) {
                await fetchServices();
                toastify('Service added successfully', 'success');
                resetServiceForm();
            } else {
                throw new Error(response.message || 'Failed to add service');
            }
        } catch (error) {
            console.error('Add service error:', error);
            setErrorMessage(error.message);
            setIsFieldInvalid(true);
            toastify(error.message, 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteService = async () => {
        try {
            if (!selectedService?._id) {
                throw new Error('No service selected for deletion');
            }

            setLoading(true);
            const facilityId = await getItem('selectedFacilityId');
            const response = await makeRequest2(
                `${deleteValueAddedService}/${facilityId}/${selectedService._id}`,
                'DELETE'
            );

            if (response.success) {
                await fetchServices();
                toastify('Service deleted successfully', 'success');
            } else {
                throw new Error(response.message || 'Failed to delete service');
            }
        } catch (error) {
            console.error('Delete service error:', error);
            toastify(error.message, 'error');
        } finally {
            setServiceConfirmDialogVisible(false);
            setSelectedService(null);
            setLoading(false);
        }
    };

    const fetchServiceVendors = async () => {
        try {
            setLoading?.(true);
            const facilityId = await getItem('selectedFacilityId');
            const response = await makeRequest2(`${getVasVendors}/${facilityId}`, 'GET');

            if (response.success) {
                const vendors = response.data?.vendors || [];
                setServiceVendors(vendors);

                // Show appropriate message when no vendors are found
                if (vendors.length === 0) {
                    toastify('No service vendors found for this facility', 'info');
                }
            } else {
                setServiceVendors([]);
                toastify(response.message || 'Unable to fetch service vendors', 'error');
            }
        } catch (error) {
            console.error('Error fetching service vendors:', error);
            setServiceVendors([]);
            toastify(error.message || 'Error fetching service vendors', 'error');
        } finally {
            setLoading?.(false);
        }
    };

    const fetchTaxRates = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const response = await makeRequest2(`${getTaxRates}/${facilityId}`, 'GET');

            if (response.success && response.data?.data) {
                setTaxRates(response.data.data);
            } else if (response.success && Array.isArray(response.data)) {
                setTaxRates(response.data);
            } else {
                setTaxRates([]);
            }
        } catch (error) {
            console.error('Error:', error);
            toastify('Error fetching tax rates', 'error');
            setTaxRates([]);
        }
    };

    const handleVendorSubmit = async (e) => {
        e.preventDefault();
        try {
            const facilityId = await getItem('selectedFacilityId');
            const url = isEditMode
                ? `${updateVasVendor}/${facilityId}/${selectedVendor._id}`
                : `${addVasVendor}/${facilityId}`;

            const response = await makeRequest2(
                url,
                isEditMode ? 'PUT' : 'POST',
                {
                    ...formData,
                    offers: formData.offers.map(offer => ({
                        serviceId: offer.serviceId,
                        amount: parseFloat(offer.amount)
                    }))
                }
            );

            if (response.success) {
                await fetchServiceVendors();
                toastify(`Vendor ${isEditMode ? 'updated' : 'added'} successfully`, 'success');
                setShowAddModal(false);
                resetForm();
            } else {
                throw new Error(response.message || `Failed to ${isEditMode ? 'update' : 'add'} vendor`);
            }
        } catch (error) {
            console.error('Error:', error);
            toastify(error.message, 'error');
        }
    };

    const handleDeleteVendor = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const response = await makeRequest2(
                `${deleteVasVendor}/${facilityId}/${selectedVendorId}`,
                'DELETE'
            );

            if (response.success) {
                await fetchServiceVendors();
                toastify('Vendor deleted successfully', 'success');
            } else {
                throw new Error(response.message || 'Failed to delete vendor');
            }
        } catch (error) {
            console.error('Error:', error);
            toastify(error.message, 'error');
        } finally {
            setConfirmDialogVisible(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleContactChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            contactDetails: { ...prev.contactDetails, [name]: value }
        }));
    };

    const handleOfferChange = (index, field, value) => {
        setFormData(prev => {
            const newOffers = [...prev.offers];
            newOffers[index] = { ...newOffers[index], [field]: value };
            return { ...prev, offers: newOffers };
        });
    };

    const resetForm = () => {
        setFormData({
            name: '',
            location: '',
            jobDescription: '',
            offers: [{ serviceId: null, amount: '' }],
            contactDetails: { name: '', phone: '', email: '' }
        });
        setIsEditMode(false);
        setSelectedVendor(null);
    };

    const resetServiceForm = () => {
        setServiceDialogVisible(false);
        setServiceName('');
        setErrorMessage('');
        setIsFieldInvalid(false);
        setApplicantTypes({ tenant: false, 'home owner': false });
        setSelectedTaxes([]);
    };

    const vendorActionTemplate = (rowData) => (
        <div className="actions">
            <Button
                icon="ti ti-edit"
                className="p-button-rounded p-button-info mr-2"
                onClick={() => {
                    setIsEditMode(true);
                    setSelectedVendor(rowData);
                    setFormData({
                        name: rowData.name,
                        location: rowData.location,
                        jobDescription: rowData.jobDescription,
                        offers: rowData.offers || [{ serviceId: null, amount: '' }],
                        contactDetails: rowData.contactDetails
                    });
                    setShowAddModal(true);
                }}
                tooltip="Edit Vendor"
            />
            <Button
                icon="ti ti-trash"
                className="p-button-rounded p-button-danger"
                onClick={() => {
                    setSelectedVendorId(rowData._id);
                    setConfirmDialogVisible(true);
                }}
                tooltip="Delete Vendor"
            />
        </div>
    );

    const serviceActionTemplate = (rowData) => (
        <div className="actions">
            <Button
                icon="ti ti-trash"
                className="p-button-rounded p-button-danger"
                onClick={() => {
                    setSelectedService(rowData);
                    setServiceConfirmDialogVisible(true);
                }}
                tooltip="Delete Service"
                disabled={loading}
            />
        </div>
    );

    return (
        <Layout>
            <div className="page-header">
                <div className="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/app/">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="">Vas</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="">Service Vendors</Link>
                        </li>
                    </ol>
                </div>
            </div>

            <div className="card">
                <div className="card-body py-0">
                    <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">
                        <li className="nav-item">
                            <button
                                className={`nav-link ${activeIndex === 0 ? 'active' : ''}`}
                                onClick={() => setActiveIndex(0)}
                                role="tab"
                            >
                                <i className="fa fa-user mx-2"></i>
                                Service Vendors
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                className={`nav-link ${activeIndex === 1 ? 'active' : ''}`}
                                onClick={() => setActiveIndex(1)}
                                role="tab"
                            >
                                <i className="fa fa-cog mx-2"></i>
                                Services
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="tab-content">
                {/* Vendors Tab */}
                <div className={`tab-pane fade ${activeIndex === 0 ? 'show active' : ''}`}>
                    <div className="card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <h5 className="card-title mb-0">Service Vendors</h5>
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    resetForm();
                                    setShowAddModal(true);
                                }}
                            >
                                Add Service Vendor
                            </button>
                        </div>
                        <div className="card-body">
                            <div className="card">
                                <div className="card-body">
                                    <DataTable
                                        value={serviceVendors}
                                        paginator
                                        rows={10}
                                        stripedRows
                                        emptyMessage="No service vendors found"
                                        loading={loading}
                                    >
                                        <Column field="name" header="Name" />
                                        <Column field="location" header="Location" />
                                        <Column field="jobDescription" header="Job Description" />
                                        <Column
                                            header="Services & Amounts"
                                            body={(rowData) => (
                                                <div>
                                                    {rowData.offers?.map((offer, index) => (
                                                        <div key={index}>
                                                            {offer.serviceName}: KES {offer.amount.toLocaleString()}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        />
                                        <Column
                                            header="Contact Details"
                                            body={(rowData) => (
                                                <div>
                                                    <div>{rowData.contactDetails?.name}</div>
                                                    <div>{rowData.contactDetails?.phone}</div>
                                                    <div>{rowData.contactDetails?.email}</div>
                                                </div>
                                            )}
                                        />
                                        <Column header="Actions" body={vendorActionTemplate} style={{ width: '150px' }} />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Services Tab */}
                <div className={`tab-pane fade ${activeIndex === 1 ? 'show active' : ''}`}>
                    <div className="card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <h5 className="card-title mb-0">Value Added Services</h5>
                            <button
                                className="btn btn-primary"
                                onClick={() => setServiceDialogVisible(true)}
                                disabled={loading}
                            >
                                Add Service
                            </button>
                        </div>
                        <div className="card-body">
                            <div className="card">
                                <div className="card-body">
                                    <DataTable
                                        value={services}
                                        paginator
                                        rows={10}
                                        stripedRows
                                        emptyMessage="No services found"
                                        loading={loading}
                                    >
                                        <Column field="serviceName" header="Service Name" />
                                        <Column
                                            header="Applies To"
                                            body={(rowData) => (
                                                rowData.appliesTo?.map(type =>
                                                    type === 'tenant' ? 'Tenant' : 'Home Owner'
                                                ).join(', ') || 'None'
                                            )}
                                        />
                                        <Column
                                            header="Applicable Taxes"
                                            body={(rowData) => (
                                                rowData.applicableTaxes?.map(tax =>
                                                    `${tax.taxName} (${tax.taxRate}%)`
                                                ).join(', ') || 'None'
                                            )}
                                        />
                                        <Column header="Actions" body={serviceActionTemplate} style={{ width: '100px' }} />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Vendor Add/Edit Modal */}
            <Dialog
                header={isEditMode ? "Edit Service Vendor" : "Add Service Vendor"}
                visible={showAddModal}
                onHide={() => {
                    setShowAddModal(false);
                    resetForm();
                }}
                className="w-75"
            >
                <form onSubmit={handleVendorSubmit}>
                    <div className="form-group mb-3">
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                    </div>

                    <div className="form-group mb-3">
                        <label htmlFor="location">Location</label>
                        <input
                            type="text"
                            className="form-control"
                            id="location"
                            name="location"
                            value={formData.location}
                            onChange={handleInputChange}
                            required
                        />
                    </div>

                    <div className="form-group mb-3">
                        <label htmlFor="jobDescription">Job Description</label>
                        <input
                            type="text"
                            className="form-control"
                            id="jobDescription"
                            name="jobDescription"
                            value={formData.jobDescription}
                            onChange={handleInputChange}
                            required
                        />
                    </div>

                    <div className="form-group mb-3">
                        <label>Service Offers</label>
                        {formData.offers.map((offer, index) => (
                            <div key={index} className="d-flex gap-2 mt-2">
                                <select
                                    className="form-select"
                                    value={offer.serviceId || ""}
                                    onChange={(e) => handleOfferChange(index, 'serviceId', e.target.value)}
                                    required
                                >
                                    <option value="">Select a service</option>
                                    {services.map((service) => (
                                        <option key={service._id} value={service._id}>
                                            {service.serviceName}
                                        </option>
                                    ))}
                                </select>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Amount"
                                    value={offer.amount}
                                    onChange={(e) => handleOfferChange(index, 'amount', e.target.value)}
                                    required
                                    min="0"
                                    step="0.01"
                                />
                                <button
                                    type="button"
                                    className={`btn btn-${index === formData.offers.length - 1 ? 'primary' : 'danger'}`}
                                    onClick={() => {
                                        if (index === formData.offers.length - 1) {
                                            setFormData(prev => ({
                                                ...prev,
                                                offers: [...prev.offers, { serviceId: null, amount: '' }]
                                            }));
                                        } else {
                                            setFormData(prev => ({
                                                ...prev,
                                                offers: prev.offers.filter((_, i) => i !== index)
                                            }));
                                        }
                                    }}
                                >
                                    <i className={`fa fa-${index === formData.offers.length - 1 ? 'plus' : 'times'}`}></i>
                                </button>
                            </div>
                        ))}
                    </div>

                    <div className="form-group mb-3">
                        <label>Contact Details</label>
                        <div className="ms-3">
                            <div className="mb-2">
                                <label htmlFor="contactName">Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="contactName"
                                    name="name"
                                    value={formData.contactDetails.name}
                                    onChange={handleContactChange}
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="contactPhone">Phone</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="contactPhone"
                                    name="phone"
                                    value={formData.contactDetails.phone}
                                    onChange={handleContactChange}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="contactEmail">Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="contactEmail"
                                    name="email"
                                    value={formData.contactDetails.email}
                                    onChange={handleContactChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end mt-4">
                        <button type="submit" className="btn btn-primary">
                            {isEditMode ? 'Update' : 'Add'} Service Vendor
                        </button>
                    </div>
                </form>
            </Dialog>

            {/* Service Add Dialog */}
            <Dialog
                header="Add New Service"
                visible={isServiceDialogVisible}
                onHide={resetServiceForm}
                className="w-50"
            >
                <div className="p-3">
                    <div className="mb-4">
                        <label className="form-label">Service</label>
                        <div className="d-flex flex-wrap gap-2 mb-3">
                            {predefinedServices.map((service) => (
                                <button
                                    key={service.name}
                                    type="button"
                                    className={`btn ${serviceName === service.name ? 'btn-primary' : 'btn-outline-primary'}`}
                                    onClick={() => {
                                        setServiceName(service.name);
                                        setErrorMessage('');
                                        setIsFieldInvalid(false);
                                    }}
                                >
                                    {service.name}
                                </button>
                            ))}
                        </div>

                        <input
                            type="text"
                            className={`form-control ${isFieldInvalid ? 'is-invalid' : ''}`}
                            value={serviceName}
                            onChange={(e) => {
                                setServiceName(e.target.value);
                                setErrorMessage('');
                                setIsFieldInvalid(false);
                            }}
                            placeholder="Enter service name"
                        />
                        {errorMessage && (
                            <div className="invalid-feedback">{errorMessage}</div>
                        )}
                    </div>

                    <div className="mb-4">
                        <label className="form-label">Applicant Type</label>
                        <div>
                            <div className="form-check mb-2">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="tenant"
                                    checked={applicantTypes.tenant}
                                    onChange={() => {
                                        setApplicantTypes(prev => ({
                                            ...prev,
                                            tenant: !prev.tenant
                                        }));
                                        setErrorMessage('');
                                    }}
                                />
                                <label className="form-check-label" htmlFor="tenant">
                                    Tenant
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="homeOwner"
                                    checked={applicantTypes['home owner']}
                                    onChange={() => {
                                        setApplicantTypes(prev => ({
                                            ...prev,
                                            'home owner': !prev['home owner']
                                        }));
                                        setErrorMessage('');
                                    }}
                                />
                                <label className="form-check-label" htmlFor="homeOwner">
                                    Home Owner
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="mb-4">
                        <label className="form-label">Applicable Taxes</label>
                        {taxRates.length > 0 ? (
                            <div>
                                {taxRates.map(tax => (
                                    <div className="form-check" key={tax._id}>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={`tax-${tax._id}`}
                                            checked={selectedTaxes.some(t => t.taxId === tax._id)}
                                            onChange={() => {
                                                if (selectedTaxes.some(t => t.taxId === tax._id)) {
                                                    setSelectedTaxes(prev => prev.filter(t => t.taxId !== tax._id));
                                                } else {
                                                    setSelectedTaxes(prev => [...prev, {
                                                        taxId: tax._id,
                                                        taxName: tax.taxType,
                                                        taxRate: tax.taxRate
                                                    }]);
                                                }
                                            }}
                                        />
                                        <label className="form-check-label" htmlFor={`tax-${tax._id}`}>
                                            {tax.taxType} ({tax.taxRate}%)
                                        </label>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="text-muted">No tax rates available</div>
                        )}
                    </div>

                    <div className="d-flex justify-content-end">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleAddService}
                            disabled={loading}
                        >
                            Add Service
                        </button>
                    </div>
                </div>
            </Dialog>

            {/* Confirm Dialogs */}
            <ConfirmDialog
                visible={confirmDialogVisible}
                onHide={() => setConfirmDialogVisible(false)}
                message="Are you sure you want to delete this vendor?"
                onConfirm={handleDeleteVendor}
            />

            <ConfirmDialog
                visible={serviceConfirmDialogVisible}
                onHide={() => setServiceConfirmDialogVisible(false)}
                message={`Are you sure you want to delete service "${selectedService?.serviceName}"?`}
                onConfirm={handleDeleteService}
            />
        </Layout>
    );
}

export default ServiceVendor;