import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import Layout from "../../component/layout";
import ConfirmDialog from "../../component/confirmDialog";
import { toastify } from "../../../../utils/toast";
import { getItem } from "../../../../utils/localStorage";
import { makeRequest2 } from "../../../../utils/makeRequest";
import { Button } from "primereact/button";
import { jsPDF } from "jspdf";
import {
  getListOfFacilities,
  getContracts,
  editContract,
  deleteContract,
  disableContract,
} from "../../../../utils/urls";

const LevyContract = () => {
  const navigate = useNavigate();
  const [contracts, setContracts] = useState([]);
  const [filteredContracts, setFilteredContracts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedContractId, setSelectedContractId] = useState(null);

  const [contractId, setContractId] = useState(null);
  const [contractName, setContractName] = useState("");
  const [amount, setAmount] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [paymentFrequency, setPaymentFrequency] = useState("");
  const [selectedRowData, setSelectedRowData] = useState({});
  const [editDialogVisible, setEditDialogVisible] = useState(false);
  const [confirmAction, setConfirmAction] = useState("");
  const [selectedDisableStatus, setSelectedDisableStatus] = useState(false);
  const [isConfirmDialogVisible, setConfirmDialogVisible] = useState(false);
  const [filterType, setFilterType] = useState("all");

  const dateTemplate = (rowData, field) => {
    const date = new Date(rowData[field]);
    return date.toISOString().split("T")[0];
  };

  const handleFilterChange = (e) => {
    const filterValue = e.target.value;
    setFilterType(filterValue);

    let filtered = [...contracts];

    // Apply search term filter
    if (searchTerm) {
      filtered = filtered.filter((contract) =>
        contract.contractName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Apply status filter
    switch (filterValue) {
      case "active":
        filtered = filtered.filter(
          (contract) =>
            !isExpired(contract.endDate) && contract.status === "Active"
        );
        break;
      case "inactive":
        filtered = filtered.filter(
          (contract) => contract.status === "Inactive"
        );
        break;
      case "expired":
        filtered = filtered.filter((contract) => isExpired(contract.endDate));
        break;
      default:
        // Sort by status for "all" view
        filtered.sort((a, b) => {
          // Priority: Active -> Expired -> Inactive
          const getStatusPriority = (contract) => {
            if (contract.status === "Inactive") return 2;
            if (isExpired(contract.endDate)) return 1;
            return 0;
          };
          return getStatusPriority(a) - getStatusPriority(b);
        });
        break;
    }

    setFilteredContracts(filtered);
  };

  const isExpired = (endDate) => {
    return new Date(endDate) < new Date();
  };

  // Modify your fetchContracts function to add the expiry status
  const fetchContracts = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      if (!facilityId) {
        toastify(
          "No facility selected. Please select a facility first.",
          "error"
        );
        return;
      }

      const response = await makeRequest2(
        `${getContracts}/${facilityId}`,
        "GET"
      );
      if (response?.success && Array.isArray(response.data.contracts)) {
        const contractsWithExpiry = response.data.contracts.map((contract) => ({
          ...contract,
          isExpired: isExpired(contract.endDate),
          startDate: new Date(contract.startDate).getTime(),
          endDate: new Date(contract.endDate).getTime(),
        }));

        // Sort contracts by status
        const sortedContracts = contractsWithExpiry.sort((a, b) => {
          // Priority: Active -> Expired -> Inactive
          const getStatusPriority = (contract) => {
            if (contract.status === "Inactive") return 2;
            if (contract.isExpired) return 1;
            return 0;
          };
          return getStatusPriority(a) - getStatusPriority(b);
        });

        setContracts(sortedContracts);
        setFilteredContracts(sortedContracts);
      } else {
        toastify(
          response?.data?.message || "Failed to fetch contracts.",
          "error"
        );
        setContracts([]);
        setFilteredContracts([]);
      }
    } catch (error) {
      console.error("Error fetching contracts:", error);
      toastify(error.message || "Error fetching contracts", "error");
      setContracts([]);
      setFilteredContracts([]);
    }
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filtered = contracts.filter((contract) =>
      contract.contractName.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredContracts(filtered);
  };

  const actionBodyTemplate = (rowData) => {
    const isInactive = rowData.status === "Inactive";
    const isExpired = new Date(rowData.endDate) < new Date();

    return (
      <div className="actions">
        <Button
          icon="ti ti-edit"
          className="p-button-rounded p-button-info mr-2"
          onClick={() => handleEdit(rowData)}
          tooltip="Edit"
        />
        <Button
          icon="ti ti-trash"
          className="p-button-rounded p-button-danger mr-2"
          onClick={() => {
            setSelectedContractId(rowData._id);
            setConfirmAction("delete");
            setConfirmDialogVisible(true);
          }}
          tooltip="Delete"
        />
        {!isExpired && (
          <Button
            icon={`ti ${isInactive ? 'ti-lock-open' : 'ti-lock'}`}
            className="p-button-rounded p-button-warning"
            onClick={() => {
              setSelectedContractId(rowData._id);
              setConfirmAction(isInactive ? "activate" : "deactivate");
              setSelectedDisableStatus(isInactive);
              setConfirmDialogVisible(true);
            }}
            tooltip={isInactive ? "Activate" : "Deactivate"}
          />
          
        )}
        <Button
                icon="ti ti-download"
                className="p-button-rounded p-button-success"
                onClick={() => downloadContract(rowData)}
                tooltip="Download Contract"
            />
      </div>
    );
  };

  // Add the handleStatusToggle function
  const handleStatusToggle = async (rowData) => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const newStatus = rowData.status === "Inactive" ? "Active" : "Inactive";

      const response = await makeRequest2(
        `${disableContract}/${facilityId}/${rowData._id}`,
        "POST",
        { status: newStatus }
      );

      if (response.success) {
        toastify(`Contract ${newStatus.toLowerCase()} successfully`, "success");
        fetchContracts();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };

  const handleEdit = (rowData) => {
    setContractId(rowData._id);
    setContractName(rowData.contractName);
    setAmount(rowData.amount);
    setStartDate(new Date(rowData.startDate).toISOString().split("T")[0]);
    setEndDate(new Date(rowData.endDate).toISOString().split("T")[0]);
    setPaymentFrequency(rowData.paymentFrequency);
    setSelectedRowData(rowData);
    setEditDialogVisible(true);
  };

  const handleEditSubmit = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const updatedData = {
        contractName,
        amount,
        startDate,
        endDate,
        paymentFrequency,
      };
      const response = await makeRequest2(
        `${editContract}/${facilityId}/${contractId}`,
        "PUT",
        updatedData
      );
      if (response.success) {
        toastify("Contract updated successfully", "success");
        handleClear();
        setEditDialogVisible(false);
        fetchContracts();
      } else {
        toastify(response.error || "Failed to update contract", "error");
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };

  const handleClear = () => {
    setContractName("");
    setAmount("");
    setStartDate("");
    setEndDate("");
    setPaymentFrequency("");
  };

  const handleConfirmAction = async () => {
    setConfirmDialogVisible(false);

    try {
      if (confirmAction === "delete") {
        await handleDelete(selectedContractId);
      } else if (
        confirmAction === "activate" ||
        confirmAction === "deactivate"
      ) {
        const newStatus = confirmAction === "activate" ? "Active" : "Inactive";
        await handleStatusToggle({
          _id: selectedContractId,
          status: newStatus === "Active" ? "Inactive" : "Active",
        });
      }
    } catch (error) {
      toastify(error.message, "error");
    }
  };

  const handleDelete = async (contractId) => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        `${deleteContract}/${facilityId}/${contractId}`,
        "DELETE"
      );

      if (response.success) {
        toastify("Contract deleted successfully", "success");
        fetchContracts();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };

  // Modify the statusTemplate function
  const statusTemplate = (rowData) => {
    const isExpired = new Date(rowData.endDate) < new Date();
    const isInactive = rowData.status === "Inactive";

    let color, bgColor, status;

    if (isExpired) {
      color = "#ff8c00"; // Dark orange for expired
      bgColor = "#fff3e6"; // Light orange background
      status = "Expired";
    } else if (isInactive) {
      color = "#ff4d4d"; // Red for inactive
      bgColor = "#ffebeb"; // Light red background
      status = "Inactive";
    } else {
      color = "#4caf50"; // Green for active
      bgColor = "#e8f5e9"; // Light green background
      status = "Active";
    }

    return (
      <span
        style={{
          fontWeight: "bold",
          color: color,
          padding: "5px 10px",
          borderRadius: "15px",
          backgroundColor: bgColor,
        }}
      >
        {status}
      </span>
    );
  };

  const downloadContract = async (contract) => {
    const facilityId = await getItem("selectedFacilityId");
    if (!facilityId) {
      console.error("No facility selected.");
      return;
    }

    // Create a new jsPDF instance
    const doc = new jsPDF();

    // Add PaySeve logo
    // Note: Replace with actual logo URL or base64
    const logoWidth = 50;
    const logoHeight = 20;
    doc.addImage(
      "../../../../assets/images/PayServeLogoFinal.png",
      20,
      10,
      logoWidth,
      logoHeight
    );

    // Add a horizontal line below the logo
    doc.setDrawColor(0, 51, 102);
    doc.line(20, 35, 190, 35);

    // Title Section
    doc.setFontSize(18);
    doc.setTextColor(0, 51, 102);
    doc.text("CONTRACT AGREEMENT", 105, 50, { align: "center" });

    // Contract details
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);

    // Add contract details in a structured format
    const content = [
      { label: "Contract Reference:", value: contract._id },
      { label: "Contract Name:", value: contract.contractName },
      { label: "Customer:", value: contract.customerName },
      { label: "Unit:", value: contract.unitName },
      { label: "Amount:", value: `KES ${contract.amount}` },
      { label: "Payment Frequency:", value: contract.paymentFrequency },
      {
        label: "Start Date:",
        value: new Date(contract.startDate).toLocaleDateString(),
      },
      {
        label: "End Date:",
        value: new Date(contract.endDate).toLocaleDateString(),
      },
      { label: "Status:", value: contract.status },
    ];

    let yPosition = 70;

    content.forEach((item) => {
      if (item.label && item.value) {
        doc.setFont("helvetica", "bold");
        doc.text(item.label, 20, yPosition);
        doc.setFont("helvetica", "normal");
        doc.text(String(item.value), 80, yPosition);
        yPosition += 10;
      }
    });

    // Terms and Conditions section
    yPosition += 10;
    doc.setFont("helvetica", "bold");
    doc.text("Terms and Conditions:", 20, yPosition);
    yPosition += 10;
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    // Add your terms here

    // Signature section
    yPosition = 250;
    doc.line(20, yPosition, 90, yPosition);
    doc.line(120, yPosition, 190, yPosition);
    doc.setFontSize(10);
    doc.text("Customer Signature", 20, yPosition + 5);
    doc.text("PaySeve Representative", 120, yPosition + 5);

    // Footer
    doc.setFontSize(8);
    doc.text("PaySeve Limited - Your Trusted Payment Partner", 105, 285, {
      align: "center",
    });

    doc.save(`${contract.contractName}_contract.pdf`);
  };

  useEffect(() => {
    fetchContracts();
  }, []);

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/app/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">Levy Management</li>
                <li className="breadcrumb-item">Levy Contracts</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>Levy Contracts</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-3 mb-3">
                  <input
                    className="form-control"
                    placeholder="Search here"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <select
                    className="form-control"
                    value={filterType}
                    onChange={handleFilterChange}
                  >
                    <option value="all">All Contracts</option>
                    <option value="active">Active Contracts</option>
                    <option value="inactive">Inactive Contracts</option>
                    <option value="expired">Expired Contracts</option>
                  </select>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        navigate(
                          "/facility/levy_management/contracts/add_contract"
                        )
                      }
                    >
                      Add Contract
                    </button>
                  </div>
                </div>
              </div>
              <DataTable
                value={filteredContracts}
                emptyMessage="No contracts found."
                paginator
                rows={5}
                stripedRows
                tableStyle={{ minWidth: "50rem" }}
                sortMode="multiple"
                removableSort
                defaultSortOrder={-1} // Descending by default
              >
                <Column
                  field="contractName"
                  header="Name"
                  sortable
                  sortField="contractName"
                />
                <Column
                  field="startDate"
                  header="Starting Date"
                  sortable
                  sortField="startDate"
                  body={(rowData) => dateTemplate(rowData, "startDate")}
                />
                <Column
                  field="endDate"
                  header="Ending Date"
                  sortable
                  sortField="endDate"
                  body={(rowData) => dateTemplate(rowData, "endDate")}
                />
                <Column
                  field="status"
                  header="Status"
                  body={statusTemplate}
                  sortable
                  sortField="status"
                />
                <Column body={actionBodyTemplate} header="Actions" />
              </DataTable>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        header={`Edit Levy`}
        style={{ width: "50vw" }}
        visible={editDialogVisible}
        onHide={() => {
          if (!editDialogVisible) return;
          setEditDialogVisible(false);
        }}
      >
        <div className="row">
          <div className="col-md-12 mt-3">
            <div className="mb-4">
              <label>Name:</label>
              <input
                className="form-control mt-2"
                type="text"
                placeholder="Levy Name"
                value={contractName}
                onChange={(e) => {
                  const value = e.target.value;
                  setContractName(value);
                }}
              ></input>
            </div>

            <div className="mb-4">
              <label>Amount:</label>
              <input
                className="form-control mt-2"
                type="text"
                placeholder="Levy Amount"
                value={amount}
                onChange={(e) => {
                  const value = e.target.value;
                  setAmount(value);
                }}
              ></input>
            </div>

            <div className="mb-4">
              <label className="form-label">Payment Frequency</label>
              <select
                className="form-control mt-2"
                value={paymentFrequency}
                onChange={(e) => setPaymentFrequency(e.target.value)}
              >
                <option value="">Select</option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
                <option value="Quarterly">Quarterly</option>
                <option value="Semi-anually">Semi-anually</option>
                <option value="Annually">Annually</option>
              </select>
            </div>

            <div className="mb-4">
              <label className="form-label">Start Date</label>
              <input
                type="date"
                className="form-control"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <label className="form-label">End Date</label>
              <input
                type="date"
                className="form-control"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>

            <div className="mt-4" style={{ float: "right" }}>
              <button className="btn btn-primary" onClick={handleEditSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </Dialog>

      <ConfirmDialog
        visible={isConfirmDialogVisible}
        onHide={() => setConfirmDialogVisible(false)}
        message={
          confirmAction === "delete"
            ? "Are you sure you want to delete this contract?"
            : `Are you sure you want to ${confirmAction} this contract?`
        }
        onConfirm={handleConfirmAction}
      />
    </Layout>
  );
};

export default LevyContract;
