import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-blue/theme.css";
import Layout from "../../component/layout";
function ExpenseManagement() {
  const header = (
    <div className="flex align-items-center justify-content-end gap-2">
      <Button
        type="button"
        label="CSV"
        rounded
        onClick={() => {}}
        data-pr-tooltip="CSV"
      />
      <Button
        type="button"
        label="XLS"
        severity="success"
        rounded
        onClick={() => {}}
        data-pr-tooltip="XLS"
      />
      <Button
        type="button"
        label="PDF"
        severity="warning"
        rounded
        onClick={() => {}}
        data-pr-tooltip="PDF"
      />
    </div>
  );
  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/facility/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/facility/expense_management"}>
                    Expense Management
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-body py-0">
                          <ul
                            className="nav nav-tabs profile-tabs"
                            id="myTab"
                            role="tablist"
                          >
                            <li className="nav-item">
                              <a
                                className="nav-link active"
                                id="profile-tab-1"
                                data-bs-toggle="tab"
                                href="#profile-1"
                                role="tab"
                                aria-selected="true"
                              >
                                <i className="ti ti-plus me-2"></i>Expense
                                Category
                              </a>
                            </li>

                            <li className="nav-item">
                              <a
                                className="nav-link"
                                id="profile-tab-2"
                                data-bs-toggle="tab"
                                href="#profile-2"
                                role="tab"
                                aria-selected="true"
                              >
                                <i className="ti ti-list me-2"></i>Add Budget
                                per Category
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                id="profile-tab-2"
                                data-bs-toggle="tab"
                                href="#profile-3"
                                role="tab"
                                aria-selected="true"
                              >
                                <i className="ti ti-list me-2"></i>Add Expense
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="tab-content">
                        <div
                          className="tab-pane show active"
                          id="profile-1"
                          role="tabpanel"
                          aria-labelledby="profile-tab-1"
                        >
                          <div className="row">
                            <div className="col-lg-12 col-xxl-12">
                              <div className="card">
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <button
                                        type="button"
                                        class="btn btn-primary"
                                        data-bs-toggle="modal"
                                        data-bs-target="#addExpenseCategoryModal"
                                        data-bs-whatever="@getbootstrap"
                                      >
                                        Add Expense
                                      </button>
                                      <div
                                        class="modal fade"
                                        id="addExpenseCategoryModal"
                                        tabindex="-1"
                                        role="dialog"
                                        aria-labelledby="addExpenseCategoryModalLabel"
                                        aria-hidden="true"
                                      >
                                        <div
                                          class="modal-dialog"
                                          role="document"
                                        >
                                          <div class="modal-content">
                                            <div class="modal-header">
                                              <h5
                                                class="modal-title"
                                                id="addExpenseCategoryModalLabel"
                                              >
                                                New Category
                                              </h5>
                                              <button
                                                type="button"
                                                class="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                              ></button>
                                            </div>
                                            <div class="modal-body">
                                              <form>
                                                <div class="mb-3">
                                                  <label
                                                    for="recipient-name"
                                                    class="col-form-label pt-0"
                                                  >
                                                    Title:
                                                  </label>
                                                  <input
                                                    type="text"
                                                    class="form-control"
                                                    id="recipient-name"
                                                  />
                                                </div>
                                              </form>
                                            </div>
                                            <div class="modal-footer">
                                              <button
                                                type="button"
                                                class="btn btn-secondary"
                                                data-bs-dismiss="modal"
                                              >
                                                Close
                                              </button>
                                              <button
                                                type="button"
                                                class="btn btn-primary"
                                              >
                                                Add Category
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane"
                          id="profile-2"
                          role="tabpanel"
                          aria-labelledby="profile-tab-2"
                        >
                          <div className="row">
                            <div className="col-lg-12 col-xxl-12">
                              <div className="card">
                                <div className="card-body position-relative">
                                  <div className="col-md-3 col-xs-12  mb-3">
                                    <br />
                                    <input
                                      className="form-control"
                                      placeholder="Search here"
                                      value={""}
                                      onChange={(e) => {}}
                                    />
                                  </div>
                                  <div className="dt-responsive table-responsive">
                                    <DataTable
                                      value={[]}
                                      header={header}
                                      emptyMessage="No power meters found."
                                      sortMode="multiple"
                                      paginator
                                      rows={5}
                                      stripedRows
                                      tableStyle={{ minWidth: "50rem" }}
                                    >
                                      <Column
                                        field="meter_sn"
                                        header="Meter S/N"
                                      ></Column>
                                      <Column
                                        field="unit"
                                        header="Unit"
                                      ></Column>
                                      <Column
                                        field="reading"
                                        header="Reading (kwh)"
                                      ></Column>
                                      <Column
                                        field="status"
                                        header="Status"
                                      ></Column>
                                      <Column
                                        field="last_updated"
                                        header="Last Updated"
                                      ></Column>
                                    </DataTable>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane"
                          id="profile-3"
                          role="tabpanel"
                          aria-labelledby="profile-tab-2"
                        >
                          <div className="row">
                            <div className="col-lg-12 col-xxl-12">
                              <div className="card">
                                <div className="card-body position-relative">
                                  <div className="col-md-3 col-xs-12  mb-3">
                                    <br />
                                    <input
                                      className="form-control"
                                      placeholder="Search here"
                                      value={""}
                                      onChange={(e) => {}}
                                    />
                                  </div>
                                  <div className="dt-responsive table-responsive">
                                    <DataTable
                                      value={[]}
                                      header={header}
                                      emptyMessage="No power meters found."
                                      sortMode="multiple"
                                      paginator
                                      rows={5}
                                      stripedRows
                                      tableStyle={{ minWidth: "50rem" }}
                                    >
                                      <Column
                                        field="meter_sn"
                                        header="Meter S/N"
                                      ></Column>
                                      <Column
                                        field="unit"
                                        header="Unit"
                                      ></Column>
                                      <Column
                                        field="reading"
                                        header="Reading (kwh)"
                                      ></Column>
                                      <Column
                                        field="status"
                                        header="Status"
                                      ></Column>
                                      <Column
                                        field="last_updated"
                                        header="Last Updated"
                                      ></Column>
                                    </DataTable>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ExpenseManagement;
