import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { makeRequest2 } from '../../../../../utils/makeRequest';
import { delete_lease_template } from '../../../../../utils/urls';
import { getItem } from '../../../../../utils/localStorage';
import { toastify } from '../../../../../utils/toast';

const DeleteButton = ({ templateId, onDelete, fetchLeaseTemplates }) => {
    const [facilityId, setFacilityId] = useState(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    useEffect(() => {
        const fetchFacilityId = async () => {
            try {
                const id = await getItem('selectedFacilityId');
                setFacilityId(id);
            } catch (error) {
                console.error('Error fetching facilityId:', error);
                toastify('Failed to fetch Facility ID', 'error');
            }
        };
        fetchFacilityId();
    }, []);

    const handleDelete = async () => {
        if (!facilityId) {
            toastify('Facility ID is not available', 'error');
            return;
        }

        try {
            const response = await makeRequest2(
                `${delete_lease_template}/${facilityId}/${templateId}`,
                'DELETE'
            );

            if (response.success) {
                toastify('Template deleted successfully!', 'success');
                onDelete(templateId);
                setShowConfirmDialog(false);

                if (fetchLeaseTemplates && typeof fetchLeaseTemplates === 'function') {
                    fetchLeaseTemplates(); // Call fetchLeaseTemplates if it's provided
                }
            } else {
                toastify(response.error || 'Failed to delete lease template', 'error');
            }
        } catch (error) {
            console.error('Error deleting lease template:', error);
            toastify(error.message || 'An error occurred while deleting the lease template', 'error');
        }
    };

    return (
        <>
            <Button
                icon="ti ti-trash"
                className="p-button-rounded p-button-danger ml-2"
                onClick={() => setShowConfirmDialog(true)}
                tooltip="Delete"
            />

            <Dialog
                visible={showConfirmDialog}
                onHide={() => setShowConfirmDialog(false)}
                header="Confirm Delete"
                footer={
                    <div>
                        <Button
                            label="No"
                            icon="pi pi-times"
                            className="p-button-text"
                            onClick={() => setShowConfirmDialog(false)}
                        />
                        <Button
                            label="Yes"
                            icon="pi pi-trash"
                            className="p-button-danger"
                            onClick={handleDelete}
                        />
                    </div>
                }
            >
                <p>Are you sure you want to delete this template?</p>
            </Dialog>
        </>
    );
};

export default DeleteButton;
