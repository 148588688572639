import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Link } from 'react-router-dom';
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { useNavigate } from "react-router-dom";
import { toastify } from '../../../../utils/toast'; 
import { makeRequest2 } from "../../../../utils/makeRequest";
import { useFacilityStore } from "../../../../app/z-store/store";
import { getLeaseInvoice } from "../../../../utils/urls";
import { Toolbar } from "primereact/toolbar";
import Layout from "../../component/layout";

const InvoiceManagement = () => {
  const navigate = useNavigate();
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [dateFilters, setDateFilters] = useState({
    startDate: null,
    endDate: null,
  });
  const toast = useRef(null);
  const selectedFacilityId = useFacilityStore((state) => state.facilityId);

  const fetchInvoices = async () => {
    try {
      setLoading(true);
      const response = await makeRequest2(`${getLeaseInvoice}/${selectedFacilityId}`, "GET");

      if (response.success) {
        // Ensure we're working with an array and handle potential nested data structure
        const invoiceData = Array.isArray(response.data) ? response.data :
                          Array.isArray(response.data?.data) ? response.data.data : [];
        
        setInvoices(invoiceData);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.message || "Failed to fetch lease invoices",
        });
        setInvoices([]); // Reset to empty array on error
      }
    } catch (error) {
      console.error('Error fetching invoices:', error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "Error loading lease invoices",
      });
      setInvoices([]); // Reset to empty array on error
    } finally {
      setLoading(false);
    }
  };

  const handleViewInvoice = (invoiceId) => {
    navigate(`/facility/lease_management/invoice/${invoiceId}`, {
      state: { facilityId: selectedFacilityId }
    });
  };

  const handleDelete = async (invoiceId) => {
    confirmDialog({
      message: "Are you sure you want to delete this invoice?",
      header: "Delete Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept: async () => {
        try {
          const response = await makeRequest2(
            `/api/app/lease_management/delete_invoice/${selectedFacilityId}/${invoiceId}`,
            "DELETE"
          );

          if (response.success) {
            toastify('Invoice deleted successfully', 'success');
            fetchInvoices();
          } else {
            toastify(response.message || 'Failed to delete invoice', 'error');
          }
        } catch (error) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: error.message,
          });
        }
      },
    });
  };

  const formatDate = (value) => {
    return new Date(value).toLocaleDateString();
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-KE', {
      style: 'currency',
      currency: 'KES',
      minimumFractionDigits: 2
    }).format(value);
  };

  const statusTemplate = (rowData) => {
    const getSeverity = (status) => {
      switch (status?.toLowerCase()) {
        case "paid":
          return { color: "#4caf50", bgColor: "#e8f5e9" };
        case "pending":
          return { color: "#ff9800", bgColor: "#fff3e0" };
        case "overdue":
          return { color: "#f44336", bgColor: "#ffebee" };
        default:
          return { color: "#2196f3", bgColor: "#e3f2fd" };
      }
    };
  
    const statusStyle = getSeverity(rowData.status);
  
    return (
      <span
        style={{
          fontWeight: "bold",
          color: statusStyle.color,
          padding: "5px 10px",
          borderRadius: "15px",
          backgroundColor: statusStyle.bgColor,
        }}
      >
        {rowData.status}
      </span>
    );
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="flex gap-2">
        <Button
          icon="ti ti-eye"
          className="p-button-rounded p-button-success p-ml-2"
          onClick={(e) => {
            e.stopPropagation();
            handleViewInvoice(rowData._id);
          }}
          tooltip="View Details"
        />
        <Button
          icon="ti ti-trash"
          className="p-button-rounded p-button-danger mr-2"
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(rowData._id);
          }}
          tooltip="Delete"
        />
      </div>
    );
  };

  const amountTemplate = (rowData) => {
    return formatCurrency(rowData.totalAmount);
  };

  const customerTemplate = (rowData) => {
    return rowData.customerInfo?.fullName || 'N/A';
  };

  const filteredInvoices = invoices.filter((invoice) => {
    // Text search filter
    const matchesSearch =
      invoice.invoiceNumber?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      invoice.customerInfo?.fullName?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      invoice.status?.toLowerCase().includes(searchKeyword.toLowerCase());

    // Date range filter
    const invoiceDate = new Date(invoice.issueDate);
    const startDateMatch = !dateFilters.startDate ||
      invoiceDate >= new Date(dateFilters.startDate);
    const endDateMatch = !dateFilters.endDate ||
      invoiceDate <= new Date(dateFilters.endDate);

    return matchesSearch && startDateMatch && endDateMatch;
  });

  useEffect(() => {
    if (selectedFacilityId) {
      fetchInvoices();
    }
  }, [selectedFacilityId]);

  const header = (
    <Toolbar
      className="mb-4"
      left={
        <div className="flex align-items-center gap-3">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              placeholder="Search invoices..."
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
              style={{ height: "40px" }}
            />
          </span>
          <Calendar
            value={dateFilters.startDate}
            onChange={(e) =>
              setDateFilters({ ...dateFilters, startDate: e.value })
            }
            placeholder="Start Date"
            style={{ height: "40px" }}
            inputStyle={{ height: "40px" }}
          />
          <Calendar
            value={dateFilters.endDate}
            onChange={(e) =>
              setDateFilters({ ...dateFilters, endDate: e.value })
            }
            placeholder="End Date"
            style={{ height: "40px" }}
            inputStyle={{ height: "40px" }}
          />
        </div>
      }
      right={
        <div className="flex align-items-center gap-2">
          <Button
            label="Filter"
            icon="pi pi-filter"
            onClick={fetchInvoices}
            className="p-button-outlined"
            style={{ height: "40px" }}
          />
          <Button
            label="Clear"
            icon="pi pi-times"
            onClick={() => {
              setDateFilters({ startDate: null, endDate: null });
              setSearchKeyword("");
              fetchInvoices();
            }}
            className="p-button-outlined p-button-secondary"
            style={{ height: "40px" }}
          />
        </div>
      }
    />
  );

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/app/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">Lease Management</li>
                <li className="breadcrumb-item">Lease Invoices</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Toast ref={toast} />
      <ConfirmDialog />

      <Card>
        <DataTable
          value={filteredInvoices}
          header={header}
          loading={loading}
          paginator
          rows={10}
          emptyMessage="No lease invoices found"
          className="p-datatable-sm"
          stripedRows
          selectionMode="single"
          sortField="issueDate"
          sortOrder={-1}
          responsiveLayout="scroll"
        >
          <Column
            field="invoiceNumber"
            header="Invoice #"
            style={{ minWidth: "150px" }}
          />
          <Column
            field="customerInfo.fullName"
            header="Customer"
            body={customerTemplate}
            style={{ minWidth: "200px" }}
          />
          <Column
            field="unit.name"
            header="Unit"
            style={{ minWidth: "150px" }}
          />
          <Column
            field="totalAmount"
            header="Amount"
            body={amountTemplate}
            style={{ minWidth: "120px" }}
          />
          <Column
            field="issueDate"
            header="Issue Date"
            body={(rowData) => formatDate(rowData.issueDate)}
            style={{ minWidth: "120px" }}
          />
          <Column
            field="dueDate"
            header="Due Date"
            body={(rowData) => formatDate(rowData.dueDate)}
            style={{ minWidth: "120px" }}
          />
          <Column
            field="status"
            header="Status"
            body={statusTemplate}
            style={{ minWidth: "120px" }}
          />
          <Column
            header="Actions"
            body={actionTemplate}
            style={{ minWidth: "100px" }}
          />
        </DataTable>
      </Card>
    </Layout>
  );
};

export default InvoiceManagement;