import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import Layout from '../../component/layout'
import { getItem } from '../../../../utils/localStorage';

function VisitorManagementHome() {
    const [entryName, setEntryName] = useState('')
    const navigate = useNavigate();


    const getCurrentAccessPoint = async () => {
        try {

            const value = await getItem('selectedEntryPoint');
            if (value) {
                const data = JSON.parse(value)
                setEntryName(data.name)
            }


        }
        catch (err) {
            console.log(err.message)
        }
    }

    useEffect(() => {
        getCurrentAccessPoint()
    }, [])



    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Visitor Access Management</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Home</Link>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">


                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-5">
                                <div className="col-md-12 text-center">
                                    <h5 style={{ color: 'black' }}>Access Point: {entryName}</h5>
                                </div>
                            </div>
                            <div className="row">

                                <div className="d-flex flex-column align-items-center gap-3 w-75 m-auto">
                                    <div className="d-flex gap-3 w-100">
                                        <button
                                            className="btn btn-primary btn-lg flex-grow-1 py-3 rounded d-flex flex-column align-items-center"
                                            onClick={() => navigate('/facility/visitor_access_management/scan_qr_code/')}
                                        >
                                            <span className="pc-micon mb-2">
                                                <svg className="pc-icon">
                                                    <use xlinkHref="#custom-cpu-charge" />
                                                </svg>
                                            </span>
                                            Scan QR Code
                                        </button>
                                        <button
                                            className="btn btn-success btn-lg flex-grow-1 py-3 rounded d-flex flex-column align-items-center"
                                            onClick={() => navigate('/facility/visitor_access_management/verify_code/')}
                                        >
                                            <span className="pc-micon mb-2">
                                                <svg className="pc-icon">
                                                    <use xlinkHref="#custom-24-support" />
                                                </svg>
                                            </span>
                                            Verify Code
                                        </button>
                                    </div>
                                    <div className="d-flex gap-3 w-100">
                                        <button
                                            className="btn btn-warning btn-lg flex-grow-1 py-3 rounded d-flex flex-column align-items-center"
                                            onClick={() => navigate('/facility/visitor_access_management/visitor_registration/gate_registration')}
                                        >
                                            <span className="pc-micon mb-2">
                                                <svg className="pc-icon">
                                                    <use xlinkHref="#custom-user-add" />
                                                </svg>
                                            </span>
                                            Gate Registration
                                        </button>
                                        <button
                                            className="btn btn-dark btn-lg flex-grow-1 py-3 rounded d-flex flex-column align-items-center"
                                            onClick={() => navigate('/facility/visitor_access_management/waiting_list')}
                                        >
                                            <span className="pc-micon mb-2">
                                                <svg className="pc-icon">
                                                    <use xlinkHref="#custom-flag" />
                                                </svg>
                                            </span>
                                            Waiting List
                                        </button>

                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </Layout>
    )
}

export default VisitorManagementHome