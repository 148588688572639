import React, { useState, useEffect } from 'react';
import Layout from '../component/layout'
import { Link,useNavigate, useParams } from 'react-router-dom'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { toastify } from '../../../utils/toast';
import { getItem } from '../../../utils/localStorage';
import { makeRequest2 } from '../../../utils/makeRequest';
import { getCustomerURL, getUnitsForFacility, getCustomerUnits } from '../../../utils/urls';


const ViewCustomer = () => {
    const navigate = useNavigate()

    const { customerId } = useParams();
    const [customer, setCustomer] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [units, setUnits] = useState([])
    const [customerUnits, setCustomerUnits] = useState([])

    

    const fetchUnits = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId')
            const response = await makeRequest2(`${getUnitsForFacility}/${facilityId}`, 'GET', {});
            if (response.success) {
                const non_occupied_units = response.data.filter((x) => {
                    return x.homeOwnerId === undefined && x.tenantId === undefined
                })

                setUnits(non_occupied_units);
            } else {
                throw new Error('Error fetching units')
            }
        } catch (err) {
            console.error('Error fetching units:', err);
        }
    };

    const getCustomer = async () => {
        try {
            const response = await makeRequest2(getCustomerURL + '/' + customerId, 'GET')

            if (response.success === true) {
                const customer = response.data;
                setCustomer(customer)
                setFirstName(customer.firstName);
                setLastName(customer.lastName);
                setPhoneNumber(customer.phoneNumber);
            }
            else {
                throw new Error(response.error)
            }
        }
        catch (err) {
            toastify(err.message, 'error')
        }
    }

    const getUnitsForCustomer = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId')
            const response = await makeRequest2(`${getCustomerUnits}/${facilityId}/${customerId}`, 'GET', {});
            if(response.success){
                setCustomerUnits(response.data);
            }
        }
        catch (error) {
            toastify('Error fetching units: ' + error.message);
        }
    };

    useEffect(()=>{
        getCustomer()
        fetchUnits()
        getUnitsForCustomer()
    },[])

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/core/dashboard/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/customer_management"}>Customer  Management</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>View Customer</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <Link to={"#"} onClick={() => navigate(-1)}><span><i className="ti ti-arrow-narrow-left"></i> Back</span></Link>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body py-0">
                                    <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="profile-tab-1" data-bs-toggle="tab" href="#profile-1" role="tab" aria-selected="true">
                                                <i className="ti ti-building-bank me-2"></i>Info
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="profile-tab-2" data-bs-toggle="tab" href="#profile-2" role="tab" aria-selected="true">
                                                <i className="ti ti-building-warehouse me-2"></i>Units
                                            </a>
                                        </li>

                                    </ul>
                                </div>
                            </div>

                            <div className="tab-content">
                                <div className="tab-pane show active" id="profile-1" role="tabpanel" aria-labelledby="profile-tab-1">
                                    <div className="row">
                                        <div className="col-lg-4 col-xxl-3">
                                            <div className="card">
                                                <div className="card-body position-relative">
                                                    <div className="text-center mt-3">
                                                        <div className="chat-avtar d-inline-flex mx-auto">
                                                            <i className="ti ti-user me-2" style={{ fontSize: 100 }}></i>
                                                        </div>
                                                        <h5 className="mb-0"></h5>
                                                        <h2 className="text-muted mt-2">{customer.firstName} {customer.lastName}</h2>
                                                        
                                                        <hr className="my-3 border border-secondary-subtle" />

                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                            <p className="mb-0"><strong>Email: </strong>{customer.email}</p>
                                                        </div>
                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                            <p className="mb-0"><strong>Phone No.: </strong>{customer.phoneNumber}</p>
                                                        </div>
                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                            <p className="mb-0"><strong>ID No: </strong>{customer.idNumber}</p>
                                                        </div>
                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                            <p className="mb-0"><strong>Type: </strong>{customer.customerType}</p>
                                                        </div>
                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                            <p className="mb-0"><strong>Resident: </strong>{customer.residentType}</p>
                                                        </div>
                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                            {/* <p className="mb-0"><strong>landRateNumber: </strong>1500 {defaultMeasurement.symbol}</p> */}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="profile-2" role="tabpanel" aria-labelledby="profile-tab-2">

                                    <div className="card">

                                        <div className="card-header">

                                            <div>
                                                {/* <div style={{ float: 'right' }}>
                                            <button className="btn btn-primary" onClick={addUnoccupiedUnit}>
                                                <i className="ti ti-circle-plus me-2"></i> Add Unit</button>
                                        </div> */}
                                                <div style={{ float: 'right' }}>
                                                    <button className="btn btn-primary" 
                                                    // onClick={() => {setAddUnitDialogVisible(true)}}
                                                    ><i className="ti ti-circle-plus me-2"></i> Add Unit</button>
                                                </div>

                                            </div>

                                        </div>

                                        <div className="card-body">
                                            <div className="tab-content" id="nav-tabContent">

                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <DataTable value={customerUnits} emptyMessage="No units found." sortMode="multiple" paginator rows={10} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                                                <Column field="name" sortable header="Name"></Column>
                                                                <Column field="unitType" header="Type"></Column>
                                                                <Column field="floorUnitNo" header="Floor/Unit"></Column>
                                                                <Column field="lettableFloorArea" header="LFA"></Column>
                                                                <Column field="landRateNumber" header="LRN"></Column>
                                                                <Column field="grossArea" header="GA"></Column>
                                                                <Column field="netLettableArea" header="NLA"></Column>

                                                            </DataTable>

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
            
            {/* <Dialog
                header={'Add Unit To Customer'}
                visible={addUnitDialog}

                onHide={() => {
                    if (addUnitDialog) setAddUnitDialogVisible(false);
                }}
            >
                <div className="row">
                    <div className="col-md-12 mt-3">


                        <div className="mb-3">
                            <label className="form-label">Unit<span style={{ color: 'red' }}>*</span></label>
                            <Autocomplete
                                multiple // Enable multiple select
                                options={units}
                                getOptionLabel={(option) => option.name} // Define how each option is displayed
                                value={selectedUnits}
                                onChange={(event, newValue) => setSelectedUnits(newValue)} // Handle selection
                                renderInput={(params) => (
                                    <TextField {...params} label="Select Units"
                                        value={units}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            console.log(value)
                                            setUnits(value)
                                        }}
                                        variant="outlined" />
                                )}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            key={option._id}
                                            label={option.name}
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                } // Display selected units as chips
                            />
                        </div>


                        <div className='mt-4' style={{ float: 'right' }}>
                            <button className="btn btn-primary" onClick={addUnoccupiedUnit}>
                                Submit
                            </button>
                        </div>

                    </div>

                </div>



            </Dialog> */}
        </Layout>
    )
}

export default ViewCustomer