import React from "react";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";

const ChecklistItem = ({ item, onEdit }) => {
  const getStatusSeverity = (status) => {
    switch (status) {
      case "Working":
        return "success";
      case "Not Working":
        return "danger";
      default:
        return "info";
    }
  };

  return (
    <div className="surface-card p-4 border-round mb-3">
      <div className="flex align-items-center justify-content-between">
        <div>
          <h5 className="mt-0 mb-2">{item.itemName}</h5>
          <div className="flex align-items-center gap-2">
            <Tag value={item.category} />
            <Tag 
              severity={getStatusSeverity(item.status)} 
              value={item.status}
            />
          </div>
        </div>
        
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text"
          onClick={() => onEdit(item)}
        />
      </div>

      {item.notes && (
        <p className="text-color-secondary mt-2 mb-2">{item.notes}</p>
      )}

      {item.photos?.length > 0 && (
        <div className="grid gap-2 mt-2">
          {item.photos.map((photo, index) => (
            <img
              key={index}
              src={photo.url}
              alt={`Item ${index + 1}`}
              className="w-24 h-24 object-cover rounded"
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ChecklistItem;