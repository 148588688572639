// UpdateLease.jsx
import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { makeRequest2 } from "../../../../utils/makeRequest";
import { update_lease, getUnitsForFacility } from "../../../../utils/urls";
import { getItem } from "../../../../utils/localStorage";
import { toastify } from "../../../../utils/toast";

const UpdateLease = ({ 
  visible, 
  onHide, 
  leaseData, 
  onUpdate 
}) => {
  // State management for form fields
  const [unitNumber, setUnitNumber] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [leaseDuration, setLeaseDuration] = useState("");
  const [autoRenewal, setAutoRenewal] = useState(true);
  const [monthlyRent, setMonthlyRent] = useState("");
  const [paymentDueDate, setPaymentDueDate] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [securityDeposit, setSecurityDeposit] = useState("");
  const [billingFrequency, setBillingFrequency] = useState("");
  const [nextInvoiceDate, setNextInvoiceDate] = useState("");
  const [autoSend, setAutoSend] = useState(true);
  const [units, setUnits] = useState([]);

  useEffect(() => {
    if (leaseData) {
      setUnitNumber(leaseData.unitNumber || "");
      setStartDate(new Date(leaseData.leaseTerms?.startDate).toISOString().split('T')[0]);
      setEndDate(new Date(leaseData.leaseTerms?.endDate).toISOString().split('T')[0]);
      setLeaseDuration(leaseData.leaseTerms?.duration || "");
      setAutoRenewal(leaseData.leaseTerms?.autoRenewal || false);
      setMonthlyRent(leaseData.financialTerms?.monthlyRent || "");
      setPaymentDueDate(leaseData.financialTerms?.paymentDueDate || "");
      setPaymentMethod(leaseData.financialTerms?.paymentMethod || "");
      setSecurityDeposit(leaseData.financialTerms?.securityDeposit || "");
      setBillingFrequency(leaseData.billingCycle?.frequency || "");
      setNextInvoiceDate(new Date(leaseData.billingCycle?.nextInvoiceDate).toISOString().split('T')[0]);
      setAutoSend(leaseData.billingCycle?.autoSend || false);
    }
    fetchUnits();
  }, [leaseData]);

  const fetchUnits = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        `${getUnitsForFacility}/${facilityId}`,
        "GET"
      );
      if (response.success) {
        setUnits(response.data);
      } else {
        throw new Error("Failed to fetch units.");
      }
    } catch (error) {
      toastify(error.message, "error");
    }
  };

  const handleSubmit = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const updatedLease = {
        unitNumber,
        startDate,
        endDate,
        leaseDuration,
        autoRenewal,
        monthlyRent,
        paymentDueDate,
        paymentMethod,
        securityDeposit,
        billingFrequency,
        nextInvoiceDate,
        autoSend,
      };

      const response = await makeRequest2(
        `${update_lease}/${facilityId}/${leaseData._id}`,
        "PUT",
        updatedLease
      );

      if (response.success) {
        toastify("Lease updated successfully", "success");
        if (onUpdate) onUpdate();
        handleClear();
        onHide();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };

  const handleClear = () => {
    setUnitNumber("");
    setStartDate("");
    setEndDate("");
    setLeaseDuration("");
    setAutoRenewal(false);
    setMonthlyRent("");
    setPaymentDueDate("");
    setPaymentMethod("");
    setSecurityDeposit("");
    setBillingFrequency("");
    setNextInvoiceDate("");
    setAutoSend(false);
  };

  return (
    <Dialog
      header="Edit Lease"
      visible={visible}
      style={{ width: "70vw" }}
      onHide={onHide}
    >
      <div className="row">
        {/* Unit Selection */}
        <div className="col-sm-6">
          <label className="form-label">Unit</label>
          <select
            className="form-control mt-2"
            value={unitNumber}
            onChange={(e) => setUnitNumber(e.target.value)}
          >
            <option value="">-- Select Unit --</option>
            {units.map((unit) => (
              <option key={unit._id} value={unit._id}>
                {unit.name}
              </option>
            ))}
          </select>
        </div>

        {/* Lease Terms */}
        <div className="col-sm-6">
          <label className="form-label">Start Date</label>
          <input
            type="date"
            className="form-control"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="col-sm-6">
          <label className="form-label">End Date</label>
          <input
            type="date"
            className="form-control"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <div className="col-sm-6">
          <label className="form-label">Lease Duration</label>
          <InputText
            className="form-control"
            value={leaseDuration}
            onChange={(e) => setLeaseDuration(e.target.value)}
            placeholder="e.g., 12 months"
          />
        </div>
        <div className="col-sm-6">
          <label className="form-label">Auto Renewal</label>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              checked={autoRenewal}
              onChange={(e) => setAutoRenewal(e.target.checked)}
            />
            <label className="form-check-label">
              Enable Auto Renewal
            </label>
          </div>
        </div>

        {/* Financial Terms */}
        <div className="col-sm-6">
          <label className="form-label">Monthly Rent</label>
          <InputText
            type="number"
            className="form-control"
            value={monthlyRent}
            onChange={(e) => setMonthlyRent(e.target.value)}
          />
        </div>
        <div className="col-sm-6">
          <label className="form-label">Payment Due Date</label>
          <input
            type="number"
            className="form-control"
            value={paymentDueDate}
            onChange={(e) => setPaymentDueDate(e.target.value)}
          />
        </div>
        <div className="col-sm-6">
          <label className="form-label">Payment Method</label>
          <select
            className="form-control mt-2"
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            <option value="">-- Select Payment Method --</option>
            <option value="Bank Transfer">Bank Transfer</option>
            <option value="Mobile Money">Mobile Money</option>
            <option value="Cheque">Cheque</option>
            <option value="Cash">Cash</option>
          </select>
        </div>
        <div className="col-sm-6">
          <label className="form-label">Security Deposit</label>
          <InputText
            type="number"
            className="form-control"
            value={securityDeposit}
            onChange={(e) => setSecurityDeposit(e.target.value)}
          />
        </div>

        {/* Billing Cycle */}
        <div className="col-sm-6">
          <label className="form-label">Billing Frequency</label>
          <select
            className="form-control mt-2"
            value={billingFrequency}
            onChange={(e) => setBillingFrequency(e.target.value)}
          >
            <option value="">-- Select Frequency --</option>
            <option value="Monthly">Monthly</option>
            <option value="Quarterly">Quarterly</option>
            <option value="Annually">Annually</option>
          </select>
        </div>
        <div className="col-sm-6">
          <label className="form-label">Next Invoice Date</label>
          <input
            type="date"
            className="form-control"
            value={nextInvoiceDate}
            onChange={(e) => setNextInvoiceDate(e.target.value)}
          />
        </div>
        <div className="col-sm-6">
          <label className="form-label">Auto Send Invoices</label>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              checked={autoSend}
              onChange={(e) => setAutoSend(e.target.checked)}
            />
            <label className="form-check-label">
              Enable Auto Send
            </label>
          </div>
        </div>

        <div className="text-end mt-4">
          <Button
            type="button"
            className="btn btn-outline-secondary"
            onClick={handleClear}
          >
            Clear
          </Button>
          &nbsp;
          <Button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default UpdateLease;