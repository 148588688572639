import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useFacilityServiceRequests } from './hooks/use_facility_service_requests';
import { RequestApprovalMatrix } from './components/request_approval_matrix';
import { UnitManagementForm, WorkOrderForm, CommissionForm } from './components/service_form';

function FacilityServiceRequestsTab({ facilityServiceRequests, refreshRequests, refreshWorkOrders }) {
    const {
        states: {
            selectedFacilityServiceRequest,
            isServiceManagementModalVisible,
            showApprovalMatrix,
            showServiceForm,
            unitManagementForm,
            commissionForm,
            workOrderForm,
            vendors
        },
        setters: {
            setSelectedFacilityServiceRequest,
            setServiceManagementModalVisible,
            setShowApprovalMatrix,
            setShowServiceForm,
            setUnitManagementForm,
            setCommissionForm,
            setWorkOrderForm
        },
        handlers: {
            handleApprove,
            handleReject,
            handleUnitManagementSubmit,
            handleCommissionSubmit,
            handleWorkOrderSubmit,
            handleInputChange,
            handleServiceManagementClick,
            handleVendorSelect
        }
    } = useFacilityServiceRequests(refreshRequests);

    // Filter requests based on status
    const allRequests = facilityServiceRequests || [];
    const pendingRequests = allRequests.filter(req => req.status === 'Pending');
    const inProgressRequests = allRequests.filter(req => req.status === 'In Progress');
    const completedRequests = allRequests.filter(req => req.status === 'Completed');
    const cancelledRequests = allRequests.filter(req => req.status === 'Cancelled');

    const statusTemplate = (rowData) => {
        const statusStyles = {
            'Pending': { color: '#ffc107', backgroundColor: '#fff8e1' },
            'In Progress': { color: '#17a2b8', backgroundColor: '#e0f4f7' },
            'Completed': { color: '#28a745', backgroundColor: '#e9fbe9' },
            'Cancelled': { color: '#dc3545', backgroundColor: '#fde9e9' }
        };

        const style = statusStyles[rowData.status] || {
            color: '#6c757d',
            backgroundColor: '#f1f1f1'
        };

        return (
            <span
                style={{
                    ...style,
                    padding: '0.5em 1em',
                    borderRadius: '12px',
                    fontWeight: 'bold',
                    fontSize: '0.9rem',
                    display: 'inline-block'
                }}
            >
                {rowData.status}
            </span>
        );
    };

    const handleModalClose = () => {
        setServiceManagementModalVisible(false);
        setShowApprovalMatrix(false);
        setShowServiceForm(false);
        setSelectedFacilityServiceRequest(null);
    };

    // Modified work order submit handler to refresh work orders
    const handleWorkOrderSubmitWithRefresh = async () => {
        await handleWorkOrderSubmit();
        if (refreshWorkOrders) {
            await refreshWorkOrders();
        }
        handleModalClose();
    };

    const getFormContent = () => {
        if (!selectedFacilityServiceRequest || !showServiceForm) return null;

        switch (selectedFacilityServiceRequest.serviceName.toLowerCase()) {
            case 'unit management':
                return (
                    <UnitManagementForm
                        form={unitManagementForm}
                        setForm={setUnitManagementForm}
                        onSubmit={handleUnitManagementSubmit}
                        setShowModal={setServiceManagementModalVisible}
                    />
                );
            case 'commission':
                return (
                    <CommissionForm
                        form={commissionForm}
                        setForm={setCommissionForm}
                        onSubmit={handleCommissionSubmit}
                        setShowModal={setServiceManagementModalVisible}
                    />
                );
            default:
                return (
                    <WorkOrderForm
                        form={workOrderForm}
                        setForm={setWorkOrderForm}
                        handleInputChange={handleInputChange}
                        onSubmit={handleWorkOrderSubmitWithRefresh}
                        setShowModal={setServiceManagementModalVisible}
                        vendors={vendors}
                        handleVendorSelect={handleVendorSelect}
                    />
                );
        }
    };

    const facilityServiceRequestActionTemplate = (rowData) => (
        <div className="actions">
            <Button
                icon="ti ti-edit"
                className="p-button-rounded p-button-info mr-2"
                onClick={() => handleServiceManagementClick(rowData)}
                tooltip="Edit"
            />
        </div>
    );

    const renderDataTable = (data) => (
        <div className="card">
            <div className="card-body">
                <div className="table-responsive">
                    <DataTable
                        value={data}
                        emptyMessage="No facility service requests found"
                        paginator
                        rows={5}
                        stripedRows
                        tableStyle={{ minWidth: '50rem' }}
                    >
                        <Column field="CustomerInfo.fullName" header="Customer Name" />
                        <Column field="CustomerInfo.phoneNumber" header="Phone Number" />
                        <Column field="serviceName" header="Service" />
                        <Column field="status" header="Status" body={statusTemplate} />
                        <Column
                            field="createdAt"
                            header="Created At"
                            body={(rowData) => rowData.createdAt ?
                                new Date(rowData.createdAt).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                }) : 'N/A'
                            }
                        />
                        <Column body={facilityServiceRequestActionTemplate} header="Actions" style={{ width: '100px' }} />
                    </DataTable>
                </div>
            </div>
        </div>
    );

    return (
        <div className="tab-pane" id="facility-service-requests">
            <div className="card">
                <div className="card-body">
                    <ul className="nav nav-tabs invoice-tab border-bottom mb-3" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link active"
                                id="analytics-tab-1"
                                data-bs-toggle="tab"
                                data-bs-target="#analytics-tab-1-pane"
                                type="button"
                                role="tab"
                                aria-controls="analytics-tab-1-pane"
                                aria-selected="true"
                            >
                                <span className="d-flex align-items-center gap-2">
                                    All <span className="avtar rounded-circle bg-light-primary">{allRequests.length}</span>
                                </span>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="analytics-tab-2"
                                data-bs-toggle="tab"
                                data-bs-target="#analytics-tab-2-pane"
                                type="button"
                                role="tab"
                                aria-controls="analytics-tab-2-pane"
                                aria-selected="false"
                            >
                                <span className="d-flex align-items-center gap-2">
                                    Pending <span className="avtar rounded-circle bg-light-warning">{pendingRequests.length}</span>
                                </span>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="analytics-tab-3"
                                data-bs-toggle="tab"
                                data-bs-target="#analytics-tab-3-pane"
                                type="button"
                                role="tab"
                                aria-controls="analytics-tab-3-pane"
                                aria-selected="false"
                            >
                                <span className="d-flex align-items-center gap-2">
                                    In Progress <span className="avtar rounded-circle bg-light-info">{inProgressRequests.length}</span>
                                </span>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="analytics-tab-4"
                                data-bs-toggle="tab"
                                data-bs-target="#analytics-tab-4-pane"
                                type="button"
                                role="tab"
                                aria-controls="analytics-tab-4-pane"
                                aria-selected="false"
                            >
                                <span className="d-flex align-items-center gap-2">
                                    Completed <span className="avtar rounded-circle bg-light-success">{completedRequests.length}</span>
                                </span>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="analytics-tab-5"
                                data-bs-toggle="tab"
                                data-bs-target="#analytics-tab-5-pane"
                                type="button"
                                role="tab"
                                aria-controls="analytics-tab-5-pane"
                                aria-selected="false"
                            >
                                <span className="d-flex align-items-center gap-2">
                                    Cancelled <span className="avtar rounded-circle bg-light-danger">{cancelledRequests.length}</span>
                                </span>
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div
                            className="tab-pane fade show active"
                            id="analytics-tab-1-pane"
                            role="tabpanel"
                            aria-labelledby="analytics-tab-1"
                            tabIndex="0"
                        >
                            {renderDataTable(allRequests)}
                        </div>
                        <div
                            className="tab-pane fade"
                            id="analytics-tab-2-pane"
                            role="tabpanel"
                            aria-labelledby="analytics-tab-2"
                            tabIndex="0"
                        >
                            {renderDataTable(pendingRequests)}
                        </div>
                        <div
                            className="tab-pane fade"
                            id="analytics-tab-3-pane"
                            role="tabpanel"
                            aria-labelledby="analytics-tab-3"
                            tabIndex="0"
                        >
                            {renderDataTable(inProgressRequests)}
                        </div>
                        <div
                            className="tab-pane fade"
                            id="analytics-tab-4-pane"
                            role="tabpanel"
                            aria-labelledby="analytics-tab-4"
                            tabIndex="0"
                        >
                            {renderDataTable(completedRequests)}
                        </div>
                        <div
                            className="tab-pane fade"
                            id="analytics-tab-5-pane"
                            role="tabpanel"
                            aria-labelledby="analytics-tab-5"
                            tabIndex="0"
                        >
                            {renderDataTable(cancelledRequests)}
                        </div>
                    </div>
                </div>
            </div>

            {isServiceManagementModalVisible && (
                <Dialog
                    header={showApprovalMatrix ? "Request Approval" : `${selectedFacilityServiceRequest?.serviceName || ''} Request`}
                    visible={isServiceManagementModalVisible}
                    onHide={handleModalClose}
                    style={{ width: '40vw', maxWidth: '500px' }}
                    className="service-details-dialog"
                    modal
                    closeOnEscape
                    closable
                >
                    {showApprovalMatrix ? (
                        <RequestApprovalMatrix
                            selectedFacilityServiceRequest={selectedFacilityServiceRequest}
                            handleReject={handleReject}
                            handleApprove={handleApprove}
                        />
                    ) : (
                        getFormContent()
                    )}
                </Dialog>
            )}
        </div>
    );
}

export default FacilityServiceRequestsTab;