import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, InputText } from "primereact";
import Layout from "../../component/layout";
import { toastify } from "../../../../utils/toast";
import { getItem } from "../../../../utils/localStorage";
import { makeRequest2 } from "../../../../utils/makeRequest";
import {
  getLevies,
  getCustomersURL,
  getUnitsForFacility,
  addContract,
} from "../../../../utils/urls";

const AddContract = () => {
  const navigate = useNavigate();

  const [contractName, setContractName] = useState("");
  const [levy, setLevy] = useState("");
  const [customer, setCustomer] = useState("");
  const [unit, setUnit] = useState("");
  const [amount, setAmount] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [paymentFrequency, setPaymentFrequency] = useState("");
  const [status, setStatus] = useState("Active");

  const [levies, setLevies] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [amounts, setAmounts] = useState([]);
  const [units, setUnits] = useState([]);

  // Fetch available levies
  const fetchLevies = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        `${getLevies}/${facilityId}`,
        "GET",
        {}
      );
      if (response.success) {
        setLevies(
          response.data.map((levy) => ({
            ...levy,
            levyType: levy.levyType?.name || "N/A",
          }))
        );
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };

  // Fetch available customers for the facility
  const fetchCustomersForFacility = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        `${getCustomersURL}/${facilityId}`,
        "GET",
        {}
      );
      if (response.success) {
        setCustomers(response.data);
      } else {
        throw new Error("Failed to fetch customers.");
      }
    } catch (error) {
      toastify(error.message, "error");
    }
  };

  // Fetch amount for the levy
  const fetchAmountForLevy = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        `${getLevies}/${facilityId}`,
        "GET",
        {}
      );
      if (response.success) {
        setLevies(
          response.data.map((levy) => ({
            ...levy,
            levyType: levy.levyType?.amount || "N/A",
          }))
        );
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };

  // Fetch available units for the facility
  const fetchUnitsForFacility = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        `${getUnitsForFacility}/${facilityId}`,
        "GET",
        {}
      );
      if (response.success) {
        setUnits(response.data);
      } else {
        throw new Error("Failed to fetch units.");
      }
    } catch (error) {
      toastify(error.message, "error");
    }
  };

  const handleContractSubmit = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");

      // Validate the form data before sending
      if (!contractName || contractName.trim() === "") {
        toastify("Contract name is required.", "error");
        return;
      }
      if (!levy) {
        toastify("Levy is required.", "error");
        return;
      }
      if (!customer) {
        toastify("Customer is required.", "error");
        return;
      }
      if (!unit) {
        toastify("Unit is required.", "error");
        return;
      }
      if (!amount || isNaN(amount)) {
        toastify("Amount must be a valid number.", "error");
        return;
      }
      if (!startDate) {
        toastify("Start date is required.", "error");
        return;
      }
      if (!endDate) {
        toastify("End date is required.", "error");
        return;
      }
      if (!paymentFrequency) {
        toastify("Payment frequency is required.", "error");
        return;
      }
      if (!status) {
        toastify("Status is required.", "error");
        return;
      }

      const contractData = {
        contractName,
        levyId: levy,
        customerId: customer,
        unitId: unit,
        amount: parseFloat(amount), // Ensure it's a number
        startDate,
        endDate,
        paymentFrequency,
        status,
        facilityId,
      };

      // Check if dates are valid
      if (new Date(startDate) > new Date(endDate)) {
        toastify("Start date cannot be later than end date.", "error");
        return;
      }

      // Send the data to the backend
      const response = await makeRequest2(
        `${addContract}/${facilityId}`,
        "POST",
        contractData
      );

      if (response.success) {
        toastify("Contract added successfully", "success");
        handleClear();
        navigate("/facility/levy_management/contracts"); // Navigate back to the contracts list
      } else {
        // Log the error response from the server
        console.error("Error response:", response.error);
        toastify(response.error || "Failed to add contract.", "error");
      }
    } catch (err) {
      console.error("Error adding contract:", err); // Log the error for debugging
      toastify(
        err.message ||
          "An unexpected error occurred while adding the contract.",
        "error"
      );
    }
  };

  // Clear form
  const handleClear = () => {
    setContractName("");
    setLevy("");
    setCustomer("");
    setUnit("");
    setAmount("");
    setStartDate("");
    setEndDate("");
    setPaymentFrequency("");
    setStatus("");
  };

  // Load data on component mount
  useEffect(() => {
    fetchLevies();
    fetchCustomersForFacility();
    fetchAmountForLevy();
    fetchUnitsForFacility();
  }, []);

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/app/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/facility/levy_management/levies/levy_list">
                    Levy Management
                  </Link>
                </li>
                <li className="breadcrumb-item">Add New Contract</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <Link to="#" onClick={() => navigate(-1)}>
                <i className="ti ti-arrow-narrow-left"></i> Back
              </Link>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h5>Add New Contract</h5>
            </div>
            <div className="card-body">
              <div className="container">
                <div className="row">
                  <div className="col-sm-6">
                    <label className="form-label">Contract Name</label>
                    <InputText
                      className="form-control"
                      value={contractName}
                      onChange={(e) => setContractName(e.target.value)}
                    />
                  </div>

                  <div className="col-sm-6">
                    <label className="form-label">Levy</label>
                    <select
                      className="form-control mt-2"
                      value={levy}
                      onChange={(e) => {
                        const selectedLevyId = e.target.value;
                        setLevy(selectedLevyId);
                        const selectedLevy = levies.find(
                          (l) => l._id === selectedLevyId
                        );
                        setAmount(selectedLevy ? selectedLevy.amount : "");
                      }}
                    >
                      <option value="">-- Select --</option>
                      {levies.map((levy) => (
                        <option key={levy._id} value={levy._id}>
                          {levy.levyName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-6">
                    <label className="form-label">Customer</label>
                    <select
                      className="form-control mt-2"
                      value={customer}
                      onChange={(e) => setCustomer(e.target.value)}
                    >
                      <option value="">-- Select --</option>
                      {customers.map((customer) => (
                        <option key={customer._id} value={customer._id}>
                          {`${customer.firstName} ${customer.lastName}`}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-6">
                    <label className="form-label">Unit</label>
                    <select
                      className="form-control mt-2"
                      value={unit}
                      onChange={(e) => setUnit(e.target.value)}
                    >
                      <option value="">-- Select --</option>
                      {units.map((unit) => (
                        <option key={unit._id} value={unit._id}>
                          {unit.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-6">
                    <label className="form-label">Amount</label>
                    <input
                      type="text"
                      className="form-control"
                      value={amount}
                      readOnly
                    />
                  </div>
                  <div className="col-sm-6">
                    <label className="form-label">Start Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-6">
                    <label className="form-label">End Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-6">
                    <label className="form-label">Payment Frequency</label>
                    <select
                      className="form-control mt-2"
                      value={paymentFrequency}
                      onChange={(e) => setPaymentFrequency(e.target.value)}
                    >
                      <option value="">-- Select --</option>
                      <option value="Weekly">Weekly</option>
                      <option value="Monthly">Monthly</option>
                      <option value="Quarterly">Quarterly</option>
                      <option value="Semi-Annually">Semi-Annually</option>
                      <option value="Annually">Annually</option>
                    </select>
                  </div>

                  <div className="col-sm-6">
                    <label className="form-label">Status</label>
                    <div className="mt-2 d-flex align-items-center">
                      <i
                        className={`ti ${
                          status === "Inactive"
                            ? "ti-toggle-left text-danger"
                            : "ti-toggle-right text-primary"
                        }`}
                        style={{ fontSize: "34px", cursor: "pointer" }}
                        onClick={() =>
                          setStatus(
                            status === "Inactive" ? "Active" : "Inactive"
                          )
                        }
                      />
                      <span className="ms-2">{status}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-end mt-4">
                <Button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={handleClear}
                >
                  Clear
                </Button>
                &nbsp;
                <Button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleContractSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddContract;
