import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { toastify } from '../../../../../utils/toast';
import { getItem } from '../../../../../utils/localStorage';
import { makeRequest2 } from '../../../../../utils/makeRequest';
import { delete_handover } from '../../../../../utils/urls';

const DeleteHandover = ({ handoverId, onDelete }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      const facilityId = await getItem("selectedFacilityId");
      
      if (!facilityId) {
        throw new Error("Facility ID not found");
      }

      const response = await makeRequest2(
        `${delete_handover}/${facilityId}/${handoverId}`,
        "DELETE"
      );

      if (response.success) {
        toastify("Handover deleted successfully", "success");
        setVisible(false);
        if (onDelete) onDelete();
      } else {
        throw new Error(response.error || "Failed to delete handover");
      }
    } catch (error) {
      toastify(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const confirmDelete = () => {
    setVisible(true);
  };

  const hideDialog = () => {
    setVisible(false);
  };

  const renderFooter = () => {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-text"
          onClick={hideDialog}
          disabled={loading}
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-danger"
          onClick={handleDelete}
          loading={loading}
        />
      </div>
    );
  };

  return (
    <>
      <Button
        icon="pi pi-trash"
        className="p-button-rounded p-button-danger p-button-text"
        onClick={confirmDelete}
        tooltip="Delete"
      />

      <Dialog
        visible={visible}
        style={{ width: '450px' }}
        header="Confirm Delete"
        modal
        footer={renderFooter}
        onHide={hideDialog}
      >
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          <span>Are you sure you want to delete this handover?</span>
        </div>
      </Dialog>
    </>
  );
};

export default DeleteHandover;