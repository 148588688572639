// LeaseManagement.jsx
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../component/layout";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { toastify } from "../../../../utils/toast";
import { getItem } from "../../../../utils/localStorage";
import { makeRequest2 } from "../../../../utils/makeRequest";
import { get_leases, getUnitsForFacility, update_lease_status } from "../../../../utils/urls";
import { jsPDF } from "jspdf";
import DeleteLease from "./delete_lease";
import UpdateLease from "./edit_lease";
import TerminateLease from "./terminate_lease";
import PopulatedLeaseTemplate from "./lease_templates/populated_lease_template"

const LeaseManagement = () => {
  const navigate = useNavigate();
  const [leases, setLeases] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [filteredLeases, setFilteredLeases] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [units, setUnits] = useState([]);
  const [selectedLeaseId, setSelectedLeaseId] = useState(null);
  const [isConfirmDialogVisible, setConfirmDialogVisible] = useState(false);
  const [editDialogVisible, setEditDialogVisible] = useState(false);
  const [selectedLeaseData, setSelectedLeaseData] = useState(null);
  const [isTemplateVisible, setIsTemplateVisible] = useState(false);
const [selectedViewLeaseId, setSelectedViewLeaseId] = useState(null);


  const fetchLeases = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      if (!facilityId) {
        toastify("No facility selected. Please select a facility first.", "error");
        return;
      }

      const response = await makeRequest2(`${get_leases}/${facilityId}`, "GET");
      if (response?.success && Array.isArray(response.data.leaseAgreements)) {
        const processedLeases = response.data.leaseAgreements.map((lease) => ({
          ...lease,
          landlordName: lease.landlordInfo?.fullName || "N/A",
          tenantName: lease.tenantInfo?.fullName || "N/A",
          unitName: lease.unitInfo?.unitName || "N/A",
          leaseTemplateName: lease.leaseTemplateName || "N/A",
          leaseStatus: calculateLeaseStatus(lease)
        }));

        setLeases(processedLeases);
        setFilteredLeases(processedLeases);
      } else {
        toastify(response?.data?.message || "Failed to fetch leases.", "error");
        setLeases([]);
        setFilteredLeases([]);
      }
    } catch (error) {
      console.error("Error fetching leases:", error);
      toastify(error.message || "Error fetching leases", "error");
      setLeases([]);
      setFilteredLeases([]);
    }
  };

  const fetchUnitsForFacility = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        `${getUnitsForFacility}/${facilityId}`,
        "GET"
      );
      if (response.success) {
        setUnits(response.data);
      } else {
        throw new Error("Failed to fetch units.");
      }
    } catch (error) {
      toastify(error.message, "error");
    }
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    filterLeases(value, selectedStatus, selectedUnit);
  };

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    filterLeases(searchTerm, status, selectedUnit);
  };

  const handleUnitChange = (unit) => {
    setSelectedUnit(unit);
    filterLeases(searchTerm, selectedStatus, unit);
  };

  const filterLeases = (search, status, unit) => {
    let filtered = [...leases];

    if (search) {
      filtered = filtered.filter(lease =>
        lease.tenantName.toLowerCase().includes(search.toLowerCase()) ||
        lease.landlordName.toLowerCase().includes(search.toLowerCase()) ||
        lease.unitName.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (status) {
      filtered = filtered.filter(lease => lease.leaseStatus === status);
    }

    if (unit) {
      filtered = filtered.filter(lease => lease.unitName === unit);
    }

    setFilteredLeases(filtered);
  };

  const calculateLeaseStatus = (lease) => {
    // Return the persisted status if it's Terminated or Pending
    if (lease.status === 'Terminated' || lease.status === 'Pending') {
      return lease.status;
    }
    
    const currentDate = new Date();
    const endDate = new Date(lease.leaseTerms.endDate);
    
    // If the lease is expired, update it in the backend
    if (endDate < currentDate && lease.status !== 'Expired') {
      updateLeaseStatus(lease._id, 'Expired');
      return 'Expired';
    }
    
    // If it's not expired and not terminated/pending, it's active
    return lease.status === 'Expired' ? 'Expired' : 'Active';
  };
  
  // Add a new function to update lease status in the backend
  const updateLeaseStatus = async (leaseId, newStatus) => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      if (!facilityId) {
        throw new Error("Facility ID not found");
      }
  
      const response = await makeRequest2(
        `${update_lease_status}/${facilityId}/${leaseId}`,
        "PUT",
        { status: newStatus }
      );
  
      if (!response.success) {
        throw new Error(response.error || "Failed to update lease status");
      }
    } catch (error) {
      console.error("Error updating lease status:", error);
      toastify(error.message, "error");
    }
  };

  const statusTemplate = (rowData) => {
    const statusStyles = {
      Active: { color: '#28a745', backgroundColor: '#e9fbe9' },
      Expired: { color: '#dc3545', backgroundColor: '#fde9e9' },
      Terminated: { color: '#6c757d', backgroundColor: '#f1f1f1' }
    };

    const style = statusStyles[rowData.leaseStatus] || {
      color: '#6c757d',
      backgroundColor: '#f1f1f1',
    };

    return (
      <span
        style={{
          ...style,
          padding: '0.5em 1em',
          borderRadius: '12px',
          fontWeight: 'bold',
          fontSize: '0.9rem',
          display: 'inline-block',
        }}
      >
        {rowData.leaseStatus}
      </span>
    );
  };

  const downloadLease = async (lease) => {
    const doc = new jsPDF();
    doc.setFontSize(18);
    doc.setTextColor(0, 51, 102);
    doc.text(`Lease Agreement: ${lease.propertyAddress}`, 20, 20);

    doc.setFontSize(12);
    doc.text("Lease Details", 20, 30);

    const content = [
      { label: "Property Address:", value: lease.propertyAddress },
      { label: "Start Date:", value: lease.leaseTerms?.startDate ? new Date(lease.leaseTerms.startDate).toLocaleDateString() : "N/A" },
      { label: "End Date:", value: lease.leaseTerms?.endDate ? new Date(lease.leaseTerms.endDate).toLocaleDateString() : "N/A" },
      { label: "Monthly Rent:", value: lease.financialTerms?.monthlyRent || "N/A" },
      { label: "Status:", value: lease.status }
    ];

    let yPosition = 40;
    content.forEach(item => {
      doc.text(`${item.label} ${item.value}`, 20, yPosition);
      yPosition += 10;
    });

    doc.save(`${lease.propertyAddress}_lease.pdf`);
  };

  const viewTemplate = (rowData) => {
    return (
      <Button
        icon="ti ti-eye"
        className="p-button-text p-button-rounded p-button-info"
        onClick={() => {
          setSelectedViewLeaseId(rowData._id);
          setIsTemplateVisible(true);
        }}
        tooltip="View Lease"
      />
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="ti ti-edit"
          className="p-button-rounded p-button-info mr-2"
          onClick={() => {
            setSelectedLeaseData(rowData);
            setEditDialogVisible(true);
          }}
          tooltip="Edit"
        />
        <DeleteLease
          leaseId={rowData._id}
          onDelete={fetchLeases}
          isConfirmDialogVisible={isConfirmDialogVisible && selectedLeaseId === rowData._id}
          setConfirmDialogVisible={(value) => {
            setSelectedLeaseId(rowData._id);
            setConfirmDialogVisible(value);
          }}
        />
        <TerminateLease
          leaseId={rowData._id}
          initialStatus={rowData.status}
          fetchLeases={fetchLeases}
        />
      </div>
    );
  };

  useEffect(() => {
    fetchLeases();
    fetchUnitsForFacility();
  }, []);

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/app/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="">Lease Management</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="">Leases</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>Leases</h5>
            </div>
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-md-3">
                  <input
                    className="form-control"
                    placeholder="Search here"
                    value={searchTerm}
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                </div>
                <div className="col-md-3">
                  <select
                    className="form-control"
                    value={selectedStatus}
                    onChange={(e) => handleStatusChange(e.target.value)}
                  >
                    <option value="">All Statuses</option>
                    <option value="Active">Active</option>
                    <option value="Expired">Expired</option>
                    <option value="Terminated">Terminated</option>
                  </select>
                </div>
                <div className="col-md-3">
                  <select
                    className="form-control"
                    value={selectedUnit}
                    onChange={(e) => handleUnitChange(e.target.value)}
                  >
                    <option value="">All Units</option>
                    {units.map((unit) => (
                      <option key={unit._id} value={unit.name}>
                        {unit.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3">
                  <button
                    className="btn btn-primary float-end"
                    onClick={() => navigate("/facility/lease_management/create_lease")}
                  >
                    Add Lease
                  </button>
                </div>
              </div>

              <DataTable 
                value={filteredLeases}
                paginator 
                rows={10}
                emptyMessage="No leases found"
                sortMode="multiple"
                removableSort
                stripedRows
                tableStyle={{ minWidth: '50rem' }}
              >
                <Column field="#" header="#" body={viewTemplate} />
                <Column field="landlordName" header="Landlord" sortable />
                <Column field="tenantName" header="Tenant" sortable />
                <Column field="unitName" header="Unit" sortable />
                <Column 
                  field="leaseTerms.startDate" 
                  header="Start Date" 
                  sortable
                  body={(rowData) => new Date(rowData.leaseTerms.startDate).toLocaleDateString()}
                />
                <Column 
                  field="leaseTerms.endDate" 
                  header="End Date" 
                  sortable
                  body={(rowData) => new Date(rowData.leaseTerms.endDate).toLocaleDateString()}
                />
                <Column 
                  field="financialTerms.monthlyRent" 
                  header="Monthly Rent" 
                  sortable
                />
                <Column field="leaseStatus" header="Status" body={statusTemplate} sortable />
                <Column header="Actions" body={actionBodyTemplate} />
              </DataTable>
            </div>
          </div>
        </div>
      </div>

      <UpdateLease
        visible={editDialogVisible}
        onHide={() => setEditDialogVisible(false)}
        leaseData={selectedLeaseData}
        onUpdate={() => {
          fetchLeases();
          setEditDialogVisible(false);
        }}
      />
      <PopulatedLeaseTemplate
  leaseId={selectedViewLeaseId}
  visible={isTemplateVisible}
  onHide={() => {
    setIsTemplateVisible(false);
    setSelectedViewLeaseId(null);
  }}
/>
    </Layout>
  );
};

export default LeaseManagement;