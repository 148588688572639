import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../component/layout";
import HandoverList from "./components/handover_list";

const HandoverManagement = () => {
  const navigate = useNavigate();
  const [selectedHandover, setSelectedHandover] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedType, setSelectedType] = useState("");

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/app/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/facility/handover_management/handovers">
                    Handover Management
                  </Link>
                </li>
                <li className="breadcrumb-item">Handovers</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>Handovers</h5>
            </div>
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-md-3">
                  <select
                    className="form-control"
                    value={selectedType}
                    onChange={(e) => setSelectedType(e.target.value)}
                  >
                    <option value="">All Types</option>
                    <option value="Move-In">Move-In</option>
                    <option value="Move-Out">Move-Out</option>
                  </select>
                </div>
                <div className="col-md-3">
                  <select
                    className="form-control"
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                  >
                    <option value="">All Statuses</option>
                    <option value="Pending">Pending</option>
                    <option value="In Progress">In Progress</option>
                    <option value="Completed">Completed</option>
                    <option value="Cancelled">Cancelled</option>
                  </select>
                </div>
                <div className="col-md-6 text-end">
                  <button
                    className="btn btn-primary"
                    onClick={() => navigate("/facility/handover_management/handovers/create")}
                  >
                    Create Handover
                  </button>
                </div>
              </div>

              <HandoverList
                selectedType={selectedType}
                selectedStatus={selectedStatus}
                onHandoverSelect={setSelectedHandover}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HandoverManagement;