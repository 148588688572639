import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../component/layout';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { getItem } from '../../../utils/localStorage';
import { getFacilityUnits } from '../../../utils/urls';
import { makeRequest2 } from '../../../utils/makeRequest';
import { toastify } from '../../../utils/toast';

function UnitListing() {
  const navigate = useNavigate();
  const [units, setUnits] = useState([]);
  const [filteredUnits, setFilteredUnits] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchUnits = async () => {
    try {
      const facilityId = await getItem('selectedFacilityId');
      if (!facilityId) {
        toastify('No facility selected. Please select a facility first.', 'error');
        return;
      }

      const response = await makeRequest2(`${getFacilityUnits}/${facilityId}`, 'GET', {});

      if (response.success) {
        const { occupiedUnits, notOccupiedUnits } = response.data;
        
        // Format all units with the correct status mapping
        const allUnits = [
          ...occupiedUnits.map(unit => ({ 
            ...unit,
            occupancyStatus: unit.status === 'Active' ? 'Occupied' : 'Available'
          })),
          ...notOccupiedUnits.map(unit => ({ 
            ...unit,
            occupancyStatus: 'Available'
          }))
        ];

        setUnits(allUnits);
        setFilteredUnits(allUnits);
      } else {
        throw new Error('Failed to fetch units');
      }
    } catch (err) {
      console.error(err.message);
      toastify(err.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUnits();
  }, []);

  const handleStatusFilter = (status) => {
    setSelectedStatus(status);
    if (!status) {
      setFilteredUnits(units);
    } else {
      const filtered = units.filter(unit => unit.occupancyStatus === status);
      setFilteredUnits(filtered);
    }
  };

  const statusTemplate = (rowData) => {
    const statusStyles = {
      Occupied: { color: '#28a745', backgroundColor: '#e9fbe9' },
      Available: { color: '#007bff', backgroundColor: '#e9f5ff' }
    };

    const style = statusStyles[rowData.occupancyStatus] || {
      color: '#6c757d',
      backgroundColor: '#f1f1f1'
    };

    return (
      <span
        style={{
          ...style,
          padding: '0.5em 1em',
          borderRadius: '12px',
          fontWeight: 'bold',
          fontSize: '0.9rem',
          display: 'inline-block'
        }}
      >
        {rowData.occupancyStatus}
      </span>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        {rowData.occupancyStatus === 'Available' && (
          <Button
            label="Lease"
            className="p-button-primary p-button-sm"
            onClick={() => navigate('/facility/lease_management/create_lease', { 
              state: { selectedUnit: rowData }
            })}
          />
        )}
      </div>
    );
  };

  return (
    <Layout>
      <div className="card">
        <div className="card-header">
          <h5>Units</h5>
        </div>
        <div className="card-body">
          <div className="row mb-3">
            <div className="col-md-3">
              <select
                className="form-control"
                value={selectedStatus}
                onChange={(e) => handleStatusFilter(e.target.value)}
              >
                <option value="">All Units</option>
                <option value="Occupied">Occupied</option>
                <option value="Available">Available</option>
              </select>
            </div>
          </div>

          <DataTable
            value={filteredUnits}
            paginator
            rows={10}
            loading={loading}
            emptyMessage="No units found"
            sortMode="multiple"
            removableSort
            stripedRows
            tableStyle={{ minWidth: '50rem' }}
          >
            <Column field="name" header="Unit Name" sortable />
            <Column field="unitType" header="Type" sortable />
            <Column field="floorUnitNo" header="Floor" sortable />
            <Column 
              field="grossArea" 
              header="Size (sq ft)" 
              sortable
              body={(rowData) => rowData.grossArea.toLocaleString()}
            />
            <Column field="occupancyStatus" header="Status" body={statusTemplate} sortable />
            <Column header="Actions" body={actionBodyTemplate} />
          </DataTable>
        </div>
      </div>
    </Layout>
  );
}

export default UnitListing;