import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

function WorkOrdersTab({ workOrders = [], onDeleteWorkOrder }) {
    const safeWorkOrders = Array.isArray(workOrders) ? workOrders : [];

    const workOrderActionTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="ti ti-trash"
                    className="p-button-rounded p-button-danger mr-2"
                    onClick={() => onDeleteWorkOrder(rowData._id.$oid)}
                    tooltip="Delete"
                />
            </div>
        );
    };

    const formatCurrency = (value) => {
        if (value == null) return 'KSH 0.00';
        const formatted = value.toLocaleString('en-KE', {
            style: 'currency',
            currency: 'KES',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
        return formatted.replace('KES', 'KSH');
    };

    const statusTemplate = (rowData) => {
        const statusStyles = {
            'Pending': { color: '#ffc107', backgroundColor: '#fff8e1' },
            'In Progress': { color: '#17a2b8', backgroundColor: '#e0f4f7' },
            'Completed': { color: '#28a745', backgroundColor: '#e9fbe9' },
            'Cancelled': { color: '#dc3545', backgroundColor: '#fde9e9' }
        };

        const style = statusStyles[rowData.status] || {
            color: '#6c757d',
            backgroundColor: '#f1f1f1'
        };

        return (
            <span
                style={{
                    ...style,
                    padding: '0.5em 1em',
                    borderRadius: '12px',
                    fontWeight: 'bold',
                    fontSize: '0.9rem',
                    display: 'inline-block'
                }}
            >
                {rowData.status}
            </span>
        );
    };

    return (
        <div className="tab-pane show active" id="workorders">
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="m-0">Work Orders</h5>
                </div>
                <div className="card-body">
                    <div className="card">
                        <div className="card-body">
                            <DataTable
                                value={safeWorkOrders}
                                emptyMessage="No work orders found"
                                paginator
                                rows={5}
                                stripedRows
                            >
                                <Column field="orderNumber" header="Order Number" />
                                <Column field="description" header="Description" />
                                <Column
                                    field="status"
                                    header="Status"
                                    body={statusTemplate}
                                />
                                <Column field="type" header="Type" />
                                <Column
                                    field="pricing"
                                    header="Price"
                                    body={(rowData) => formatCurrency(rowData.pricing)}
                                />
                                <Column
                                    body={workOrderActionTemplate}
                                    header="Actions"
                                    style={{ width: '100px' }}
                                />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WorkOrdersTab;