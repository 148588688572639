import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from 'react-router-dom';
import { Button } from "primereact/button";
import "primereact/resources/themes/lara-light-blue/theme.css";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { toastify } from "../../../../utils/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Card } from "primereact/card";

import Layout from "../../component/layout";
import { makeRequest2 } from "../../../../utils/makeRequest";
import { useFacilityStore } from "../../../../app/z-store/store";

const WorkOrderManagement = () => {
  const [workOrders, setWorkOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);
  const selectedFacilityId = useFacilityStore((state) => state.facilityId);
  const [formData, setFormData] = useState({
    status: "pending",
  });
  const toast = useRef(null);

  useEffect(() => {
    if (selectedFacilityId) {
      fetchWorkOrders(selectedFacilityId);
    }
  }, [selectedFacilityId]);

  const fetchWorkOrders = async (currentFacilityId) => {
    try {
      setLoading(true);
      const idToUse = currentFacilityId || selectedFacilityId;

      if (!idToUse) {
        throw new Error("No facility ID available");
      }

      const response = await makeRequest2(
        `/api/work_order/${idToUse}`,
        "GET",
        null
      );

      if (response.success) {
        const workOrderData = Array.isArray(response.data)
          ? response.data
          : Array.isArray(response.data.workOrders)
            ? response.data.workOrders
            : [];
        setWorkOrders(workOrderData);
        setError(null);
      } else {
         toastify("Failed to fetch work orders", 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const filteredWorkOrders = Array.isArray(workOrders)
    ? workOrders.filter((order) =>
      Object.values(order).some((value) =>
        value?.toString().toLowerCase().includes(searchKeyword.toLowerCase())
      )
    )
    : [];

  const truncateDescription = (description, maxLength = 50) => {
    return description.length > maxLength 
      ? `${description.substring(0, maxLength)}...` 
      : description;
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleModalOpen = (workOrder = null) => {
    setSelectedWorkOrder(workOrder);
    setFormData({
      status: workOrder ? workOrder.status : "pending",
    });
    setShowModal(true);
  };

  const handleDetailsModalOpen = (workOrder) => {
    setSelectedWorkOrder(workOrder);
    setShowDetailsModal(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const response = await makeRequest2(
        `/api/work_order/${selectedFacilityId}/${selectedWorkOrder._id}`,
        "PUT",
        { status: formData.status }
      );

      if (response.success) {
        toastify("Work order status updated successfully", 'success');
        fetchWorkOrders();
        setShowModal(false);
      } else {
        toastify(response.error, 'error');
      }
    } catch (err) {
      toastify("An error occurred", 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (orderId) => {
    confirmDialog({
      message: "Are you sure you want to delete this work order?",
      header: "Delete Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept: async () => {
        try {
          const response = await makeRequest2(
            `/api/work_order/${selectedFacilityId}/${orderId}`,
            "DELETE",
            null
          );

          if (response.success) {
            toastify("Work order deleted successfully", 'success');
            fetchWorkOrders();
          } else {
            toastify("Failed to delete work order", 'error');
          }
        } catch (err) {
          toastify("Failed to delete work order", 'error');
        }
      },
    });
  };

  const actionTemplate = (rowData) => {
    return (
      <div>
        <Button
          icon="ti ti-eye"
          className="p-button-rounded p-button-info mr-2"
          onClick={() => handleDetailsModalOpen(rowData)}
          tooltip="View Details"
        />
        <Button
          icon="ti ti-edit"
          className="p-button-rounded p-button-warning mr-2"
          onClick={() => handleModalOpen(rowData)}
          tooltip="Edit Status"
        />
        <Button
          icon="ti ti-trash"
          className="p-button-rounded p-button-danger mr-2"
          onClick={() => handleDelete(rowData._id)}
          tooltip="Delete"
        />
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <input
          type="text"
          className="form-control w-25"
          placeholder="Search work orders..."
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
        />
      </div>
    );
  };

  const statusTemplate = (rowData) => {
    const statusColors = {
      'pending': 'bg-warning',
      'in progress': 'bg-info',
      'completed': 'bg-success',
      'suspended': 'bg-secondary',
      'cancelled': 'bg-danger'
    };

    return (
      <span className={`badge ${statusColors[rowData.status]}`}>
        {rowData.status}
      </span>
    );
  };

 const renderWorkOrderAnalysis = () => {
  const statusCount = workOrders.reduce((acc, order) => {
    acc[order.status] = (acc[order.status] || 0) + 1;
    return acc;
  }, {});

  const analysisData = Object.entries(statusCount).map(([status, count]) => ({
    status,
    count
  }));

  return (
    <div className="row">
      <div className="col-md-12">
        <Card title="Work Order Status Distribution">
          <div style={{ width: "100%", height: "400px" }}>
            <ResponsiveContainer>
              <BarChart data={analysisData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="status" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#8884d8" name="Number of Orders" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Card>
      </div>
    </div>
  );
};

return (
  <Layout>
    <div className="page-header">
      <div className="page-block">
        <div className="row align-items-center">
          <div className="col-md-12">
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/facility/"}>Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={""}>Work Order Management</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <Toast ref={toast} />
    <ConfirmDialog />

    <div className="row">
      <div className="col-sm-12">
        <div className="card">
          <div className="card-body py-0">
            <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="work-orders-tab"
                  data-bs-toggle="tab"
                  href="#work-orders"
                  role="tab"
                  aria-selected="true"
                >
                  <i className="ti ti-clipboard me-2"></i>
                  Work Orders
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link"
                  id="work-order-analysis-tab"
                  data-bs-toggle="tab"
                  href="#work-order-analysis"
                  role="tab"
                  aria-selected="false"
                >
                  <i className="ti ti-chart-bar me-2"></i>
                  Work Order Analysis
                </a>
              </li>
            </ul>
          </div>

          <div className="tab-content">
            <div
              className="tab-pane show active"
              id="work-orders"
              role="tabpanel"
              aria-labelledby="work-orders-tab"
            >
              <div className="card">
                <div className="card-body">
                  <div className="dt-responsive table-responsive">
                    <DataTable
                      value={filteredWorkOrders}
                      emptyMessage="No work orders found."
                      sortMode="multiple"
                      paginator
                      rows={5}
                      stripedRows
                      loading={loading}
                      header={renderHeader}
                      tableStyle={{ minWidth: "50rem" }}
                    >
                      <Column
                        field="orderNumber"
                        header="Order Number"
                        sortable
                      />
                      <Column
                        field="description"
                        header="Description"
                        body={(rowData) => truncateDescription(rowData.description)}
                        sortable
                      />
                      <Column
                        field="assigneeName"
                        header="Assignee Name"
                        sortable
                      />
                      <Column
                        field="type"
                        header="Type"
                        sortable
                      />
                      <Column
                        field="pricing"
                        header="Pricing"
                        sortable
                        body={(rowData) => `ksh${rowData.pricing}`}
                      />
                      <Column
                        field="status"
                        header="Status"
                        sortable
                        body={statusTemplate}
                      />
                      <Column
                        body={actionTemplate}
                        header="Actions"
                      />
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="tab-pane"
              id="work-order-analysis"
              role="tabpanel"
              aria-labelledby="work-order-analysis-tab"
            >
              <div className="card">
                <div className="card-body">
                  {renderWorkOrderAnalysis()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   
      {showDetailsModal && selectedWorkOrder && (
        <div className="modal show d-block">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Work Order Details</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowDetailsModal(false)}
                />
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <strong>Order Number:</strong> 
                    <p>{selectedWorkOrder.orderNumber}</p>
                  </div>
                  <div className="col-md-6">
                    <strong>Type:</strong>
                    <p>{selectedWorkOrder.type}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <strong>Description:</strong>
                    <p>{selectedWorkOrder.description}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <strong>Assignee Name:</strong>
                    <p>{selectedWorkOrder.assigneeName}</p>
                  </div>
                  <div className="col-md-6">
                    <strong>Pricing:</strong>
                    <p>Ksh {selectedWorkOrder.pricing}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <strong>Status:</strong>
                    {statusTemplate(selectedWorkOrder)}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowDetailsModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Edit Status Modal */}
      {showModal && (
        <div className="modal show d-block">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Update Work Order Status</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                />
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="mb-3">
                    <label htmlFor="status" className="form-label">
                      Status
                    </label>
                    <select
                      className="form-select"
                      id="status"
                      name="status"
                      value={formData.status}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="pending">Pending</option>
                      <option value="in progress">In Progress</option>
                      <option value="completed">Completed</option>
                      <option value="suspended">Suspended</option>
                      <option value="cancelled">Cancelled</option>
                    </select>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <button 
                    type="submit" 
                    className="btn btn-primary"
                    disabled={loading}
                  >
                    {loading ? 'Saving...' : 'Save'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default WorkOrderManagement;