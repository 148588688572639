import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import ChecklistItem from "./checklist_item";

const ChecklistList = ({ items, onEdit, loading }) => {
  const statusTemplate = (rowData) => {
    return (
      <Tag 
        severity={rowData.status === "Working" ? "success" : "danger"}
        value={rowData.status}
      />
    );
  };

  const photoTemplate = (rowData) => {
    return (
      <span>{rowData.photos?.length || 0} photo(s)</span>
    );
  };

  const actionTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-pencil"
        className="p-button-rounded p-button-text"
        onClick={() => onEdit(rowData)}
      />
    );
  };

  return (
    <div className="card">
      <DataTable
        value={items}
        paginator
        rows={10}
        loading={loading}
        emptyMessage="No checklist items found"
        className="p-datatable-striped"
      >
        <Column field="itemName" header="Item" sortable />
        <Column field="category" header="Category" sortable />
        <Column field="status" header="Status" body={statusTemplate} sortable />
        <Column header="Photos" body={photoTemplate} />
        <Column
          headerStyle={{ width: '4rem' }}
          bodyStyle={{ textAlign: 'center' }}
          body={actionTemplate}
        />
      </DataTable>
    </div>
  );
};

export default ChecklistList;