import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { toastify } from "../../../../../utils/toast";
import { getItem } from "../../../../../utils/localStorage";
import { makeRequest2 } from "../../../../../utils/makeRequest";
import {
  create_handover,
  get_leases,
  getUnitsForFacility,
} from "../../../../../utils/urls";
import Layout from "../../../component/layout";

const CreateHandover = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [leases, setLeases] = useState([]);
  const [units, setUnits] = useState([]);

  const [formData, setFormData] = useState({
    handoverType: "",
    leaseId: "",
    unitId: "",
    scheduledDate: "",
    notes: "",
  });

  const fetchLeases = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(`${get_leases}/${facilityId}`, "GET");

      if (response?.success && Array.isArray(response.data.leaseAgreements)) {
        setLeases(response.data.leaseAgreements);
      } else {
        throw new Error("Failed to fetch leases");
      }
    } catch (error) {
      toastify(error.message, "error");
    }
  };

  const fetchUnits = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        `${getUnitsForFacility}/${facilityId}`,
        "GET"
      );

      if (response?.success && Array.isArray(response.data.units)) {
        setUnits(response.data.units);
      } else {
        throw new Error("Failed to fetch units");
      }
    } catch (error) {
      toastify(error.message, "error");
    }
  };

  useEffect(() => {
    fetchLeases();
    fetchUnits();
  }, []);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const facilityId = await getItem("selectedFacilityId");
      const userId = await getItem("userId");

      if (!facilityId) {
        throw new Error("Facility ID not found");
      }

      // Validation
      if (!formData.handoverType) {
        throw new Error("Handover type is required");
      }
      if (!formData.leaseId) {
        throw new Error("Lease is required");
      }
      if (!formData.unitId) {
        throw new Error("Unit is required");
      }
      if (!formData.scheduledDate) {
        throw new Error("Scheduled date is required");
      }

      const response = await makeRequest2(
        `${create_handover}/${facilityId}`,
        "POST",
        {
          ...formData,
          propertyManagerId: userId,
        }
      );

      if (response.success) {
        toastify("Handover created successfully", "success");
        navigate("/facility/handover_management/handovers");
      } else {
        throw new Error(response.error || "Failed to create handover");
      }
    } catch (error) {
      toastify(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/app/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/facility/handover_management/handovers">
                    Handover Management
                  </Link>
                </li>
                <li className="breadcrumb-item">Create Handover</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>Create New Handover</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Handover Type *</label>
                    <select
                      className="form-control"
                      value={formData.handoverType}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          handoverType: e.target.value,
                        }))
                      }
                    >
                      <option value="">Select Type</option>
                      <option value="Move-In">Move-In</option>
                      <option value="Move-Out">Move-Out</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Lease *</label>
                    <select
                      className="form-control"
                      value={formData.leaseId}
                      onChange={(e) => {
                        const selectedLease = leases.find(
                          (lease) => lease._id === e.target.value
                        );
                        setFormData((prev) => ({
                          ...prev,
                          leaseId: e.target.value,
                          unitId: selectedLease?.unitInfo?._id || "",
                        }));
                      }}
                    >
                      <option value="">Select Lease</option>
                      {leases.map((lease) => (
                        <option key={lease._id} value={lease._id}>
                          {lease.tenantInfo?.fullName} -{" "}
                          {lease.unitInfo?.unitName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Unit *</label>

                    <select
                      className="form-control"
                      value={formData.unitId}
                      onChange={(e) => {
                        const selectedUnit = units.find(
                          (unit) => unit._id === e.target.value
                        );
                        setFormData((prev) => ({
                          ...prev,
                          unitId: e.target.value,
                        }));
                      }}
                    >
                      <option value="">Select Unit</option>
                      {units.map((unit) => (
                        <option key={unit._id} value={unit._id}>
                          {unit.unitName || unit.name || `Unit ${unit._id}`}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Scheduled Date *</label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      value={formData.scheduledDate}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          scheduledDate: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Notes</label>
                    <textarea
                      className="form-control"
                      rows="4"
                      value={formData.notes}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          notes: e.target.value,
                        }))
                      }
                      placeholder="Enter any additional notes..."
                    />
                  </div>
                </div>
              </div>

              <div className="text-end mt-4">
                <Button
                  label="Cancel"
                  className="p-button-secondary mr-2"
                  onClick={() => navigate(-1)}
                  disabled={loading}
                />
                <Button
                  label="Create Handover"
                  className="p-button-primary"
                  onClick={handleSubmit}
                  loading={loading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CreateHandover;
