import React from 'react';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

export const frequencyOptions = [
    { label: 'Monthly', value: 'monthly' },
    { label: 'Bimonthly', value: 'bimonthly' },
    { label: 'Quarterly', value: 'quarterly' },
    { label: 'Half Annually', value: 'half_annually' },
    { label: 'Annually', value: 'annually' }
];

export const commissionTypeOptions = [
    { label: 'Fixed', value: 'fixed' },
    { label: 'Percentage', value: 'percentage' }
];

export const typeOptions = [
    { label: 'Scheduled', value: 'scheduled' },
    { label: 'Unscheduled', value: 'unscheduled' },
];

export const statusOptions = [
    { label: 'In Progress', value: 'In Progress' },
    { label: 'Completed', value: 'Completed' },
    { label: 'Pending', value: 'Pending' },
    { label: 'Cancelled', value: 'Cancelled' }
];

export const statusTemplate = (rowData) => {
    const statusStyles = {
        'Pending': { color: '#ffc107', backgroundColor: '#fff8e1' },
        'In Progress': { color: '#17a2b8', backgroundColor: '#e0f4f7' },
        'Completed': { color: '#28a745', backgroundColor: '#e9fbe9' },
        'Cancelled': { color: '#dc3545', backgroundColor: '#fde9e9' }
    };

    const style = statusStyles[rowData.status] || {
        color: '#6c757d',
        backgroundColor: '#f1f1f1'
    };

    return (
        <span
            style={{
                ...style,
                padding: '0.5em 1em',
                borderRadius: '12px',
                fontWeight: 'bold',
                fontSize: '0.9rem',
                display: 'inline-block'
            }}
        >
            {rowData.status}
        </span>
    );
};

export const WorkOrderForm = ({ form, setForm, handleInputChange, onSubmit, setShowModal, vendors, handleVendorSelect }) => (
    <div className="dialog-body">
        <div className="mb-3">
            <label className="form-label">Vendor</label>
            <select
                className="form-select w-100"
                value={form.vendorId}
                onChange={(e) => handleVendorSelect(e.target.value)}
                required
            >
                <option value="">Select a vendor</option>
                {vendors.map((vendor) => (
                    <option key={vendor.value} value={vendor.value}>
                        {vendor.label}
                    </option>
                ))}
            </select>
        </div>

        <div className="mb-3">
            <label className="form-label">Description</label>
            <InputText
                className="w-100"
                name="description"
                value={form.description}
                onChange={handleInputChange}
                placeholder="Enter description"
                required
            />
        </div>

        <div className="mb-3">
            <label className="form-label">Pricing</label>
            <InputNumber
                className="w-100"
                value={form.pricing}
                disabled={true}
                placeholder="Vendor pricing"
            />
        </div>

        <div className="d-flex justify-content-end gap-2">
            <Button
                label="Cancel"
                className="btn btn-outline-primary"
                onClick={() => setShowModal(false)}
            />
            <Button
                label="Submit Work Order"
                icon="pi pi-check"
                className="btn btn-primary"
                onClick={onSubmit}
            />
        </div>
    </div>
);

export const CommissionForm = ({ form, setForm, onSubmit, setShowModal }) => (
    <div className="dialog-body">
        <div className="mb-3">
            <label className="form-label">Commission Type</label>
            <select
                className="form-select w-100"
                value={form.type || ""}
                onChange={(e) =>
                    setForm((prev) => ({
                        ...prev,
                        type: e.target.value,
                    }))
                }
                required
            >
                <option value="" disabled hidden>
                    Select Commission Type
                </option>
                {commissionTypeOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>

        {form.type === 'fixed' ? (
            <div className="mb-3">
                <label className="form-label">Amount</label>
                <InputNumber
                    className="w-100"
                    value={form.amount}
                    onValueChange={(e) => setForm(prev => ({
                        ...prev,
                        amount: e.value
                    }))}
                    mode="currency"
                    currency="KSH"
                    placeholder="Enter amount"
                    min={0}
                    required
                />
            </div>
        ) : form.type === 'percentage' && (
            <>
                <div className="mb-3">
                    <label className="form-label">Percentage Amount</label>
                    <InputNumber
                        className="w-100"
                        value={form.percentageAmount}
                        onValueChange={(e) => setForm(prev => ({
                            ...prev,
                            percentageAmount: e.value
                        }))}
                        suffix="%"
                        min={0}
                        max={100}
                        placeholder="Enter percentage"
                        required
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label">Rent Amount</label>
                    <InputNumber
                        className="w-100"
                        value={form.rentAmount}
                        onValueChange={(e) => setForm(prev => ({
                            ...prev,
                            rentAmount: e.value
                        }))}
                        placeholder="Rent per month"
                        min={0}
                        required
                    />
                </div>
            </>
        )}

        <div className="d-flex justify-content-end gap-2">
            <Button
                label="Cancel"
                className="btn btn-outline-primary"
                onClick={() => setShowModal(false)}
            />
            <Button
                label="Submit"
                icon="pi pi-check"
                className="btn btn-primary"
                onClick={onSubmit}
            />
        </div>
    </div>
);

export const UnitManagementForm = ({ form, setForm, onSubmit, setShowModal }) => (
    <div className="dialog-body">
        <div className="mb-3">
            <label className="form-label">Percentage</label>
            <InputNumber
                className="w-100"
                value={form.percentage}
                onValueChange={(e) => setForm(prev => ({
                    ...prev,
                    percentage: e.value
                }))}
                suffix="%"
                min={0}
                max={100}
                placeholder="Enter percentage"
                required
            />
        </div>

        <div className="mb-3">
            <label className="form-label">Rent Amount</label>
            <InputNumber
                className="w-100"
                value={form.rentAmount}
                onValueChange={(e) => setForm(prev => ({
                    ...prev,
                    rentAmount: e.value
                }))}
                placeholder="Rent per month"
                min={0}
                required
            />
        </div>

        <div className="mb-3">
            <label className="form-label">Frequency</label>
            <select
                className="form-select w-100"
                value={form.frequency || ""}
                onChange={(e) =>
                    setForm((prev) => ({
                        ...prev,
                        frequency: e.target.value,
                    }))
                }
                required
            >
                <option value="" disabled hidden>
                    Select Frequency
                </option>
                {frequencyOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>

        <div className="d-flex justify-content-end gap-2">
            <Button
                label="Cancel"
                className="btn btn-outline-primary"
                onClick={() => setShowModal(false)}
            />
            <Button
                label="Submit"
                icon="pi pi-check"
                className="btn btn-primary"
                onClick={onSubmit}
            />
        </div>
    </div>
);

const ServiceForms = {
    CommissionForm,
    UnitManagementForm,
    WorkOrderForm,
    statusTemplate,
    frequencyOptions,
    commissionTypeOptions,
    statusOptions,
    typeOptions
};

export default ServiceForms;