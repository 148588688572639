import { useState } from 'react';
import { toastify } from '../../../../utils/toast';
import { makeRequest2 } from "../../../../utils/makeRequest";
import { getItem } from '../../../../utils/localStorage';
import {
    updateServiceRequest,
    assignWorkOrder,
    addServiceInvoice,
    getVasInvoices,
    getVasInvoice,
    getVasVendors
} from '../../../../utils/urls';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export const useFacilityServiceRequests = (refreshRequests) => {
    // Service request states
    const [selectedFacilityServiceRequest, setSelectedFacilityServiceRequest] = useState(null);
    const [isServiceManagementModalVisible, setServiceManagementModalVisible] = useState(false);
    const [showApprovalMatrix, setShowApprovalMatrix] = useState(false);
    const [showServiceForm, setShowServiceForm] = useState(false);

    // Vendor states
    const [vendors, setVendors] = useState([]);

    // Invoice management states
    const [loading, setLoading] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const [error, setError] = useState(null);

    // Form states
    const [unitManagementForm, setUnitManagementForm] = useState({
        amount: 0,
        frequency: '',
        rentPercentage: 0
    });

    const [commissionForm, setCommissionForm] = useState({
        type: '',
        amount: 0,
        percentageAmount: 0,
        rentAmount: 0
    });

    const [workOrderForm, setWorkOrderForm] = useState({
        description: '',
        pricing: 0,
        vendorId: '',
        serviceId: ''
    });

    // Vendor management functions
    const getVendorsForService = async (serviceId) => {
        try {
            if (!serviceId) {
                throw new Error('Service ID is required');
            }

            const facilityId = await getItem('selectedFacilityId');
            if (!facilityId) {
                throw new Error('Facility ID not found');
            }

            const response = await makeRequest2(`${getVasVendors}/${facilityId}`, 'GET');

            if (!response) {
                throw new Error('No response from vendors API');
            }

            if (response?.success && Array.isArray(response.data?.vendors)) {
                const filteredVendors = response.data.vendors.filter(vendor => {
                    if (!vendor.offers || !Array.isArray(vendor.offers)) {
                        console.warn('Vendor missing offers array:', vendor);
                        return false;
                    }
                    return vendor.offers.some(offer => {
                        const offerServiceId = offer.serviceId;
                        return offerServiceId === serviceId;
                    });
                });

                const formattedVendors = filteredVendors.map(vendor => ({
                    value: vendor._id,
                    label: vendor.name,
                    pricing: vendor.offers.find(o => {
                        const offerId = o.serviceId;
                        return offerId === serviceId;
                    })?.amount || 0
                }));
                setVendors(formattedVendors);
                return formattedVendors;
            } else {
                throw new Error(response?.message || 'Invalid response from vendors API');
            }
        } catch (error) {
            setVendors([]);
            throw error;
        }
    };



    const handleVendorSelect = (value) => {
        const selectedVendor = vendors.find(v => v.value === value);
        setWorkOrderForm(prev => ({
            ...prev,
            vendorId: value,
            pricing: selectedVendor?.pricing || 0
        }));
    };

    // Invoice Management Functions
    const fetchInvoices = async () => {
        try {
            setLoading(true);
            setError(null);
            const facilityId = await getItem("selectedFacilityId");
            const response = await makeRequest2(
                `${getVasInvoices}/${facilityId}`,
                'GET'
            );

            if (response?.data?.success && Array.isArray(response.data.data)) {
                const formattedInvoices = response.data.data.map(invoice => ({
                    ...invoice,
                    formattedDate: formatDate(invoice.createdAt),
                    formattedTime: formatTime(invoice.createdAt),
                    formattedAmount: formatCurrency(invoice.amount)
                }));
                setInvoices(formattedInvoices);
            } else {
                throw new Error('Invalid response format or no invoices found');
            }
        } catch (error) {
            console.error('Error fetching invoices:', error);
            setError(error.message || 'Failed to fetch invoices');
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const fetchSingleInvoice = async (facilityId, invoiceId) => {
        try {
            setLoading(true);
            const response = await makeRequest2(`${getVasInvoice}/${facilityId}/${invoiceId}`, "GET", {});

            if (response.success) {
                const fetchedInvoice = response.data.invoice;
                return {
                    ...fetchedInvoice,
                    fullName: fetchedInvoice.customerInfo?.fullName,
                };
            } else {
                throw new Error(response.message || "Failed to fetch invoice");
            }
        } catch (error) {
            setError(error.message);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const fetchCompanyDetails = async (facilityId) => {
        try {
            const response = await makeRequest2(
                `/api/facilities/${facilityId}/details`,
                "GET"
            );
            if (response.success) {
                return response.data;
            }
            throw new Error('Failed to fetch company details');
        } catch (error) {
            console.error("Failed to fetch company details:", error);
            throw error;
        }
    };

    const downloadInvoicePDF = async (elementId, invoice) => {
        try {
            setLoading(true);
            const element = document.getElementById(elementId);
            if (!element) {
                throw new Error('Element not found');
            }

            const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
            const filename = `Invoice_${invoice?.invoiceNumber || 'unknown'}_${timestamp}.pdf`;

            const canvas = await html2canvas(element, {
                scale: 2,
                useCORS: true,
                logging: false,
                backgroundColor: "#ffffff",
            });

            const imgData = canvas.toDataURL("image/jpeg", 1.0);
            const pdf = new jsPDF({
                orientation: "portrait",
                unit: "mm",
                format: "a4",
            });

            const imgWidth = 210; // A4 width in mm
            const pageHeight = 297; // A4 height in mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight, "", "FAST");

            if (imgHeight > pageHeight) {
                let remainingHeight = imgHeight;
                let position = -pageHeight;

                while (remainingHeight > 0) {
                    pdf.addPage();
                    position -= pageHeight;
                    pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight, "", "FAST");
                    remainingHeight -= pageHeight;
                }
            }

            pdf.save(filename);
            return true;
        } catch (error) {
            console.error('Error generating PDF:', error);
            toastify('Error generating PDF', 'error');
            throw error;
        } finally {
            setLoading(false);
        }
    };

    // Service request functions
    const handleApprove = async () => {
        setShowApprovalMatrix(false);
        setShowServiceForm(true);
        await refreshRequests();
    };

    const handleReject = async (serviceId) => {
        try {
            const facilityId = await getItem("selectedFacilityId");
            const data = {
                serviceRequestId: serviceId,
                status: 'Cancelled'
            };

            const url = updateServiceRequest + '/' + facilityId;
            const response = await makeRequest2(url, 'POST', data);

            toastify('Request cancelled successfully', 'success');
            setShowApprovalMatrix(false);
            setServiceManagementModalVisible(false);
            await refreshRequests();
        } catch (error) {
            toastify('Error rejecting request', 'error');
        }
    };

    const handleServiceManagementClick = async (rowData) => {
        setSelectedFacilityServiceRequest(rowData);
        setWorkOrderForm(prev => ({
            ...prev,
            serviceId: rowData.serviceId
        }));

        try {
            await getVendorsForService(rowData.serviceId);
        } catch (error) {
            toastify('Error loading vendors', 'error');
        }

        setServiceManagementModalVisible(true);
        setShowApprovalMatrix(true);
        setShowServiceForm(false);
    };

    // Form submission handlers
    const handleUnitManagementSubmit = async () => {
        try {
            const facilityId = await getItem("selectedFacilityId");
            const totalRent = calculateTotalRent(unitManagementForm.rentAmount, unitManagementForm.frequency);
            const unitAmount = calculateAmount(totalRent, unitManagementForm.percentage);

            const invoiceData = {
                facilityId,
                serviceId: selectedFacilityServiceRequest._id,
                customerId: selectedFacilityServiceRequest.customerId,
                amount: unitAmount,
                status: 'Pending'
            };

            const serviceData = {
                serviceRequestId: selectedFacilityServiceRequest._id,
                status: 'Completed'
            };

            const serviceUrl = updateServiceRequest + '/' + facilityId;
            await makeRequest2(serviceUrl, 'POST', serviceData);

            const invoiceUrl = addServiceInvoice + '/' + facilityId;
            await makeRequest2(invoiceUrl, 'POST', invoiceData);

            toastify('Unit management service processed successfully', 'success');
            setServiceManagementModalVisible(false);
            await refreshRequests();
        } catch (error) {
            toastify('Error processing unit management', 'error');
            console.error('Error:', error);
        }
    };

    const handleCommissionSubmit = async () => {
        try {
            const facilityId = await getItem("selectedFacilityId");
            let commissionAmount;

            if (commissionForm.type === 'fixed') {
                commissionAmount = commissionForm.amount;
            } else {
                commissionAmount = (commissionForm.rentAmount * (commissionForm.percentageAmount / 100)).toFixed(2);
            }

            const invoiceData = {
                facilityId,
                serviceId: selectedFacilityServiceRequest._id,
                customerId: selectedFacilityServiceRequest.customerId,
                amount: commissionAmount,
                status: 'Pending'
            };

            const serviceData = {
                serviceRequestId: selectedFacilityServiceRequest._id,
                status: 'Completed'
            };

            const serviceUrl = updateServiceRequest + '/' + facilityId;
            await makeRequest2(serviceUrl, 'POST', serviceData);

            const invoiceUrl = addServiceInvoice + '/' + facilityId;
            await makeRequest2(invoiceUrl, 'POST', invoiceData);

            toastify('Commission service processed successfully', 'success');
            setServiceManagementModalVisible(false);
            await refreshRequests();
        } catch (error) {
            toastify('Error processing commission', 'error');
            console.error('Error:', error);
        }
    };

    const handleWorkOrderSubmit = async () => {
        try {
            const facilityId = await getItem("selectedFacilityId");

            const serviceData = {
                serviceRequestId: selectedFacilityServiceRequest._id,
                assigneeId: workOrderForm.vendorId,
                amount: workOrderForm.pricing,
                status: 'Awaiting'
            };

            const serviceUrl = updateServiceRequest + '/' + facilityId;
            await makeRequest2(serviceUrl, 'POST', serviceData);

            toastify('Service request awaiting approval', 'success');
            setServiceManagementModalVisible(false);
            await refreshRequests();
        } catch (error) {
            toastify('Error assigning work order', 'error');
            console.error('Error:', error);
        }
    };

    // Utility functions
    const calculateTotalRent = (monthlyRent, frequency) => {
        const frequencyMultipliers = {
            'monthly': 1,
            'bimonthly': 2,
            'quarterly': 3,
            'half_annually': 6,
            'annually': 12
        };

        return monthlyRent * (frequencyMultipliers[frequency] || 1);
    };

    const calculateAmount = (totalRent, percentage) => {
        return (totalRent * (percentage / 100)).toFixed(2);
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString();
    };

    const formatTime = (dateString) => {
        return new Date(dateString).toLocaleTimeString();
    };

    const formatCurrency = (value) => {
        return value?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'KSH'
        }) || 'KSH 0.00';
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setWorkOrderForm((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    return {
        states: {
            selectedFacilityServiceRequest,
            isServiceManagementModalVisible,
            showApprovalMatrix,
            showServiceForm,
            unitManagementForm,
            commissionForm,
            workOrderForm,
            loading,
            invoices,
            error,
            vendors
        },
        setters: {
            setSelectedFacilityServiceRequest,
            setServiceManagementModalVisible,
            setShowApprovalMatrix,
            setShowServiceForm,
            setUnitManagementForm,
            setCommissionForm,
            setWorkOrderForm,
            setVendors
        },
        handlers: {
            handleApprove,
            handleReject,
            handleUnitManagementSubmit,
            handleCommissionSubmit,
            handleWorkOrderSubmit,
            handleInputChange,
            handleServiceManagementClick,
            handleVendorSelect,
            getVendorsForService
        },
        invoiceHandlers: {
            fetchInvoices,
            fetchSingleInvoice,
            fetchCompanyDetails,
            downloadInvoicePDF,
            formatDate,
            formatTime,
            formatCurrency
        }
    };
};

export default useFacilityServiceRequests;