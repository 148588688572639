import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Layout from '../component/layout';
import { toastify } from '../../../utils/toast';
import ConfirmDialog from '../component/confirmDialog';
import { getItem } from '../../../utils/localStorage';
import { makeRequest2 } from '../../../utils/makeRequest';
import { closeTicketURL, finishReviewURL, getVasVendors } from '../../../utils/urls';

const StartReview = () => {
    const navigate = useNavigate();
    const { ticketId } = useParams();

    const [currentStep, setCurrentStep] = useState('StartReview');
    const [needsFix, setNeedsFix] = useState('');
    const [closeReason, setCloseReason] = useState('');
    const [byWho, setByWho] = useState('');
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [selectedVendor, setSelectedVendor] = useState('');
    const [image, setImage] = useState(null);
    const [reviewText, setReviewText] = useState('');
    const [costAttachment, setCostAttachement] = useState(null);
    const [payer, setPayer] = useState('');
    const [completedSteps, setCompletedSteps] = useState(['StartReview']);

    const [confirmVisible, setConfirmVisible] = useState(false);

    const [employees, setEmployees] = useState([]);
    const [serviceVendors, setServiceVendors] = useState([]);

    const location = useLocation();
    const { requester, description } = location.state || {};

    const steps = ['StartReview', 'UploadReview', 'EstimatedCosts', 'PayerApproval'];



    const [services, setServices] = useState([
        { description: '', amount: '' }, // Initial service field
    ]);

    const [totalCost, setTotalCost] = useState(0);


    // Handle field changes dynamically
    const handleServiceChange = (index, field, value) => {
        const updatedServices = [...services];
        updatedServices[index][field] = value; // Update specific field
        setServices(updatedServices);
        calculateTotalCost(updatedServices); // Recalculate total cost
    };

    // Add a new service field
    const addServiceField = () => {
        setServices([...services, { description: '', amount: '' }]);
    };

    // Remove a specific service field
    const removeServiceField = (index) => {
        const updatedServices = services.filter((_, i) => i !== index);
        setServices(updatedServices);
        calculateTotalCost(updatedServices); // Recalculate total cost
    };

    // Calculate the total cost
    const calculateTotalCost = (servicesArray) => {
        const total = servicesArray.reduce(
            (sum, service) => sum + (parseFloat(service.amount) || 0),
            0
        );
        setTotalCost(total);
    };






    const hideConfirmDialog = () => {
        setConfirmVisible(false);
    };

    const getCurrentUser = async () => {
        try {
            const currentUserValue = await getItem("APPUSER");

            let currentUser;
            // Check if the value needs to be parsed
            if (typeof currentUserValue === "string") {
                try {
                    currentUser = JSON.parse(currentUserValue);
                } catch (err) {
                    console.error("Error parsing APPUSER:", err);
                }
            } else {
                currentUser = currentUserValue; // Assume it's already an object
            }

            // Safely access and return phone number
            if (currentUser?.user) {
                return currentUser.user.phoneNumber;
            } else {
                console.error("Invalid user structure:", currentUser);
                return null; // Return null if user structure is invalid
            }
        } catch (err) {
            console.log(err.message);
            return null; // Return null if an error occurs
        }
    };


    const handleCloseTicket = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const response = await makeRequest2(closeTicketURL + '/' + ticketId + '/' + facilityId, 'POST', {
                status: 'closed',
                reason: closeReason,
            });

            if (response.success) {
                toastify('Ticket successfully closed and moved to Resolved.', 'success');
                setConfirmVisible(false);
                navigate('/facility/ticket_management/tickets');
            } else {
                throw new Error(response.message || 'Failed to update ticket status.');
            }
        } catch (err) {
            toastify(err.message || 'Error closing ticket.', 'error');
        }
    };


    const handleNextStep = () => {
        const currentIndex = steps.indexOf(currentStep);
        if (currentIndex < steps.length - 1) {
            const nextStep = steps[currentIndex + 1];
            setCompletedSteps([...completedSteps, nextStep]);
            setCurrentStep(nextStep);
        }
    };

    const handleBackToPrevious = () => {
        const currentIndex = steps.indexOf(currentStep);
        if (currentIndex > 0) {
            setCurrentStep(steps[currentIndex - 1]);
        }
    };

    const handleFinish = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const propertyManagerPhoneNumber = await getCurrentUser();
            const selectedVendorPhone = selectedVendor?.contactDetails?.phone;

            const formData = new FormData();
            formData.append('needsFix', needsFix);
            formData.append('byWho', byWho);
            formData.append('selectedEmployee', selectedEmployee);
            formData.append('selectedEmployeePhoneNumber', selectedEmployee.phoneNumber)
            formData.append('selectedVendorPhoneNumber', selectedVendorPhone)
            formData.append('selectedVendor', selectedVendor);
            formData.append('reviewText', reviewText);
            formData.append('payer', payer);

            formData.append('requester', requester);
            formData.append('description', description);

            formData.append('services', JSON.stringify(services));

            formData.append('totalAmount', totalCost);

            if (propertyManagerPhoneNumber) {
                formData.append('propertyManagerPhoneNumber', propertyManagerPhoneNumber); // Add phone number to FormData
            }

            if (image) {
                formData.append('image', image);
            } else {
                console.log('No image selected');
            }

            // Append the cost attachment if available
            if (costAttachment) {
                formData.append('costAttachment', costAttachment); // Attaching the file
            } else {
                console.log('No cost attachment selected');
            }

            const response = await makeRequest2(`${finishReviewURL}/${ticketId}/${facilityId}`, 'POST', formData);

            if (response.success) {
                toastify('Ticket Review Finished Successfully', 'success');
                handleClear();
                navigate('/facility/ticket_management/tickets');
            } else {
                console.log(response.error);
                throw new Error(response.error);
            }

        } catch (err) {
            toastify(err.message, 'error');
        }
    }

    const fetchServiceVendors = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const url = `${getVasVendors}/${facilityId}`;
            const response = await makeRequest2(url, 'GET', {});
            // Handle case where returns success but data is an empty array (404 response)
            if (response?.success) {
                if (Array.isArray(response.data)) {
                    setServiceVendors(response.data);
                    if (response.data.length === 0) {
                        toastify('No vendors available', 'info');
                    }
                } else if (Array.isArray(response)) {
                    // Handle case where response might be the array directly
                    setServiceVendors(response);
                    if (response.length === 0) {
                        toastify('No vendors available', 'info');
                    }
                }
            } else {
                setServiceVendors([]);
                toastify('Error loading vendors', 'error');
            }
        } catch (error) {
            console.error('Error fetching vendors:', error);
            setServiceVendors([]);
            toastify('Error fetching vendors', 'error');
        }
    };

    const fetchEmployees = async () => {
        try {
            const currentFacilityId = await getItem('selectedFacilityId');
            const response = await makeRequest2(
                `/api/employees/${currentFacilityId}`,
                "GET",
                null
            );

            if (response.success) {
                const employeeData = Array.isArray(response.data)
                    ? response.data
                    : Array.isArray(response.data.employees)
                        ? response.data.employees
                        : [];
                setEmployees(employeeData);
            } else {
                toastify("Failed to fetch staff records", 'error');
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    };

    const handleEmployeeChange = (e) => {
        const selectedId = e.target.value;
        const employee = employees.find(emp => emp._id === selectedId); // Use _id instead of id
        if (employee) {
            setSelectedEmployee(employee); // Store the entire employee object
        } else {
            console.log("Employee not found");
        }
    };

    const handleClear = () => {
        setNeedsFix('');
        setByWho('');
        setSelectedEmployee('');
        setSelectedVendor('');
        setImage(null);
        setReviewText('');
        setPayer('');
    }

    useEffect(() => {
        getCurrentUser();
        fetchEmployees();
        fetchServiceVendors();
    }, []);

    return (
        <Layout>
            <div className="card">
                <div className="card-header">
                    <Link to={"#"} onClick={() => navigate(-1)}>
                        <span>
                            <i className="ti ti-arrow-narrow-left"></i> Back
                        </span>
                    </Link>
                </div>
            </div>
            <div className="wizard-container">
                {/* Step Navigation */}
                <div className="card">
                    <div className="card-body p-3">
                        <ul className="nav nav-pills nav-justified">
                            {steps.map((step, index) => (
                                <li className="nav-item" key={step}>
                                    <button
                                        className={`nav-link ${currentStep === step ? 'active' : ''} ${completedSteps.includes(step) ? '' : 'disabled'
                                            }`}
                                        onClick={() =>
                                            completedSteps.includes(step) ? setCurrentStep(step) : null
                                        }
                                        disabled={!completedSteps.includes(step)}
                                    >
                                        {step}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                {/* Step Content */}
                <div className="card mt-3">
                    <div className="card-body">
                        {currentStep === 'StartReview' && (
                            <div>
                                <h2>Start Review</h2>
                                <div className="mb-4">
                                    <label htmlFor="needsFix" className="form-label">
                                        Needs to be fixed:
                                    </label>
                                    <select
                                        id="needsFix"
                                        className="form-select"
                                        value={needsFix}
                                        onChange={(e) => setNeedsFix(e.target.value)}
                                    >
                                        <option value="">Select</option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>

                                {needsFix === 'no' && (
                                    <div className="text-end mt-4">
                                        <textarea
                                            className="form-control mb-3"
                                            rows="3"
                                            placeholder="Enter reason for closing the ticket"
                                            value={closeReason}
                                            onChange={(e) => setCloseReason(e.target.value)}
                                        ></textarea>
                                        <button
                                            className="btn btn-danger"
                                            onClick={() => setConfirmVisible(true)}
                                        >
                                            Close Ticket
                                        </button>
                                    </div>
                                )}


                                {needsFix === 'yes' && (
                                    <div>
                                        <div className="mb-4">
                                            <label htmlFor="byWho" className="form-label">
                                                Who to Assess:
                                            </label>
                                            <select
                                                id="byWho"
                                                className="form-select"
                                                value={byWho}
                                                onChange={(e) => setByWho(e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                <option value="propertyManager">Property Manager</option>
                                                <option value="vendor">Vendor</option>
                                            </select>
                                        </div>

                                        {byWho === 'propertyManager' && (
                                            <div>
                                                <div className="mb-4">
                                                    <label htmlFor="employees" className="form-label">
                                                        Select Staff:
                                                    </label>
                                                    <select
                                                        id="employees"
                                                        className="form-select"
                                                        value={selectedEmployee?._id || ""}
                                                        onChange={handleEmployeeChange}
                                                    >
                                                        <option value="" disabled>
                                                            Select
                                                        </option>
                                                        {employees.map((employee) => (
                                                            <option key={employee._id} value={employee._id}>
                                                                {employee.name}
                                                            </option>
                                                        ))}
                                                    </select>


                                                </div>
                                            </div>
                                        )}

                                        {byWho === 'vendor' && (
                                            <div>
                                                <div className="mb-4">
                                                    <label htmlFor="vendors" className="form-label">
                                                        Select Vendor:
                                                    </label>
                                                    <select
                                                        id="vendors"
                                                        className="form-select"
                                                        value={selectedVendor?._id || ""}
                                                        onChange={(e) => {
                                                            const selectedVendorId = e.target.value;
                                                            const vendor = serviceVendors.find((serviceVendor) => serviceVendor._id === selectedVendorId);
                                                            setSelectedVendor(vendor); // Store the entire vendor object
                                                        }}
                                                    >
                                                        <option value="" disabled>
                                                            Select
                                                        </option>
                                                        {serviceVendors.map((serviceVendor) => (
                                                            <option key={serviceVendor._id} value={serviceVendor._id}>
                                                                {serviceVendor.name}
                                                            </option>
                                                        ))}
                                                    </select>

                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}

                        {currentStep === 'UploadReview' && (
                            <div>
                                <h4>Upload Review</h4>
                                <div className="mb-3">
                                    <label htmlFor="photoUpload" className="form-label">
                                        Upload Photo:
                                    </label>
                                    <input
                                        type="file"
                                        id="photoUpload"
                                        className="form-control"
                                        onChange={(e) => setImage(e.target.files[0])}
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="reviewText" className="form-label">
                                        Write a Review:
                                    </label>
                                    <textarea
                                        id="reviewText"
                                        className="form-control"
                                        rows="4"
                                        value={reviewText}
                                        onChange={(e) => setReviewText(e.target.value)}
                                    ></textarea>
                                </div>
                            </div>
                        )}

                        {currentStep === 'EstimatedCosts' && (
                            <div>
                                <h4>Estimated Costs</h4>
                                {services.map((service, index) => (
                                    <div className="row align-items-center mb-3" key={index}>
                                        <div className="col-6">
                                            <label className="form-label">Service Description</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Service Description"
                                                value={services[index]?.description || ''} // Bind to specific service description
                                                onChange={(e) => handleServiceChange(index, 'description', e.target.value)} // Update description dynamically
                                            />
                                        </div>
                                        <div className="col-5">
                                            <label className="form-label">Amount</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Amount"
                                                value={services[index]?.amount || ''} // Bind to specific service amount
                                                onChange={(e) => handleServiceChange(index, 'amount', e.target.value)} // Update amount dynamically
                                            />
                                        </div>
                                        {/* Allow removal for dynamically added fields only */}
                                        {index > 0 && (
                                            <div className="col-1 text-end">
                                                <button
                                                    className="btn btn-danger mt-4"
                                                    onClick={() => removeServiceField(index)}
                                                >
                                                    -
                                                </button>
                                            </div>
                                        )}
                                    </div>



                                ))}
                                {/* Add Button */}
                                <div className="text-end">
                                    <button
                                        className="btn btn-primary"
                                        onClick={addServiceField}
                                    >
                                        + Add Another Service
                                    </button>
                                </div>
                                {/* Total Cost Display */}
                                <div className="mt-3">
                                    <h5>Total Cost: {totalCost.toFixed(2)}</h5>
                                </div>
                                {/* File Upload */}
                                <div className="mt-3">
                                    <label className="form-label">Attach Detailed Cost Breakdown (if any)</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        onChange={(e) => setCostAttachement(e.target.files[0])}
                                    />
                                </div>
                            </div>
                        )}

                        {currentStep === 'PayerApproval' && (
                            <div>
                                <h4>Payer Approval</h4>
                                <div className="mb-3">
                                    <label htmlFor="payer-dropdown" className="form-label">
                                        Who will pay:
                                    </label>
                                    <select
                                        id="payer-dropdown"
                                        className="form-select"
                                        value={payer}
                                        onChange={(e) => setPayer(e.target.value)}
                                    >
                                        <option value="" disabled>
                                            Select an option
                                        </option>
                                        <option value="landlord">Landlord</option>
                                        <option value="propertyManager">Property Manager</option>
                                        <option value="tenant">Tenant</option>
                                    </select>
                                </div>
                            </div>
                        )}

                        {/* Navigation Buttons */}
                        {needsFix === 'yes' && (
                            <div className="d-flex justify-content-between mt-3">
                                <button
                                    className="btn btn-secondary"
                                    onClick={handleBackToPrevious}
                                    disabled={steps.indexOf(currentStep) === 0}
                                >
                                    Back
                                </button>
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => {
                                        if (steps.indexOf(currentStep) === steps.length - 1) {
                                            handleFinish();
                                        } else {
                                            handleNextStep();
                                        }
                                    }}
                                >
                                    {steps.indexOf(currentStep) === steps.length - 1 ? 'Finish' : 'Next'}
                                </button>
                            </div>
                        )}

                    </div>
                </div>
            </div>


            <ConfirmDialog
                visible={confirmVisible}
                onHide={hideConfirmDialog}
                message="Are you sure you want to close this ticket?"
                onConfirm={handleCloseTicket}
            />
        </Layout>
    );
};

export default StartReview;
