// DeleteLease.jsx
import React from 'react';
import { Button } from "primereact/button";
import { makeRequest2 } from "../../../../utils/makeRequest";
import { delete_lease } from "../../../../utils/urls";
import { getItem } from "../../../../utils/localStorage";
import { toastify } from "../../../../utils/toast";
import ConfirmDialog from "../../component/confirmDialog";

const DeleteLease = ({ leaseId, onDelete, isConfirmDialogVisible, setConfirmDialogVisible }) => {
  const handleDelete = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(`${delete_lease}/${facilityId}/${leaseId}`, "DELETE");
      
      if (response.success) {
        toastify("Lease deleted successfully", "success");
        if (onDelete) onDelete();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
    setConfirmDialogVisible(false);
  };

  return (
    <>
      <Button
        icon="ti ti-trash"
        className="p-button-rounded p-button-danger mr-2"
        onClick={() => setConfirmDialogVisible(true)}
        tooltip="Delete"
      />

      <ConfirmDialog
        visible={isConfirmDialogVisible}
        onHide={() => setConfirmDialogVisible(false)}
        message="Are you sure you want to delete this Lease?"
        onConfirm={handleDelete}
      />
    </>
  );
};

export default DeleteLease;