import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Layout from "../../component/layout";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { toastify } from "../../../../utils/toast";
import { getItem } from "../../../../utils/localStorage";
import { makeRequest2 } from "../../../../utils/makeRequest";
import { get_checklist } from "../../../../utils/urls";
import ChecklistForm from "./components/checklist_form";
import ChecklistList from "./components/checklist_list";

const ChecklistManagement = () => {
  const { handoverId } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [checklistItems, setChecklistItems] = useState([]);

  const fetchChecklist = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      if (!facilityId) {
        toastify("Please select a facility first", "error");
        return;
      }

      const response = await makeRequest2(
        `${get_checklist}/${facilityId}/${handoverId}`,
        "GET"
      );

      if (response.success) {
        setChecklistItems(response.checklist.all || []);
      } else {
        throw new Error(response.error || "Failed to fetch checklist");
      }
    } catch (error) {
      toastify(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (handoverId) {
      fetchChecklist();
    }
  }, [handoverId]);

  const handleAddItem = () => {
    setSelectedItem(null);
    setDialogVisible(true);
  };

  const handleEditItem = (item) => {
    setSelectedItem(item);
    setDialogVisible(true);
  };

  const handleFormSubmit = () => {
    setDialogVisible(false);
    fetchChecklist();
  };

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/app/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/facility/handover_management/handovers">
                    Handover Management
                  </Link>
                </li>
                <li className="breadcrumb-item">Checklist</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h5>Checklist Management</h5>
              <Button
                label="Add New Item"
                icon="pi pi-plus"
                className="p-button-primary"
                onClick={handleAddItem}
              />
            </div>
            <div className="card-body">
              <TabView
                activeIndex={activeTab}
                onTabChange={(e) => setActiveTab(e.index)}
              >
                <TabPanel header="All Items">
                  <ChecklistList
                    items={checklistItems}
                    onEdit={handleEditItem}
                    onRefresh={fetchChecklist}
                    loading={loading}
                  />
                </TabPanel>
                <TabPanel header="Move-In Items">
                  <ChecklistList
                    items={checklistItems.filter(item => item.type === "Move-In")}
                    onEdit={handleEditItem}
                    onRefresh={fetchChecklist}
                    loading={loading}
                  />
                </TabPanel>
                <TabPanel header="Move-Out Items">
                  <ChecklistList
                    items={checklistItems.filter(item => item.type === "Move-Out")}
                    onEdit={handleEditItem}
                    onRefresh={fetchChecklist}
                    loading={loading}
                  />
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        header={selectedItem ? "Edit Checklist Item" : "Add New Checklist Item"}
        modal
        className="p-fluid"
        style={{ width: '50vw' }}
      >
        <ChecklistForm
          item={selectedItem}
          onSubmit={handleFormSubmit}
          onCancel={() => setDialogVisible(false)}
          handoverId={handoverId}
        />
      </Dialog>
    </Layout>
  );
};

export default ChecklistManagement;