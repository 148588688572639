import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { toastify } from "../../../../../utils/toast";
import { getItem } from "../../../../../utils/localStorage";
import { makeRequest2 } from "../../../../../utils/makeRequest";
import { get_facility_handovers } from "../../../../../utils/urls";
import DeleteHandover from "./delete_handover";

const HandoverList = ({ selectedType, selectedStatus, onHandoverSelect }) => {
  const [handovers, setHandovers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 1
  });

  const fetchHandovers = async () => {
    try {
      setLoading(true);
      const facilityId = await getItem("selectedFacilityId");
      if (!facilityId) {
        toastify("Please select a facility first", "error");
        return;
      }

      const queryParams = new URLSearchParams({
        page: lazyParams.page,
        limit: lazyParams.rows,
        ...(selectedStatus && { status: selectedStatus }),
        ...(selectedType && { handoverType: selectedType })
      });

      const response = await makeRequest2(
        `${get_facility_handovers}/${facilityId}?${queryParams}`,
        "GET"
      );

      if (response.success) {
        setHandovers(response.handovers);
        setTotalRecords(response.pagination.total);
      } else {
        throw new Error(response.error || "Failed to fetch handovers");
      }
    } catch (error) {
      toastify(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHandovers();
  }, [lazyParams, selectedType, selectedStatus]);

  const onPage = (event) => {
    setLazyParams(event);
  };

  const statusTemplate = (rowData) => {
    const statusStyles = {
      Pending: { color: '#ffc107', backgroundColor: '#fff3cd' },
      "In Progress": { color: '#0dcaf0', backgroundColor: '#cff4fc' },
      Completed: { color: '#198754', backgroundColor: '#d1e7dd' },
      Cancelled: { color: '#dc3545', backgroundColor: '#f8d7da' }
    };

    return (
      <span
        style={{
          ...statusStyles[rowData.status],
          padding: '0.5em 1em',
          borderRadius: '12px',
          fontWeight: 'bold',
          fontSize: '0.9rem'
        }}
      >
        {rowData.status}
      </span>
    );
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="d-flex gap-2 justify-content-center">
        <Link
          to={`/facility/handover_management/handovers/view/${rowData._id}`}
          className="btn btn-sm btn-info"
        >
          <i className="ti ti-eye"></i>
        </Link>
        <Link
          to={`/facility/handover_management/handovers/edit/${rowData._id}`}
          className="btn btn-sm btn-primary"
        >
          <i className="ti ti-pencil"></i>
        </Link>
        <DeleteHandover handoverId={rowData._id} onDelete={fetchHandovers} />
      </div>
    );
  };

  return (
    <DataTable
      value={handovers}
      lazy
      paginator
      first={lazyParams.first}
      rows={lazyParams.rows}
      totalRecords={totalRecords}
      onPage={onPage}
      loading={loading}
      emptyMessage="No handovers found"
      className="p-datatable-striped"
    >
      <Column field="handoverType" header="Type" sortable />
      <Column 
        field="tenantInfo.fullName" 
        header="Tenant" 
        sortable 
      />
      <Column 
        field="landlordInfo.fullName" 
        header="Landlord" 
        sortable 
      />
      <Column 
        field="scheduledDate" 
        header="Scheduled Date" 
        sortable
        body={(rowData) => new Date(rowData.scheduledDate).toLocaleDateString()}
      />
      <Column 
        field="status" 
        header="Status" 
        body={statusTemplate} 
        sortable 
      />
      <Column 
        header="Actions" 
        body={actionTemplate}
        style={{ width: '150px' }}
      />
    </DataTable>
  );
};

export default HandoverList;