import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { toastify } from "../../../../../utils/toast";
import { getItem } from "../../../../../utils/localStorage";
import { makeRequest2 } from "../../../../../utils/makeRequest";
import { add_checklist_item, update_checklist_item } from "../../../../../utils/urls";

const ChecklistForm = ({ item, onSubmit, onCancel, handoverId }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    itemName: "",
    category: "",
    status: "",
    notes: "",
    photos: []
  });

  const categories = [
    "Appliances",
    "Furniture",
    "Electronics",
    "Utilities",
    "Structure",
    "Other"
  ];

  const statuses = ["Working", "Not Working"];

  useEffect(() => {
    if (item) {
      setFormData({
        itemName: item.itemName || "",
        category: item.category || "",
        status: item.status || "",
        notes: item.notes || "",
        photos: item.photos || []
      });
    }
  }, [item]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const facilityId = await getItem("selectedFacilityId");

      if (!facilityId || !handoverId) {
        throw new Error("Required IDs not found");
      }

      // Validation
      if (!formData.itemName.trim()) {
        throw new Error("Item name is required");
      }
      if (!formData.category) {
        throw new Error("Category is required");
      }
      if (!formData.status) {
        throw new Error("Status is required");
      }

      const endpoint = item
        ? `${update_checklist_item}/${facilityId}/${handoverId}/${item._id}`
        : `${add_checklist_item}/${facilityId}/${handoverId}`;

      const response = await makeRequest2(
        endpoint,
        item ? "PUT" : "POST",
        formData
      );

      if (response.success) {
        toastify(
          `Item ${item ? 'updated' : 'added'} successfully`,
          "success"
        );
        onSubmit();
      } else {
        throw new Error(response.error || "Operation failed");
      }
    } catch (error) {
      toastify(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handlePhotoUpload = (event) => {
    const files = Array.from(event.target.files);
    const maxSize = 5 * 1024 * 1024; // 5MB

    // Validate file sizes
    const oversizedFiles = files.filter(file => file.size > maxSize);
    if (oversizedFiles.length > 0) {
      toastify("Some files exceed the 5MB size limit", "error");
      return;
    }

    // Convert files to base64
    Promise.all(
      files.map(file => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve({
              url: reader.result,
              uploadedAt: new Date()
            });
          };
          reader.readAsDataURL(file);
        });
      })
    ).then(newPhotos => {
      setFormData(prev => ({
        ...prev,
        photos: [...prev.photos, ...newPhotos]
      }));
    });
  };

  const removePhoto = (index) => {
    setFormData(prev => ({
      ...prev,
      photos: prev.photos.filter((_, i) => i !== index)
    }));
  };

  return (
    <div className="p-fluid">
      <div className="field">
        <label htmlFor="itemName">Item Name *</label>
        <InputText
          id="itemName"
          value={formData.itemName}
          onChange={(e) => setFormData(prev => ({
            ...prev,
            itemName: e.target.value
          }))}
        />
      </div>

      <div className="field">
        <label htmlFor="category">Category *</label>
        <Dropdown
          id="category"
          value={formData.category}
          options={categories}
          onChange={(e) => setFormData(prev => ({
            ...prev,
            category: e.value
          }))}
          placeholder="Select a category"
        />
      </div>

      <div className="field">
        <label htmlFor="status">Status *</label>
        <Dropdown
          id="status"
          value={formData.status}
          options={statuses}
          onChange={(e) => setFormData(prev => ({
            ...prev,
            status: e.value
          }))}
          placeholder="Select a status"
        />
      </div>

      <div className="field">
        <label htmlFor="notes">Notes</label>
        <InputTextarea
          id="notes"
          value={formData.notes}
          onChange={(e) => setFormData(prev => ({
            ...prev,
            notes: e.target.value
          }))}
          rows={3}
          autoResize
        />
      </div>

      <div className="field">
        <label>Photos</label>
        <input
          type="file"
          className="form-control"
          accept="image/*"
          multiple
          onChange={handlePhotoUpload}
        />
        
        {formData.photos.length > 0 && (
          <div className="mt-2 grid gap-2">
            {formData.photos.map((photo, index) => (
              <div key={index} className="relative inline-block">
                <img
                  src={photo.url}
                  alt={`Photo ${index + 1}`}
                  className="w-24 h-24 object-cover rounded"
                />
                <Button
                  icon="pi pi-times"
                  className="p-button-rounded p-button-danger p-button-text absolute -top-2 -right-2"
                  onClick={() => removePhoto(index)}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="flex justify-content-end gap-2">
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-text"
          onClick={onCancel}
          disabled={loading}
        />
        <Button
          label={item ? "Update" : "Save"}
          icon="pi pi-check"
          onClick={handleSubmit}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default ChecklistForm;