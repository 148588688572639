import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext"; // Ensure correct import
import Layout from "../../component/layout";
import { toastify } from "../../../../utils/toast";
import { getItem } from "../../../../utils/localStorage";
import { makeRequest2 } from "../../../../utils/makeRequest";
import { getUnitsForFacility, getCustomersURL, add_lease, get_lease_templates } from "../../../../utils/urls";

const AddLeaseAgreement = () => {
  const navigate = useNavigate();

  // State for form fields
  const [unitNumber, setUnitNumber] = useState("");
  const [landlordId, setLandlordId] = useState(""); // Use landlordId only
  const [tenant, setTenant] = useState("");
  const [status, setStatus] = useState("Pending");

  // Lease Terms State
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [leaseDuration, setLeaseDuration] = useState("");
  const [autoRenewal, setAutoRenewal] = useState(false);

  // Financial Terms State
  const [monthlyRent, setMonthlyRent] = useState("");
  const [paymentDueDate, setPaymentDueDate] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [securityDeposit, setSecurityDeposit] = useState("");

  // Billing Cycle State
  const [billingFrequency, setBillingFrequency] = useState("");
  const [nextInvoiceDate, setNextInvoiceDate] = useState("");
  const [autoSend, setAutoSend] = useState(false);

  // Lease Template State
  const [leaseTemplate, setLeaseTemplate] = useState("");

  // Dropdown Options State
  const [units, setUnits] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [landlords, setLandlords] = useState([]);
  const [leaseTemplates, setLeaseTemplates] = useState([]);

  // Fetch available units for the facility
  const fetchUnitsForFacility = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        `${getUnitsForFacility}/${facilityId}`,
        "GET",
        {}
      );
      // console.log(response)
      if (response.success) {
        setUnits(response.data);
      } else {
        throw new Error("Failed to fetch units.");
      }
    } catch (error) {
      console.log(error)
      toastify(error.message, "error");
    }
  };

  // Fetch available customers for the facility
  const fetchCustomersForFacility = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        `${getCustomersURL}/${facilityId}`,
        "GET",
        {}
      );
      if (response.success) {
        const filteredCustomers = response.data.filter(
          (customer) => customer.facilityId.toString() === facilityId
        );

        const tenantCustomers = filteredCustomers.filter(
          (customer) => customer.customerType.toLowerCase() === "tenant"
        );
        const landlordCustomers = filteredCustomers.filter(
          (customer) => customer.customerType.toLowerCase() === "home owner"
        );

        setTenants(tenantCustomers);
        setLandlords(landlordCustomers);
      } else {
        throw new Error("Failed to fetch customers.");
      }
    } catch (error) {
      toastify(error.message, "error");
    }
  };

  // Fetch lease templates
  const fetchLeaseTemplates = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        `${get_lease_templates}/${facilityId}`,
        "GET",
        {}
      );
      if (response.success) {
        setLeaseTemplates(response.data);
      } else {
        throw new Error("Failed to fetch lease templates.");
      }
    } catch (error) {
      toastify(error.message, "error");
    }
  };

  // Handle Lease Agreement Submission
  const handleLeaseAgreementSubmit = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");

      const leaseAgreementData = {
        unitNumber,
        landlord: landlordId,
        tenant,
        leaseTerms: {
          startDate: new Date(startDate).toISOString(),
          endDate: new Date(endDate).toISOString(),
          duration: parseInt(leaseDuration),
          autoRenewal,
        },
        financialTerms: {
          monthlyRent: parseFloat(monthlyRent),
          paymentDueDate: parseFloat(paymentDueDate),
          paymentMethod,
          securityDeposit: parseFloat(securityDeposit),
        },
        billingCycle: {
          frequency: billingFrequency,
          nextInvoiceDate: nextInvoiceDate ? new Date(nextInvoiceDate).toISOString() : null,
          autoSend,
        },
        leaseTemplate,
        status,
        leaseDocuments: [],
      };

      // Validation
      if (!unitNumber) {
        toastify("Unit is required");
        return;
      }
      if (!landlordId) {
        toastify("Landlord is required");
        return;
      }
      if (!tenant) {
        toastify("Tenant is required");
        return;
      }
      if (!startDate) {
        toastify("Start Date is required");
        return;
      }
      if (!endDate) {
        toastify("End Date is required");
        return;
      }
      if (!monthlyRent) {
        toastify("Monthly Rent is required");
        return;
      }
      if (!paymentDueDate) {
        toastify("Payment Due Date is required");
        return;
      }
      if (!billingFrequency) {
        toastify("Billing Frequency is required");
        return;
      }
      if (!leaseTemplate) {
        toastify("Lease Template is required");
        return;
      }
      if (!leaseDuration) {
        toastify("Lease Duration is required");
        return;
      }
      if (!securityDeposit) {
        toastify("Security Deposit is required");
        return;
      }
      if (!paymentMethod) {
        toastify("Payment Method is required");
        return;
      }


      console.log({
        unitNumber,
        landlordId,
        tenant,
        startDate,
        endDate,
        monthlyRent,
        paymentDueDate,
        billingFrequency,
        leaseTemplate,
        leaseDuration,
        securityDeposit,
        paymentMethod,
      });



      // Send the data to the backend
      const response = await makeRequest2(
        `${add_lease}/${facilityId}`,
        "POST",
        leaseAgreementData
      );

      if (response.success) {
        toastify("Contract added successfully", "success");
        handleClear();
        navigate("/facility/lease_management/leases");
      } else {
        console.error("Error response:", response.error);
        toastify(response.error || "Failed to add lease.", "error");
      }
    } catch (err) {
      console.error("Error adding lease agreement:", err);
      toastify(err.message || "An unexpected error occurred while adding the lease agreement.", "error");
    }
  };

  // Clear form
  const handleClear = () => {
    setUnitNumber("");
    setLandlordId(""); // Reset landlordId
    setTenant("");
    setStatus("Pending");
    setStartDate("");
    setEndDate("");
    setLeaseDuration("");
    setAutoRenewal(false);
    setMonthlyRent("");
    setPaymentDueDate("");
    setPaymentMethod("");
    setSecurityDeposit("");
    setBillingFrequency("");
    setNextInvoiceDate("");
    setAutoSend(false);
    setLeaseTemplate("");
  };

  // Load data on component mount
  useEffect(() => {
    fetchUnitsForFacility();
    fetchCustomersForFacility();
    fetchLeaseTemplates();
  }, []);

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/app/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/facility/lease_management/agreements">
                    Lease Management
                  </Link>
                </li>
                <li className="breadcrumb-item">Add New Lease Agreement</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <Link to="#" onClick={() => navigate(-1)}>
                <i className="ti ti-arrow-narrow-left"></i> Back
              </Link>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h5>Add New Lease Agreement</h5>
            </div>
            <div className="card-body">
              <div className="container">
                <div className="row">
                  {/* Unit Selection */}
                  <div className="col-sm-6">
                    <label className="form-label">Unit</label>
                    <select
                      className="form-control mt-2"
                      value={unitNumber}
                      onChange={(e) => setUnitNumber(e.target.value)}
                    >
                      <option value="">-- Select Unit --</option>
                      {units.map((unit) => (
                        <option key={unit._id} value={unit._id}>
                          {unit.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Landlord Selection */}
                  <div className="col-sm-6">
                    <label className="form-label">Landlord</label>
                    <select
                      className="form-control mt-2"
                      value={landlordId}
                      onChange={(e) => setLandlordId(e.target.value)}
                    >
                      <option value="">-- Select Landlord --</option>
                      {landlords.map((landlord) => (
                        <option key={landlord._id} value={landlord._id}>
                          {`${landlord.firstName} ${landlord.lastName}`}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Tenant Selection */}
                  <div className="col-sm-6">
                    <label className="form-label">Tenant</label>
                    <select
                      className="form-control mt-2"
                      value={tenant}
                      onChange={(e) => setTenant(e.target.value)}
                    >
                      <option value="">-- Select Tenant --</option>
                      {tenants.map((tenant) => (
                        <option key={tenant._id} value={tenant._id}>
                          {`${tenant.firstName} ${tenant.lastName}`}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Lease Terms */}
                  <div className="col-sm-6">
                    <label className="form-label">Start Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-6">
                    <label className="form-label">End Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-6">
                    <label className="form-label">Lease Duration</label>
                    <InputText
                      className="form-control"
                      value={leaseDuration}
                      onChange={(e) => setLeaseDuration(e.target.value)}
                      placeholder="e.g., 12 months"
                    />
                  </div>
                  <div className="col-sm-6">
                    <label className="form-label">Auto Renewal</label>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={autoRenewal}
                        onChange={(e) => setAutoRenewal(e.target.checked)}
                      />
                      <label className="form-check-label">
                        Enable Auto Renewal
                      </label>
                    </div>
                  </div>

                  {/* Financial Terms */}
                  <div className="col-sm-6">
                    <label className="form-label">Monthly Rent</label>
                    <InputText
                      type="number"
                      className="form-control"
                      value={monthlyRent}
                      onChange={(e) => setMonthlyRent(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-6">
                    <label className="form-label">Payment Due Date</label>
                    <input
                      type="number"
                      className="form-control"
                      value={paymentDueDate}
                      onChange={(e) => setPaymentDueDate(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-6">
                    <label className="form-label">Payment Method</label>
                    <select
                      className="form-control mt-2"
                      value={paymentMethod}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    >
                      <option value="">-- Select Payment Method --</option>
                      <option value="Bank Transfer">Bank Transfer</option>
                      <option value="Mobile Money">Mobile Money</option>
                      <option value="Cheque">Cheque</option>
                      <option value="Cash">Cash</option>
                    </select>
                  </div>
                  <div className="col-sm-6">
                    <label className="form-label">Security Deposit</label>
                    <InputText
                      type="number"
                      className="form-control"
                      value={securityDeposit}
                      onChange={(e) => setSecurityDeposit(e.target.value)}
                    />
                  </div>

                  {/* Billing Cycle */}
                  <div className="col-sm-6">
                    <label className="form-label">Billing Frequency</label>
                    <select
                      className="form-control mt-2"
                      value={billingFrequency}
                      onChange={(e) => setBillingFrequency(e.target.value)}
                    >
                      <option value="">-- Select Frequency --</option>
                      <option value="Monthly">Monthly</option>
                      <option value="Quarterly">Quarterly</option>
                      <option value="Annually">Annually</option>
                    </select>
                  </div>
                  <div className="col-sm-6">
                    <label className="form-label">Next Invoice Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={nextInvoiceDate}
                      onChange={(e) => setNextInvoiceDate(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-6">
                    <label className="form-label">Auto Send Invoices</label>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={autoSend}
                        onChange={(e) => setAutoSend(e.target.checked)}
                      />
                      <label className="form-check-label">
                        Enable Auto Send
                      </label>
                    </div>
                  </div>

                  {/* Lease Template */}
                  <div className="col-sm-6">
                    <label className="form-label">Lease Template</label>
                    <select
                      className="form-control mt-2"
                      value={leaseTemplate}
                      onChange={(e) => setLeaseTemplate(e.target.value)}
                    >
                      <option value="">-- Select Lease Template --</option>
                      {leaseTemplates.map((template) => (
                        <option key={template._id} value={template._id}>
                          {template.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Status */}
                  <div className="col-sm-6">
                    <label className="form-label">Status</label>
                    <select
                      className="form-control mt-2"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="Pending">Pending</option>
                      <option value="Active">Active</option>
                      <option value="Expired">Expired</option>
                      <option value="Terminated">Terminated</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="text-end mt-4">
                <Button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={handleClear}
                >
                  Clear
                </Button>
                &nbsp;
                <Button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleLeaseAgreementSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddLeaseAgreement;