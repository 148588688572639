import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toastify } from "../../../../../utils/toast";
import { makeRequest2 } from "../../../../../utils/makeRequest";
import { create_lease_template } from "../../../../../utils/urls";
import { getItem } from "../../../../../utils/localStorage";

// Enhanced lease fields with more details
const leaseFields = {
  tenant: [
    { label: "Tenant First Name", placeholder: "{tenant.firstName}", description: "First name of the tenant" },
    { label: "Tenant Last Name", placeholder: "{tenant.lastName}", description: "Last name of the tenant" },
    { label: "Tenant Phone", placeholder: "{tenant.phoneNumber}", description: "Contact number" },
    { label: "Tenant Email", placeholder: "{tenant.email}", description: "Email address" },
    { label: "Tenant ID Number", placeholder: "{tenant.idNumber}", description: "ID number" }
  ],
  landlord: [
    { label: "Landlord First Name", placeholder: "{landlord.firstName}", description: "First name of the landlord" },
    { label: "Landlord Last Name", placeholder: "{landlord.lastName}", description: "Last name of the landlord" },
    { label: "Landlord Phone", placeholder: "{landlord.phoneNumber}", description: "Contact number" },
    { label: "Landlord Email", placeholder: "{landlord.email}", description: "Email address" }
  ],
  unit: [
    { label: "Unit Name", placeholder: "{unit.name}", description: "Name of the unit" },
    { label: "Unit Number", placeholder: "{unit.number}", description: "Unit number" }
  ],
  lease: [
    { label: "Start Date", placeholder: "{lease.startDate}", description: "Lease start date" },
    { label: "End Date", placeholder: "{lease.endDate}", description: "Lease end date" },
    { label: "Duration", placeholder: "{lease.duration}", description: "Lease duration" },
    { label: "Monthly Rent", placeholder: "{lease.monthlyRent}", description: "Monthly rental amount" },
    { label: "Security Deposit", placeholder: "{lease.securityDeposit}", description: "Security deposit amount" },
    { label: "Payment Due Date", placeholder: "{lease.paymentDueDate}", description: "Payment due date" },
    { label: "Payment Method", placeholder: "{lease.paymentMethod}", description: "Payment method" },
    { label: "Billing Frequency", placeholder: "{lease.billingFrequency}", description: "Billing frequency" }
  ]
};

const CreateLeaseTemplate = ({ visible, onHide, onTemplateCreated }) => {
  const [templateName, setTemplateName] = useState("");
  const [templateContent, setTemplateContent] = useState("");
  const [templateDescription, setTemplateDescription] = useState("");
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    getCurrentUser();
  }, []);

  const getCurrentUser = async () => {
    try {
      const currentUserValue = await getItem("APPUSER");
      let currentUser = typeof currentUserValue === "string" ? JSON.parse(currentUserValue) : currentUserValue;
      if (currentUser?.user?.userId) {
        setUserId(currentUser.user.userId);
      }
    } catch (err) {
      console.error("Error getting user:", err.message);
    }
  };

  const handleSaveTemplate = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");

      if (!templateName || !templateContent || !templateDescription || !userId) {
        toastify("Please fill in all required fields.", "error");
        return;
      }

      const response = await makeRequest2(
        `${create_lease_template}/${facilityId}`,
        "POST",
        {
          name: templateName,
          description: templateDescription,
          templateContent,
          createdBy: userId,
        }
      );

      if (response.success) {
        toastify("Lease template created successfully!", "success");
        onTemplateCreated();
        resetForm();
        onHide();
      } else {
        toastify(response.error || "Failed to create lease template.", "error");
      }
    } catch (err) {
      toastify(err.message || "An unexpected error occurred.", "error");
    }
  };

  const resetForm = () => {
    setTemplateName("");
    setTemplateDescription("");
    setTemplateContent("");
  };
  return (
    <Dialog
      header="Create Lease Template"
      visible={visible}
      style={{ 
        width: "95vw", 
        maxWidth: "1400px",
        height: '95vh', // Set dialog height to 95% of viewport height
      }}
      onHide={() => {
        onHide();
        resetForm();
      }}
      className="lease-template-dialog"
      maximizable // Adds maximize button
    >
      <div className="template-form p-3">
        {/* Header Section - Keep compact */}
        <div className="row mb-3"> {/* Reduced margin bottom */}
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Template Name</label>
              <input
                className="form-control"
                type="text"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
                placeholder="Enter template name"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Template Description</label>
              <input
                className="form-control"
                type="text"
                value={templateDescription}
                onChange={(e) => setTemplateDescription(e.target.value)}
                placeholder="Enter template description"
              />
            </div>
          </div>
        </div>

        {/* Main Content Section with Fixed Height */}
        <div className="row" style={{ height: 'calc(100vh - 280px)' }}>
          {/* Editor Section */}
          <div className="col-md-8 h-100 d-flex flex-column">
            <ReactQuill
              value={templateContent}
              onChange={setTemplateContent}
              placeholder="Start typing your template content here..."
              style={{ 
                flex: 1,
                backgroundColor: '#fff',
                display: 'flex',
                flexDirection: 'column'
              }}
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3, false] }],
                  ['bold', 'italic', 'underline'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  [{ align: [] }],
                  ['clean']
                ]
              }}
            />
          </div>

          {/* Properties Section */}
          <div className="col-md-4 h-100">
            <div className="properties-section h-100" style={{ 
              overflowY: 'auto',
              padding: '15px',
              backgroundColor: '#f8f9fa',
              borderRadius: '8px',
              boxShadow: '0 0 10px rgba(0,0,0,0.1)'
            }}>
              <h5 className="mb-3">Available Properties</h5>
              <p className="text-muted small mb-4">Click on any property to add it to your template</p>
              
              {Object.entries(leaseFields).map(([category, fields]) => (
                <div key={category} className="field-category mb-4">
                  <h6 className="text-uppercase mb-3 fw-bold" style={{ color: '#2196f3' }}>
                    {category.charAt(0).toUpperCase() + category.slice(1)}
                  </h6>
                  {fields.map((field, index) => (
                    <div key={index} className="mb-2">
                      <button
                        className="btn btn-light btn-sm w-100 text-start position-relative"
                        onClick={() => {
                          setTemplateContent(prev => 
                            prev + (prev.endsWith(' ') ? '' : ' ') + field.placeholder
                          );
                        }}
                        style={{
                          borderLeft: '3px solid #2196f3',
                          transition: 'all 0.2s ease',
                          padding: '10px 15px'
                        }}
                        title={field.description}
                      >
                        <span className="d-block" style={{ fontSize: '0.9rem' }}>{field.label}</span>
                        <small className="text-muted d-block" style={{ fontSize: '0.75rem' }}>
                          {field.placeholder}
                        </small>
                      </button>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Footer Actions - Fixed at Bottom */}
        <div className="action-buttons mt-3" style={{ 
          borderTop: '1px solid #dee2e6',
          paddingTop: '1rem',
          backgroundColor: '#fff',
          textAlign: 'right'
        }}>
          <Button 
            label="Cancel" 
            className="p-button-secondary p-button-outlined me-2" 
            onClick={() => {
              onHide();
              resetForm();
            }}
          />
          <Button 
            label="Add Template" 
            icon="pi pi-save"
            className="p-button-primary" 
            onClick={handleSaveTemplate}
          />
        </div>
      </div>

      <style>{`
        .lease-template-dialog .p-dialog-content {
          padding: 0;
          overflow: hidden;
          height: calc(95vh - 6rem) !important; /* Adjust dialog content height */
        }
        
        .properties-section::-webkit-scrollbar {
          width: 6px;
        }
        
        .properties-section::-webkit-scrollbar-track {
          background: #f1f1f1;
        }
        
        .properties-section::-webkit-scrollbar-thumb {
          background: #2196f3;
          border-radius: 3px;
        }
        
        .btn-light:hover {
          transform: translateX(5px);
          background-color: #e9ecef;
        }

        .ql-container {
          border-radius: 0 0 4px 4px;
          flex: 1;
          display: flex;
          flex-direction: column;
        }

        .ql-container .ql-editor {
          flex: 1;
        }

        .ql-toolbar {
          border-radius: 4px 4px 0 0;
        }

        .form-control:focus {
          border-color: #2196f3;
          box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.25);
        }

         .action-buttons {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 1rem;
          background: white;
          border-top: 1px solid #dee2e6;
          z-index: 1;
          height: 70px; /* Fixed height for footer */
        }

        .template-form {
          height: 100%;
          padding-bottom: 70px !important; /* Match footer height */
        }

        /* Adjust editor height */
        .ql-container {
          height: calc(100% - 42px) !important; /* 42px is toolbar height */
        }

        /* Make properties section fill available height */
        .properties-section {
          height: 100% !important;
          overflow-y: auto;
        }
      `}</style>
    </Dialog>
  );
};

export default CreateLeaseTemplate;